import axios from "axios";
import jwtDecode from "jwt-decode";

import { logout, refreshAccessToken } from "./utils/auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Set the proper Authorization header for every request
api.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("accessToken");

    // Get a new access token if one is not already present in the localStorage
    // e.g. the user just logged in
    if (!accessToken) {
      accessToken = await refreshAccessToken();
    }

    // Refresh the access token if it is about to expire (in less than 30s)
    const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);

    if (decodedAccessToken.exp * 1000 - 30000 < Date.now()) {
      accessToken = await refreshAccessToken();
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  },
  (error) =>
    // Forward rejection
    Promise.reject(error)
);

// Disconnect user if authentication problems are encountered
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Return immediately if no response was received (e.g. network error)
    if (!error.response) return Promise.reject(error);

    switch (error.response) {
      case 401:
        logout();
        break;
      default:
        // Forward rejection
        return Promise.reject(error);
    }
  }
);

export default api;
