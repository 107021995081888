import gql from "graphql-tag";

export const DELETE_RANKING = gql`
  mutation($id: ID!) {
    deleteRanking(id: $id)
  }
`;

export const GET_RANKING = gql`
  query($id: ID!) {
    ranking(id: $id) {
      country
      createdAt
      id
      language
      periodicity
      website {
        accountId
        id
        domain
      }
    }
  }
`;

export const GET_RANKINGS = gql`
  query($accountId: ID, $domain: String, $limit: Int, $offset: Int) {
    rankings(
      accountId: $accountId
      domain: $domain
      limit: $limit
      offset: $offset
    ) {
      country
      createdAt
      id
      language
      periodicity
      website {
        accountId
        id
        domain
      }
    }
  }
`;

export const GET_RANKINGS_COUNT = gql`
  query($accountId: ID, $domain: String) {
    rankingsCount(accountId: $accountId, domain: $domain)
  }
`;
