import gql from "graphql-tag";

export const GET_MODULES = gql`
  query {
    modules {
      depth
      id
      level
      name
    }
  }
`;
