import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import ReactTable, { Instance as ReactTableInstance } from "react-table";
import { RouteComponentProps, withRouter } from "react-router-dom";

import formatChartData from "../../containers/AuditView/formatChartData";
import PieChart from "./Chart";
import Spinner from "../Spinner";

import "react-table/react-table.css";
import styles from "./OptimizationView.module.css";

const DEFAULT_PAGE_SIZE = 16;

interface OwnProps {
  audit: any;
  catchLabel: boolean;
  chartContentField: any;
  chartDataType: string;
  chartType: "bar" | "pie";
  dataType: string;
  dispatch: Function;
  fetchTableData: any;
  match: any;
  tableColumns: any[];
}

type Props = OwnProps & RouteComponentProps<{}>;

interface State {
  isLoading: boolean;
  chartData?: any;
  label?: any;
  // sort: any[];
  tableData?: any;
  tablePages?: any;
}

function colorFormat(
  labels: string[]
): { backgroundColor: string[]; hoverBackgroundColor: string[] } {
  const colors = labels.map((label: string) => {
    switch (label) {
      case "http":
      case "incorrect":
      case "non_compliant":
      case "notset":
        return "red";
      case "duplicate":
      case "incomplete":
        return "orange";
      case "complete":
      case "compliant":
      case "correct":
      case "https":
      case "set":
      case "unique":
        return "green";
      default:
        return "grey";
    }
  });

  return {
    backgroundColor: colors,
    hoverBackgroundColor: colors,
  };
}

function barDataFormat({ data, labels }: any) {
  const colors = colorFormat(labels);

  return {
    labels,
    datasets: [
      {
        label: "Number of pages",
        data,
        backgroundColor: colors.backgroundColor,
        hoverBackgroundColor: colors.hoverBackgroundColor,
        borderWidth: 1.5,
      },
    ],
  };
}

function pieDataFormat({ data, labels }: any) {
  const { backgroundColor, hoverBackgroundColor } = colorFormat(labels);

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        hoverBackgroundColor,
      },
    ],
  };
}

function formatData(data: any, type: "bar" | "pie") {
  switch (type) {
    case "bar":
      return barDataFormat(data);
    case "pie":
      return pieDataFormat(data);
    default:
      return null;
  }
}

class OptimizationView extends Component<Props, State> {
  myRef = React.createRef<ReactTableInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      chartData: {},
      isLoading: true,
      // sort: [],
    };
  }

  static getDerivedStateFromProps({
    audit,
    chartContentField,
    chartDataType,
    chartType = "pie",
  }: Props) {
    if (audit && audit.content) {
      const data = formatChartData(
        audit.content[chartContentField],
        chartDataType
      );

      return { chartData: formatData(data, chartType) };
    }

    return null;
  }

  chartClicked = (chart: any, e: any) => {
    const {
      catchLabel,
      // fetchTableData,
      // match: {
      //   params: { id: auditId },
      // },
    } = this.props;
    // const { chartData, sort } = this.state;

    const activePoints = chart.getElementsAtEvent(e);

    if (activePoints.length) {
      const index = activePoints[0]._index;
      const label = chart.data.labels[index];
      // let value = chart.data.datasets[0].data[index];

      this.setState({ label: label ? [label] : label }, () => {
        if (this.myRef.current) {
          this.myRef.current.fireFetchData();
        }
      });

      // this.setState({ label, isLoading: true }, () => {
      //   fetchTableData(
      //     0,
      //     DEFAULT_PAGE_SIZE,
      //     sort,
      //     auditId,
      //     label ? [label] : label,
      //     chartData.labels
      //   ).then((newState: any) => {
      //     this.setState({
      //       ...newState,
      //       isLoading: false,
      //     });
      //   });
      // });
    } else if (catchLabel) {
      const labels = chart.legend.legendItems
        .filter((item: any) => !item.hidden)
        .map((item: any) => item.text);

      this.setState({ label: labels }, () => {
        if (this.myRef.current) {
          this.myRef.current.fireFetchData();
        }
      });

      // this.setState({ label: labels, isLoading: true }, () => {
      //   fetchTableData(
      //     0,
      //     DEFAULT_PAGE_SIZE,
      //     sort,
      //     auditId,
      //     labels,
      //     chartData.labels
      //   ).then((newState: any) => {
      //     this.setState({
      //       ...newState,
      //       isLoading: false,
      //     });
      //   });
      // });
    }
  };

  render() {
    const {
      audit,
      fetchTableData,
      tableColumns,
      match: {
        params: { id: auditId },
      },
    } = this.props;

    const { chartData, label, tableData, tablePages, isLoading } = this.state;

    if (!audit || !audit.content)
      return (
        <div className={styles.spinnerWrapper}>
          <Spinner size={64} />
        </div>
      );

    return (
      <div className={styles.wrapper}>
        <h2>
          <FormattedMessage id="audit.overview" />{" "}
        </h2>
        <div className={styles.topWrapper}>
          <div>
            <PieChart
              data={chartData}
              onClick={(chart: any, e: any) => this.chartClicked(chart, e)}
            />
          </div>
          <div className={styles.text}>
            <h3>Description</h3>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            malesuada malesuada magna, et fringilla erat lobortis in. In hac
            habitasse platea dictumst. Curabitur pretium sapien eu risus
            hendrerit imperdiet. Donec consequat hendrerit consectetur. Integer
            luctus justo vitae sapien convallis, gravida imperdiet enim mattis.
            Donec quis iaculis risus. Vestibulum posuere accumsan malesuada. Ut
            at quam justo. Cras malesuada sem sit amet orci commodo lacinia et
            in mi. Cras scelerisque lacus at enim volutpat, ac egestas ipsum
            vulputate. Praesent quam massa, sollicitudin sit amet ultricies non,
            tempus et odio.
            <h3>Advice</h3>
            Ut ipsum arcu, imperdiet et semper finibus, faucibus ac augue. Ut et
            vulputate urna, sit amet facilisis metus. Mauris id est massa. Donec
            blandit odio ut pharetra posuere. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. Donec tempor,
            lorem ac sollicitudin faucibus, ligula augue ultrices arcu, quis
            fermentum felis dolor dignissim dui. Curabitur lorem eros, elementum
            ac leo in, blandit iaculis dui. In tempus nisi quis neque gravida,
            nec porta lorem mattis. Donec dignissim auctor nunc id efficitur.
            Duis et metus enim. Ut mollis velit vel purus congue ullamcorper.
            Nam ipsum lacus, ultrices sit amet feugiat quis, consequat gravida
            neque. Ut ante diam, condimentum a volutpat non, elementum vitae
            ipsum. Curabitur in iaculis lacus, eu iaculis enim. Praesent congue
            commodo eros a efficitur.
          </div>
        </div>
        <h2>
          <FormattedMessage id="audit.details" />{" "}
        </h2>
        <div>
          <ReactTable
            manual
            ref={this.myRef}
            data={tableData}
            columns={tableColumns}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={isLoading}
            showPageSizeOptions={false}
            pages={tablePages}
            onFetchData={(state) => {
              this.setState({ isLoading: true });

              fetchTableData(
                state.page,
                DEFAULT_PAGE_SIZE,
                state.sorted,
                auditId,
                // label ? [label] : label,
                label,
                chartData.labels
              ).then((newState: any) => {
                this.setState({
                  ...newState,
                  isLoading: false,
                });
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(OptimizationView);
