import React from "react";

import styles from "./Spinner.module.css";

interface Props {
  size?: number;
}

const Spinner = ({ size = 18 }: Props) => (
  <div
    className={styles.spinner}
    style={{ height: `${size}px`, width: `${size}px` }}
  />
);

export default Spinner;
