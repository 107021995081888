import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import React, { FunctionComponent, Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";

// GraphQL
import {
  NetlinkingConfiguration,
  NetlinkingPublicationType,
} from "../../queries/netlinking";

export type FormData = {
  anchorText: string;
  citationFlow: number;
  date: string;
  netlinkingConfigurationId: number;
  trustFlowScore: number;
  trustFlowTopic: string;
  type: string;
  url: string;
};

type Props = {
  configurations: NetlinkingConfiguration[];
};

const NetlinkingPublication: FunctionComponent<Props> = ({
  configurations,
}) => {
  const { control, errors, register, setValue, watch } = useFormContext<
    FormData
  >();

  return (
    <Fragment>
      <TextField
        error={!!errors.url}
        fullWidth
        inputRef={register({
          pattern: /^https?:\/\/.*$/,
          required: true,
        })}
        label="URL"
        margin="dense"
        name="url"
        required
      />

      <TextField
        error={!!errors.anchorText}
        fullWidth
        inputRef={register({ required: true })}
        label="Anchor text"
        margin="dense"
        name="anchorText"
        required
      />

      <FormControl error={!!errors.type} fullWidth margin="dense" required>
        <InputLabel id="type-label">Type</InputLabel>
        <Controller
          as={
            <Select labelId="configuration-label">
              <MenuItem value={NetlinkingPublicationType.Generic}>
                Generic
              </MenuItem>
              <MenuItem value={NetlinkingPublicationType.Thematic}>
                Thematic
              </MenuItem>
              <MenuItem value={NetlinkingPublicationType.DedicatedWebsite}>
                Dedicated website
              </MenuItem>
              <MenuItem value={NetlinkingPublicationType.Premium}>
                Premium
              </MenuItem>
            </Select>
          }
          control={control}
          defaultValue=""
          name="type"
          rules={{ required: true }}
        />
      </FormControl>

      <Controller
        as={
          // @ts-ignore onChange will be passed by <Controller/>
          <DatePicker
            error={!!errors.date}
            fullWidth
            label="Date"
            margin="dense"
            required
            value={watch("date")}
          />
        }
        control={control}
        name="date"
        onChange={(date: Moment) => setValue("date", date.toString())}
      />

      <TextField
        error={!!errors.trustFlowTopic}
        fullWidth
        inputRef={register({ required: true })}
        label="Trust Flow topic"
        margin="dense"
        name="trustFlowTopic"
        required
      />

      <TextField
        error={!!errors.trustFlowScore}
        fullWidth
        inputProps={{ max: 100, min: 0 }}
        inputRef={register({ min: 0, max: 100, required: true })}
        label="Trust Flow score"
        margin="dense"
        name="trustFlowScore"
        required
        type="number"
      />

      <TextField
        error={!!errors.citationFlow}
        fullWidth
        inputProps={{ max: 100, min: 0 }}
        inputRef={register({ min: 0, max: 100, required: true })}
        label="Citation Flow"
        margin="dense"
        name="citationFlow"
        required
        type="number"
      />

      <FormControl
        error={!!errors.netlinkingConfigurationId}
        fullWidth
        margin="dense"
        required
      >
        <InputLabel id="configuration-label">Configuration</InputLabel>
        <Controller
          as={
            <Select labelId="configuration-label">
              {configurations.map((configuration) => (
                <MenuItem key={configuration.id} value={configuration.id}>
                  {configuration.targetUrl}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          defaultValue=""
          name="netlinkingConfigurationId"
          rules={{ required: true }}
        />
      </FormControl>
    </Fragment>
  );
};

export default NetlinkingPublication;
