import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState, Fragment } from "react";

// Components
import ConfirmationDialog from "../UI/Dialog/ConfirmationDialog";
import EditionDialog from "../EditionDialogs/NetlinkingPublication";

// GraphQL
import {
  DELETE_NETLINKING_PUBLICATION,
  DeleteNetlinkingPublicationData,
  DeleteNetlinkingPublicationVariables,
  NetlinkingConfiguration,
  NetlinkingPublication,
} from "../../queries/netlinking";

// Assets
import { useMutation } from "@apollo/client";

type Props = {
  configurations: NetlinkingConfiguration[];
  publications: NetlinkingPublication[];
  refetch: () => Promise<any>;
};

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const PublicationsTable: FunctionComponent<Props> = ({
  configurations,
  publications,
  refetch,
}) => {
  const [openedConfirmation, displayConfirmation] = useState(false);
  const [deferredAction, setDeferredAction] = useState<() => Promise<any>>();

  const [openedEditionDialog, displayEditionDialog] = useState(false);
  const [succeeded, showSuccess] = useState(false);
  const [
    editablePublication,
    setEditablePublication,
  ] = useState<NetlinkingPublication | null>(null);

  // GraphQL hooks
  const [deletePublication] = useMutation<
    DeleteNetlinkingPublicationData,
    DeleteNetlinkingPublicationVariables
  >(DELETE_NETLINKING_PUBLICATION);

  // Pop up confirmation dialog if a new action has been deferred
  useEffect(() => {
    if (deferredAction) displayConfirmation(true);
  }, [deferredAction]);

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>Targeted page</TableCell>
              <TableCell>Link description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Trust Flow</TableCell>
              <TableCell>Publication date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {publications.map((publication) => (
              <TableRow key={publication.id}>
                <TableCell>{publication.url}</TableCell>
                <TableCell>
                  {configurations.reduce<string>(
                    (previous, configuration) =>
                      configuration.id === publication.netlinkingConfigurationId
                        ? configuration.targetUrl
                        : previous,
                    "-"
                  )}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>{publication.type}</TableCell>
                <TableCell>{publication.trustFlowTopic}</TableCell>
                <TableCell>
                  {moment(publication.date).format("dddd, MMMM Do YYYY")}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setEditablePublication(publication);
                      displayEditionDialog(true);
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      setDeferredAction(() => () =>
                        deletePublication({
                          variables: { id: publication.id },
                        }).then(() => refetch())
                      )
                    }
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editablePublication && (
        <EditionDialog
          publication={editablePublication}
          configurations={configurations}
          onCancel={() => {
            displayEditionDialog(false);
          }}
          onSuccess={async () => {
            displayEditionDialog(false);
            showSuccess(true);

            await refetch();
          }}
          open={openedEditionDialog}
        />
      )}

      <ConfirmationDialog
        action={() => {
          if (deferredAction)
            deferredAction().then(() => displayConfirmation(false));
        }}
        cancel={() => displayConfirmation(false)}
        open={openedConfirmation}
      />

      <Snackbar
        autoHideDuration={6000}
        onClose={() => showSuccess(false)}
        open={succeeded}
      >
        <Alert>
          Updated publication
          {editablePublication && ` n°${editablePublication.id}`}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default PublicationsTable;
