export enum OrderStatus {
  Accepted = "ACCEPTED",
  Any = "ANY",
  Created = "CREATED",
  Estimated = "ESTIMATED",
  Finalized = "FINALIZED",
  Fulfilled = "FULFILLED",
  Refused = "REFUSED",
  Validated = "VALIDATED",
}

export type Order = {
  audit: any;
  createdAt: any;
  id: number;
  status: OrderStatus;
  updatedAt: any;
};
