import { Box, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Typings
import { RowAction, Column } from "./TableView";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    textAlign: "end",
  },
  fields: {
    flexGrow: 1,
  },
  root: {
    borderTop: "1px solid #cccccc",
    display: "flex",
    padding: "4px 4px 4px 8px",
    "&:hover": {
      background: "whitesmoke",
    },
  },
});

type Props<T> = {
  columns: Column<T>[];
  items: T[];
  rowActions: RowAction<T>[];
};

function Rows<T>({ columns, items, rowActions }: Props<T>) {
  const classes = useStyles();

  const defaultColumnWidth = Math.floor(12 / columns.length);

  return (
    <Fragment>
      {items.map((item, idx) => (
        <Box className={classes.root} key={idx}>
          <Grid className={classes.fields} container spacing={1}>
            {columns.map(
              ({ centered, computeLink, computeValue, width }, idx) => (
                <Grid
                  item
                  key={idx}
                  style={{
                    alignSelf: "center",
                    textAlign: centered ? "center" : "start",
                  }}
                  // @ts-ignore
                  xs={width ? width : defaultColumnWidth}
                >
                  {computeLink ? (
                    <Link to={computeLink(item)}>{computeValue(item)}</Link>
                  ) : (
                    computeValue(item)
                  )}
                </Grid>
              )
            )}
          </Grid>
          <Box className={classes.actions}>
            {rowActions.map(({ handler, Icon }, idx) => {
              return (
                <IconButton
                  key={idx}
                  onClick={() => handler(item)}
                  size="small"
                >
                  <Icon />
                </IconButton>
              );
            })}
          </Box>
        </Box>
      ))}
    </Fragment>
  );
}

export default Rows;
