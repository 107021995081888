import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

// Assets
import styles from "./NavItem.module.css";

interface Props {
  icon?: ReactElement | null;
  link: string;
  message: string;
}

const NavItem = ({ icon = null, link, message }: Props) => {
  const intl = useIntl();

  return (
    <ListItem
      activeClassName={styles.active}
      button
      component={NavLink}
      to={link}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={intl.formatMessage({ id: message })} />
    </ListItem>
  );
};

export default NavItem;
