import React, { Component } from "react";
import { connect, DispatchProp } from "react-redux";
import { FormattedMessage } from "react-intl";

// Redux
import {
  removeAllItemsToFilters,
  removeItemToFilters,
} from "../../../actions/rankings";

// CSS
import styles from "./Filter.module.css";

interface OwnProps {
  filters: any[];
  rankingData: any;
  type: string;
}

type Props = OwnProps & DispatchProp;

class Filter extends Component<Props> {
  componentDidMount() {
    const { dispatch, rankingData } = this.props;

    // Clear filters between component mounts
    // TODO Check if relevant
    if (rankingData.selectedKeywords.length !== 0) {
      dispatch(removeAllItemsToFilters("selectedKeywords"));
    }

    if (rankingData.selectedCompetitors.length !== 0) {
      dispatch(removeAllItemsToFilters("selectedCompetitors"));
    }
  }

  render() {
    const { dispatch, filters, type } = this.props;

    return (
      <div className={styles.wrapper}>
        <span style={{ marginBottom: "10px" }}>
          <i
            className="fa fa-circle"
            aria-hidden="true"
            style={{ marginRight: "5px", color: "#0086DC" }}
          />
          <span>
            <FormattedMessage id="ranking.averageRank" />
          </span>
        </span>
        {filters.map((filter, index) => (
          <span
            key={
              /* eslint-disable-next-line react/no-array-index-key */
              index
            }
            onClick={() => {
              dispatch(
                removeItemToFilters(
                  type === "keywords"
                    ? "selectedKeywords"
                    : "selectedCompetitors",
                  index
                )
              );
            }}
            className={styles.clickable}
          >
            <i
              className="fa fa-circle"
              aria-hidden="true"
              style={{ marginRight: "5px", color: filter.color }}
            />
            <span>{type === "keywords" ? filter.keyword : filter.domain}</span>
          </span>
        ))}
      </div>
    );
  }
}

export default connect()(Filter);
