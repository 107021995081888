import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import ReactTable from "react-table";
import Papa from "papaparse";
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from "react-intl";
import { Dispatch } from "redux";

import helpers from "./helpers";

import globalStyles from "../../index.module.css";
import styles from "./AddKeywords.module.css";
import api from "../../api";
import { Modals, showModal } from "../../actions/modal";

const KeywordComponent = ({
  keyword,
  index,
  deleteKeywordFromList,
}: {
  keyword: any;
  index: any;
  deleteKeywordFromList: any;
}) => (
  <span className={styles.newKeyword}>
    {keyword}
    <i
      style={{ marginLeft: "10px", cursor: "pointer" }}
      className="fa fa-times"
      aria-hidden="true"
      onClick={() => deleteKeywordFromList(index)}
    />
  </span>
);

interface OwnProps {}

interface DispatchProps {
  confirm: Function;
}

type Props = DispatchProps &
  OwnProps &
  RouteComponentProps<{ id: string }> &
  WrappedComponentProps;

interface State {
  country: any;
  data: any[];
  fetchedData: any;
  inputKeyword: string;
  keywordsPerPage: number;
  keywordsToAdd: any[];
  loading: boolean;
  loadingParser?: any;
  page: number;
  total: number;
}

class Keywords extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputKeyword: "",
      keywordsToAdd: [],
      total: 0,
      page: 0,
      loading: true,
      keywordsPerPage: 20,
      fetchedData: [], // so we can keep the id of every keyword if we want to delete one
      data: [],
      country: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    helpers.getRankingProject(id).then((res) => {
      this.setState({
        country: res.country,
      });
    });
  }

  deleteKeywordFromList = (index: any) => {
    const { keywordsToAdd } = this.state;

    const keywordsArr = keywordsToAdd;
    keywordsArr.splice(index, 1);

    if (keywordsArr.length === 0) {
      // @ts-ignore
      this.fileInput.value = "";
    } // delete the file from upload input

    this.setState({ keywordsToAdd: keywordsArr });
  };

  handleChange = (e: any) => {
    this.setState({ inputKeyword: e.target.value });
  };

  handleKeyPress = (e: any) => {
    const { inputKeyword, keywordsToAdd } = this.state;

    if (e.key === "Enter") {
      if (inputKeyword.trim() !== "") {
        const keywordsArr = keywordsToAdd;
        inputKeyword.split(/,|;/).forEach((keyword: any) => {
          const k = keyword.trim();

          k.length > 0 && keywordsArr.push(k);
        });
        this.setState({ inputKeyword: "", keywordsToAdd: keywordsArr });
      } else {
        this.setState({ inputKeyword: "" });
      }
    }
  };

  createKeywordsArray = (str: any) => {
    const arr = str.split(/;|\n/); // split by ; and \n

    return arr
      .filter((s: any) => s.trim() !== "")
      .map((s: any) => {
        return String.prototype.trim.apply(s);
      }); // trim all the elements in the array if not empty
  };

  getContentOfFile = (file: any, fn: any) => {
    const f = file;
    const read = new FileReader();

    read.readAsText(f);
    read.onloadend = () => {
      fn(read.result);
    };
  };

  parseCsv = (file: any) => {
    const { keywordsToAdd } = this.state;

    this.setState({ loadingParser: true });

    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results: { data: any[][] }) => {
        const keywordsArr = keywordsToAdd;
        let posKeywordHeader = 0; // position of title "Keyword" in csd header (default 0 if no header)
        results.data[0].forEach((element, index) => {
          // find if there is header with title Keyword
          if (
            element.match(
              /(?:^|(<= ))(keyword|keywords|mot clé|mot clés|mots clés|mots clé|mot-clé|mot-clés|mots-clés|mots-clé)(?:(?= )|$)/i
            )
          )
            posKeywordHeader = index;
        });
        results.data.forEach((element, index) => {
          const keyword = element[posKeywordHeader];
          if (results.data[0].length > 1) {
            // if there are columns
            if (index > 0)
              // remove header from list
              keywordsArr.push(keyword);
          } else {
            keywordsArr.push(keyword);
          }
        });
        this.setState({ keywordsToAdd: keywordsArr, loadingParser: false });
      },
    });
  };

  handleUpload = (e: any) => {
    const file = e.target.files[0];
    const splitFilename = file.name.split(".");
    const extension = splitFilename[splitFilename.length - 1];

    if (extension === "txt") {
      this.setState({ loadingParser: true });
      this.getContentOfFile(file, (keywords: any) => {
        const arr = this.createKeywordsArray(keywords);
        // put keywords into scroll list
        const { keywordsToAdd } = this.state;
        const keywordsArr = keywordsToAdd;

        arr.forEach((element: any) => {
          keywordsArr.push(element);
        });
        this.setState({ keywordsToAdd: keywordsArr, loadingParser: false });
      });
    } else {
      // csv
      this.parseCsv(file);
    }
  };

  updateKeywords = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { country, keywordsToAdd, page } = this.state;

    helpers.postKeywords(id, keywordsToAdd, country).then(() => {
      // @ts-ignore
      this.fileInput.value = ""; // delete the file from upload input
      this.setState({ keywordsToAdd: [] });
      this.fetchKeywords(page);
    });
  };

  fetchKeywords = (from: any) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { keywordsPerPage } = this.state;

    this.setState({ loading: true, page: from });

    helpers
      .getKeywords(id, from * keywordsPerPage, keywordsPerPage)
      .then((res: AxiosResponse) => {
        const data: any[] = [];

        res.data.keywords.forEach((element: any) => {
          const obj = { keyword: "" };
          obj.keyword = element.keyword;
          data.push(obj);
        });

        this.setState({
          loading: false,
          fetchedData: res.data,
          total: res.data.total,
          data,
        });
      });
  };

  deleteKeyword = (index: any) => {
    const {
      confirm,
      match: {
        params: { id },
      },
    } = this.props;

    const { fetchedData, page } = this.state;

    confirm(() => {
      this.setState({ loading: true });

      const keywordId = fetchedData.keywords[index].id;

      api.delete(`/rankings/${id}/keywords/${keywordId}`).then(() => {
        this.fetchKeywords(page);
      });
    });
  };

  render() {
    const { intl } = this.props;

    const {
      loading,
      data,
      loadingParser,
      keywordsPerPage,
      keywordsToAdd,
      inputKeyword,
      total,
    } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.newKeywords}>
          <div className={styles.newKeywordsHeader}>
            <h3>
              <FormattedMessage id="ranking.keywords.newKeywords" />
            </h3>
            <div>
              <button
                className={
                  keywordsToAdd.length === 0
                    ? globalStyles.disabledTrigger
                    : globalStyles.confirmationTrigger
                }
                disabled={keywordsToAdd.length === 0}
                onClick={this.updateKeywords}
                type="button"
              >
                {/* <i className="fa fa-check" aria-hidden="true" />{" "} */}
                <FormattedMessage id="ranking.keywords.add.update" />
              </button>
              <button
                className={globalStyles.deleteTrigger}
                onClick={() => this.setState({ keywordsToAdd: [] })}
                type="button"
              >
                <FormattedMessage id="ranking.keywords.add.clear" />
              </button>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className={styles.inputWrapper}>
              <div className={styles.inputKeyword}>
                <input
                  placeholder={intl.formatMessage({
                    id: "ranking.keywords.add.inputPlaceholder",
                  })}
                  value={inputKeyword}
                  type="text"
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  className={styles.input}
                />
              </div>
              or
              <div className={globalStyles.addTrigger}>
                <div
                  // @ts-ignore
                  onClick={() => document.getElementById("files").click()}
                >
                  <FormattedMessage id="ranking.keywords.add.upload" />
                </div>
                <input
                  id="files"
                  style={{ display: "none" }}
                  // @ts-ignore
                  // eslint-disable-next-line no-return-assign
                  ref={(ref) => (this.fileInput = ref)} // used to delete the file when uploaded
                  type="file"
                  accept=".txt,.csv"
                  onChange={this.handleUpload}
                />
              </div>
            </div>
            <div className={styles.keywordsList}>
              {loadingParser
                ? "Loading..."
                : keywordsToAdd.map((keyword, index) => (
                    <KeywordComponent
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      keyword={keyword}
                      index={index}
                      deleteKeywordFromList={this.deleteKeywordFromList}
                    />
                  ))}
            </div>
          </div>
        </div>
        <div className={styles.currentKeywords}>
          <ReactTable
            manual
            nextText={intl.formatMessage({ id: "ranking.keywords.add.next" })}
            previousText={intl.formatMessage({
              id: "ranking.keywords.add.previous",
            })}
            data={data}
            columns={[
              {
                Header: intl.formatMessage({
                  id: "ranking.keywords.add.tableName",
                }),
                accessor: "keyword",
                Cell: (row) => (
                  <div>
                    <i
                      onClick={() => this.deleteKeyword(row.index)}
                      className="fa fa-trash"
                      aria-hidden="true"
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "red",
                      }}
                    />
                    {row.value}
                  </div>
                ),
              },
            ]}
            loading={loading}
            pages={Math.ceil(total / keywordsPerPage)}
            defaultPageSize={keywordsPerPage}
            showPageSizeOptions={false}
            onFetchData={(state) => {
              this.fetchKeywords(state.page);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: (callback: Function) =>
    dispatch(showModal(callback, Modals.CONFIRMATION)),
});

export default injectIntl(
  connect<State, DispatchProps, OwnProps>(null, mapDispatchToProps)(Keywords)
);
