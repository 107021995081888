import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./Metric.module.css";

interface Props {
  data: any;
  title: any;
  position?: any;
  totalNumKeywords?: any;
}

const Metric = ({ data, title, position, totalNumKeywords }: Props) => {
  let color = "darkslategrey";

  if (!data) {
    return null;
  }

  if (+data.diff < 0) {
    color = "red";
  }

  if (+data.diff > 0) {
    color = "green";
  }

  return (
    <div className={styles.wrapper} style={{ border: `1px solid ${color}` }}>
      {/* Metric value */}
      <div className={styles.value} style={{ background: color }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {position && data.value === 101 ? (
          <span>n/a</span>
        ) : totalNumKeywords ? (
          <span>{data}</span>
        ) : (
          <span>
            {!(data.value % 1 === 0) // is number a float
              ? parseFloat(data.value.toFixed(1))
              : data.value}
          </span>
        )}
        {!totalNumKeywords && parseFloat(data.diff.toFixed(1)) !== 0 && (
          <span style={{ fontSize: "1em" }}>
            <i
              className={`fa fa-caret-${+data.diff > 0 ? "up" : "down"}`}
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            />
            {!(data.diff % 1 === 0)
              ? parseFloat(Math.abs(data.diff).toFixed(1))
              : Math.abs(data.diff)}
          </span>
        )}
      </div>

      {/* Metric label */}
      <div className={styles.label}>
        <FormattedMessage id={title} />
      </div>
    </div>
  );
};

export default Metric;
