import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import styles from "./ErrorBoundary.module.css";
import globalStyles from "../index.module.css";

type Props = RouteComponentProps;

interface State {
  error: any;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line
    console.log(error, info);
  }

  render() {
    const {
      // eslint-disable-next-line
      props: { children, history },
      state: { error },
    } = this;

    if (error) {
      // You can render any custom fallback UI
      return (
        <div className={styles.container}>
          <img alt="Logo" src="/octopulse_logo_noname.png" />
          <h1>Oops, something went wrong.</h1>
          <p>
            Please report it on Slack, create a card in Asana or use the button
            below
          </p>
          <br />
          <div>
            {process.env.NODE_ENV === "production" && (
              <button
                className={globalStyles.primaryTrigger}
                type="button"
                onClick={() => Sentry.showReportDialog()}
              >
                Provide feedback
              </button>
            )}
            {process.env.NODE_ENV === "production" && (
              <span>&nbsp;or&nbsp;</span>
            )}
            <button
              className={globalStyles.primaryTrigger}
              type="button"
              onClick={() => {
                this.setState({
                  error: null,
                });

                history.push("/");
              }}
            >
              Go to homepage
            </button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
