import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";

// Components
import Form from "../EditionForms/AdsCampaign";

// GraphQL
import {
  // NetlinkingConfiguration,
  UPDATE_ADS_CAMPAIGN,
  // UpdateNetlinkingConfigurationData,
  // UpdateNetlinkingConfigurationVariables,
} from "../../queries/adsCampaigns";
import { AdsCampaign } from "../../types/adsCampaigns";

type Props = {
  adsCampaign: AdsCampaign;
  open: boolean;
  onCancel: () => any;
  onSuccess: () => any;
};

const EditionDialog: FunctionComponent<Props> = ({
  adsCampaign,
  open,
  onCancel,
  onSuccess,
}) => {
  const formMethods = useForm<AdsCampaign>();

  const [updateConfiguration] = useMutation<
    { adsCampaign: AdsCampaign },
    {
      id: string;
      googleAdsCustomerAccountId: string;
      datastudioId: string;
      budget: number;
      status: string;
    }
  >(UPDATE_ADS_CAMPAIGN);

  const onEditionSubmit = formMethods.handleSubmit((data) =>
    updateConfiguration({
      variables: {
        id: adsCampaign.id,
        ...data,
        budget: +data.budget,
      },
    }).then(() => onSuccess())
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Update configuration</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <Form adsCampaign={adsCampaign} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color="primary" onClick={onEditionSubmit} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditionDialog;
