import { Button, TextField } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";

import history from "../../history";

// Utils
import { login } from "../../utils/auth";

// Assets
import styles from "./index.module.css";
import pJson from "../../../package.json";

type FormData = {
  email: string;
  password: string;
};

const Login: FunctionComponent = () => {
  const { errors, handleSubmit, register } = useForm<FormData>();
  const [status, setStatus] = useState(
    localStorage.getItem("disconnected")
      ? "Your have been disconnected, please log in again"
      : ""
  );

  // Redirect the user if he is already authenticated
  if (localStorage.getItem("refreshToken")) {
    history.push("/");
  }

  // onSubmit handler for the login form
  const handleLogin = handleSubmit(({ email, password }) =>
    login(email, password)
      .then((res) => {
        if (res.status === 401) {
          setStatus("Please verify your credentials");
        } else {
          setStatus("Oops, something went wrong, try again later");
        }
      })
      .catch(() => {
        setStatus("Oops, something went wrong, try again later");
      })
  );

  return (
    <form className={styles.container} onSubmit={handleLogin}>
      <img alt="Logo" className={styles.logo} src="/octopulse_logo.png" />

      <TextField
        autoComplete="username"
        error={!!errors.email}
        inputRef={register({
          pattern: /^\w+@[a-zA-Z]+?\.[a-zA-Z]{2,4}$/i,
          required: true,
        })}
        label="User"
        margin="dense"
        name="email"
        required
        type="email"
        variant="outlined"
      />

      <TextField
        autoComplete="current-password"
        error={!!errors.password}
        inputRef={register({ required: true })}
        label="Password"
        margin="dense"
        name="password"
        required
        type="password"
        variant="outlined"
      />

      <Button color="primary" type="submit" variant="contained">
        Log in
      </Button>

      {status && <p className={styles.loginError}>{status}</p>}

      <span className={styles.version}>v{pJson.version}</span>
    </form>
  );
};

export default Login;
