/* eslint no-use-before-define: ["error", { "functions": false }] */
// See https://eslint.org/docs/rules/no-use-before-define#options

/**
 * Audit model
 * @typedef {Object} Audit
 * @property {number} id
 * @property {string} name
 * @property {string} url
 * @property {string} status
 * @property {Date} start
 * @property {Date} end
 * @property {number} userId
 * @property {User} user
 */
export function auditIn({ id, dateStart, dateEnd, status, Website }) {
  return {
    id,
    start: dateStart ? new Date(dateStart) : null,
    status,
    end: dateEnd ? new Date(dateEnd) : null,
    website: Website ? websiteIn(Website) : null,
  };
}

/**
 * Order model
 * @typedef {Object} Order
 * TODO
 */
export function orderIn({
  id,
  Account,
  Audit,
  createdAt,
  Quotes,
  status,
  updatedAt,
}) {
  return {
    id,
    account: Account ? accountIn(Account) : null,
    audit: Audit ? auditIn(Audit) : null,
    createdAt,
    quotes: Quotes ? Quotes.map(quote => quoteIn(quote)) : null,
    status,
    updatedAt,
  };
}

/**
 * Quote model
 * @typedef {Object} Quote
 * TODO
 */
export function quoteIn({ id, price, status }) {
  return {
    id,
    price,
    status,
  };
}

/**
 * Todo model
 * @typedef {Object} Todo
 * TODO
 */
export function todoIn({ id, time_per_unit: duration, tag }) {
  return {
    id,
    duration,
    tag,
  };
}

export function accountIn({ id, subscription }) {
  return {
    id,
    subscription,
  };
}

/**
 * User model
 * @typedef {Object} User
 * TODO
 */
export function userIn({
  accountId,
  Account: account,
  createdAt,
  firstName,
  id,
  email,
  lastName,
  phone,
  isBlocked,
  verifiedEmail,
}) {
  return {
    account: account ? accountIn(account) : null,
    accountId,
    audits: [],
    createdAt,
    email,
    firstName,
    id,
    isBlocked,
    isVerified: !!verifiedEmail,
    lastName,
    phone,
    rankings: [],
  };
}

export function userOut({
  id,
  firstName,
  lastName,
  email,
  phone,
  isBlocked,
  isVerified,
}) {
  return {
    id,
    firstName,
    lastName,
    email,
    phone,
    isBlocked,
    verifiedEmail: isVerified,
  };
}

export function websiteIn({ accountId, id, domain, url, Account }) {
  return {
    account: Account ? accountIn(Account) : null,
    accountId,
    id,
    domain,
    url,
  };
}
