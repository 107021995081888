import React from "react";
import { Link } from "react-router-dom";
import { fetchAuditUrlInfo } from "../../actions/audits";

export const url = (auditId, dispatch) => ({
  Header: "URL",
  accessor: "url",
  esPath: "url.raw",
  Cell: row => (
    <span>
      <Link
        to={`/audits/${auditId}/url-finder`}
        onClick={() => dispatch(fetchAuditUrlInfo(auditId, row.value))}
      >
        <span style={{ textDecoration: "underline" }}>{row.value}</span>
      </Link>
      <a
        href={row.value}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: "5px" }}
      >
        <i className="fas fa-external-link-alt" />
      </a>
    </span>
  ),
});

export const depth = {
  Header: "Depth",
  accessor: "depth",
  esPath: "depth",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const h1Status = {
  Header: "Status",
  accessor: "h1_status",
  esPath: "h1_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const hnHierarchyJump = {
  Header: "Status",
  accessor: "hn_hierarchy_jump",
  esPath: "hn_hierarchy_jump",
  width: 200,
  Cell: row => (
    <div style={{ textAlign: "center" }}>{row.value.toString()}</div>
  ),
};

export const titleStatus = {
  Header: "Status",
  accessor: "title_status",
  esPath: "title_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const descriptionStatus = {
  Header: "Status",
  accessor: "description_status",
  esPath: "description_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const imgStatus = {
  Header: "Status",
  accessor: "img_status",
  esPath: "img_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const canonicalStatus = {
  Header: "Status",
  accessor: "canonical_status",
  esPath: "canonical_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const protocol = {
  Header: "Protocol",
  accessor: "protocol",
  esPath: "protocol",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const compliant = {
  Header: "Compliant URL",
  accessor: "compliant",
  esPath: "url.compliant",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const opengraphStatus = {
  Header: "Status",
  accessor: "opengraph_status",
  esPath: "opengraph_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const twittercardStatus = {
  Header: "Status",
  accessor: "twittercard_status",
  esPath: "twittercard_status",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const duplicate = {
  Header: "Status",
  accessor: "duplicate",
  esPath: "similar_urls",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const loadTime = {
  Header: "Load time",
  accessor: "load_time",
  esPath: "download_time",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const latency = {
  Header: "Load time",
  accessor: "latency",
  esPath: "latency",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const returnCode = {
  Header: "HTTP Code",
  accessor: "return_code",
  esPath: "return_code",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const size = {
  Header: "Size",
  accessor: "size",
  esPath: "size",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const lang = {
  Header: "Language",
  accessor: "lang",
  esPath: "lang",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const wordCount = {
  Header: "Word count",
  accessor: "wordCount",
  esPath: "word_count",
  width: 200,
  Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
};

export const gpsiUrl = {
  Header: "Url",
  accessor: "gpsiUrl",
  esPath: "URL",
  Cell: row => (
    <a
      href={row.value}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: "5px" }}
    >
      {row.value}
    </a>
  ),
};

export const gpsiBytes = {
  Header: "Bytes",
  accessor: "gpsiBytes",
  esPath: "BYTES",
};

export const gpsiPlatform = {
  Header: "Platform",
  accessor: "gpsiPlatform",
  esPath: "platform",
};

export const gpsiPageUrl = {
  Header: "Page URL",
  accessor: "gpsiPageUrl",
  esPath: "page_url",
};

export const gpsiDuration = {
  Header: "Duration",
  accessor: "gpsiDuration",
  esPath: "DURATION",
};

export const gpsiPercentage = {
  Header: "Percentage",
  accessor: "gpsiPercentage",
  esPath: "PERCENTAGE",
};

export const gpsiIntLiteral = {
  Header: "Int Literal",
  accessor: "gpsiIntLiteral",
  esPath: "INT_LITERAL",
};

export const gpsiStringLiteral = {
  Header: "String Literal",
  accessor: "gpsiStringLiteral",
  esPath: "STRING_LITERAL",
};

export const gpsiSnapshotRect = {
  Header: "Snapshot Rect",
  accessor: "gpsiSnapshotRect",
  esPath: "SNAPSHOT_RECT",
};
