import gql from "graphql-tag";

export const CREATE_AUDIT = gql`
  mutation($input: AuditInput!) {
    createAudit(input: $input) {
      id
    }
  }
`;

export const DELETE_AUDIT = gql`
  mutation($id: ID!) {
    deleteAudit(id: $id)
  }
`;

export const GET_AUDIT = gql`
  query($id: ID!) {
    audit(id: $id) {
      crawlId
      createdAt
      dateEnd
      dateStart
      depth
      id
      maxURL
      score
      speed
      status
      website {
        accountId
        id
        domain
        url
      }
    }
  }
`;

export const GET_DETAILED_AUDIT = gql`
  query($id: ID!) {
    audit(id: $id) {
      content
      crawlId
      createdAt
      dateEnd
      dateStart
      depth
      id
      maxURL
      technos {
        id
      }
      todos {
        id
        hits
        score
        optimisation {
          groupScoreId
          id
          seoRatio
          tag
          module {
            depth
            id
            level
            name
          }
          technoOptimisations {
            id
            maxFailScore
            minSuccessScore
            technoId
            status
          }
        }
      }
      pagesCount
      score
      speed
      status
      website {
        id
        domain
        url
      }
    }
  }
`;

export const GET_AUDIT_URL_INFO = gql`
  query($auditId: ID!, $url: String) {
    audit(id: $auditId) {
      id
      page(url: $url)
    }
  }
`;

export const GET_AUDITS = gql`
  query(
    $accountId: ID
    $status: String
    $url: String
    $limit: Int
    $offset: Int
  ) {
    audits(
      accountId: $accountId
      status: $status
      url: $url
      offset: $offset
      limit: $limit
    ) {
      crawlId
      createdAt
      dateEnd
      dateStart
      depth
      id
      maxURL
      score
      speed
      status
      website {
        accountId
        id
        domain
        url
      }
    }
  }
`;

export const GET_AUDITS_COUNT = gql`
  query($accountId: ID, $status: String, $url: String) {
    auditsCount(accountId: $accountId, status: $status, url: $url)
  }
`;
