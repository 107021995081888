import { AnyAction } from "redux";

import { D_RANKING_OK } from "../actions/rankings";

const defaultState = {
  count: null,
  list: null,
};

function rankings(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case D_RANKING_OK:
      return {
        ...state,
        // @ts-ignore
        list: state.list.filter((ranking: any) => ranking.id !== action.id),
      };
    default:
      return state;
  }
}

export default rankings;
