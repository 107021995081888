import React from "react";
import PropTypes from "prop-types";

import Switch from "../UI/Switch/Switch";
import Tile from "../UI/Tile/Tile";

import styles from "./NewQuoteTodos.module.css";
import globalStyles from "../../index.module.css";

const NewQuoteTodos = ({
  handleInputChange,
  hourlyRate,
  todos,
  resetAll,
  resetOne,
  switchAll,
}) => (
  <Tile className={styles.tile} icon="fa-th-list" name="Todos">
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>Tag</div>
        <div>Quantity</div>
        <div>Duration (mn)</div>
        <div>Price (€ ex. VAT)</div>
        <div>
          <button
            className={globalStyles.addTrigger}
            onClick={() => switchAll(true)}
            type="button"
          >
            <i className="fas fa-plus" />
          </button>
          <button
            className={globalStyles.deleteTrigger}
            onClick={() => switchAll(false)}
            type="button"
          >
            <i className="fas fa-minus" />
          </button>
          <button
            className={globalStyles.primaryTrigger}
            onClick={resetAll}
            type="button"
          >
            <i className="fas fa-redo" />
          </button>
        </div>
      </div>
      {todos.map(todo => (
        <div
          className={todo.enabled ? styles.enabled : styles.disabled}
          key={todo.id}
        >
          <div>
            <p>{todo.tag}</p>
          </div>
          <div>
            {todo.enabled ? (
              <input
                min={1}
                name="value"
                onChange={e => handleInputChange(e, todo.id)}
                type="number"
                value={todo.value || 1}
              />
            ) : (
              <span>-</span>
            )}
          </div>
          <div>
            {todo.enabled ? (
              <input
                min={0}
                name="duration"
                onChange={e => handleInputChange(e, todo.id)}
                type="number"
                value={todo.duration}
              />
            ) : (
              <span>-</span>
            )}
          </div>
          <div>
            {todo.enabled ? (
              `${Math.trunc(
                ((todo.duration * (todo.value || 1)) / 60) * hourlyRate
              )} €`
            ) : (
              <span>-</span>
            )}
          </div>
          <div>
            <Switch
              checked={todo.enabled}
              id={`enabled-${todo.id}`}
              name="enabled"
              onChange={e => handleInputChange(e, todo.id)}
            />
            <div
              className={`${styles.resetContainer} ${todo.resettable &&
                styles.resettable}`}
            >
              <i
                aria-hidden
                className="fas fa-redo"
                onClick={() => resetOne(todo.id)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  </Tile>
);

NewQuoteTodos.propTypes = {
  handleInputChange: PropTypes.func,
  hourlyRate: PropTypes.number.isRequired,
  todos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  resetAll: PropTypes.func,
  resetOne: PropTypes.func,
  switchAll: PropTypes.func,
};

NewQuoteTodos.defaultProps = {
  handleInputChange: () => {},
  resetAll: () => {},
  resetOne: () => {},
  switchAll: () => {},
};

export default NewQuoteTodos;
