import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import api from "../api";

/*
Action dispatched to update a order in the redux store
 */
export const UPDATE_ORDER = "UPDATE_ORDER";

/*
Action dispatched to update the redux store with a fetched order
 */
export const RECEIVE_ORDER = "RECEIVE_ORDER";

function receiveOrder(order: any) {
  return {
    type: RECEIVE_ORDER,
    order,
  };
}

/*
Action dispatched to fetch an order from the API
 */
export const FETCH_ORDER = "FETCH_ORDER";
export const F_ORDER_ERR = "F_ORDER_ERR";

export function fetchOrder(id: number) {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(() => ({
      type: FETCH_ORDER,
    }));

    api
      .get(`/orders/${id}`)
      .then((order) => order.data)
      .then((order) => {
        dispatch(receiveOrder(order));
      })
      .catch(() => dispatch({ type: F_ORDER_ERR }));
  };
}
