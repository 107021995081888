import React, { Component } from "react";
import * as PropTypes from "prop-types";

import styles from "./UrlForm.module.css";

// Verify an URL format using a regexp
function isUrlValid(str) {
  const pattern = new RegExp(
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9].[^s]{2,})"
  );

  return !str.match(pattern);
}

class UrlForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      url: "",
    };
  }

  // Update state as input changes
  handleChange = ({ target: { value: url } }) => {
    this.setState({
      hasError: isUrlValid(url),
      url,
    });
  };

  handleSubmit = e => {
    const {
      props: { submitUrl },
      state: { hasError, url },
    } = this;

    e.preventDefault();

    // Submit the URL only if its valid
    if (!hasError) {
      submitUrl(url);
    }
  };

  render() {
    const { hasError, url } = this.state;

    return (
      <div className={styles.wrapper}>
        <form onSubmit={e => this.handleSubmit(e)}>
          <input
            className={hasError ? styles.error : undefined}
            name="urlInput"
            onChange={this.handleChange}
            placeholder="https://example.com"
            type="text"
            value={url}
          />
          <input type="submit" value="Search" />
        </form>
      </div>
    );
  }
}

UrlForm.propTypes = {
  submitUrl: PropTypes.func.isRequired,
};

export default UrlForm;
