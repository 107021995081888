import { AnyAction } from "redux";

import { DELETE_QUOTE, RECEIVE_QUOTES } from "../actions/quotes";
import { quoteIn } from "../utils/format";

function quotes(state = [], action: AnyAction) {
  switch (action.type) {
    case DELETE_QUOTE:
      return state.filter((quote: any) => quote.id !== action.id);
    case RECEIVE_QUOTES:
      return action.quotes.map((quote: any) => quoteIn(quote));
    default:
      return state;
  }
}

export default quotes;
