import { AnyAction } from "redux";

import { RECEIVE_TODOS } from "../actions/todos";
import { todoIn } from "../utils/format";

function todos(state = null, action: AnyAction) {
  switch (action.type) {
    case RECEIVE_TODOS:
      return action.todos.map((todo: any) => todoIn(todo));
    default:
      return state;
  }
}

export default todos;
