import gql from "graphql-tag";
import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "../OptimizationView.module.css";
import Spinner from "../../Spinner";
import Tile from "../../UI/Tile/Tile";
import apollo from "../../../apollo";

interface Props {
  audit: any;
}

interface State {
  robotsTxt?: any;
}

class RobotsTxt extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { audit } = this.props;

    apollo
      .query({
        query: gql`
          {
            audit(id: ${audit.id}) {
              id
              robotsTxt
            }
          }
        `,
      })
      .then(
        ({
          data: {
            audit: {
              robotsTxt: {
                hits: [
                  {
                    _source: { robots_txt: robotsTxt },
                  },
                ],
              },
            },
          },
        }) => {
          this.setState({
            robotsTxt,
          });
        }
      );
  }

  render() {
    const { robotsTxt } = this.state;

    if (!robotsTxt) {
      return <Spinner />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.robotsTxtInfo}>
          {robotsTxt.robots_missing ? (
            <div className={styles.robotsTxtStatus} style={{ color: "red" }}>
              <span>Your website does not have a robots.txt</span>
              <span>Googlebot is not allowed to parse your website</span>
            </div>
          ) : (
            <div className={styles.robotsTxtStatus} style={{ color: "green" }}>
              <span>The robots.txt is accessible</span>
              <span>Googlebot is allowed to parse your website</span>
            </div>
          )}
          <div>
            <h3>Description</h3>
            audit.page.architecture.subtab.robotsTxt_desc
            <h3 style={{ margin: "15px 0px 15px 0px", fontSize: "1.5em" }}>
              Advice
            </h3>
            audit.page.architecture.subtab.robotsTxt_advice
          </div>
        </div>
        <Tile name="audit.raw">
          <div className={styles.rawRobotsTxt}>{robotsTxt.robots_txt_raw}</div>
        </Tile>
      </div>
    );
  }
}

const mapStateToProps = ({ audit }: any) => ({ audit });

export default connect(mapStateToProps)(RobotsTxt);
