import React, { Component } from "react";
import Chart from "chart.js";

interface Props {
  chartType?: "bar" | "pie";
  data: any;
  onClick: Function;
}

interface State {
  chart: Chart | null;
}

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chart: null,
    };
  }

  componentDidMount(): void {
    const { chartType, onClick } = this.props;
    const { data } = this.props;

    const context = document.getElementById("chart") as HTMLCanvasElement;
    const chart = new Chart(context, {
      type: chartType || "pie",
      data: data || undefined,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "left",
        },
      },
    });

    context.onclick = e => {
      onClick(chart, e);
    };

    this.setState({ chart });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { data } = this.props;
    const { chart } = this.state;

    if (chart) {
      chart.data = data;
      chart.update();
    }
  }

  componentWillUnmount(): void {
    const { chart } = this.state;

    if (chart) {
      chart.destroy();
    }
  }

  render() {
    return <canvas height="300" id="chart" width="400" />;
  }
}
