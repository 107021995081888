import { TextField } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

// Typings
import { NetlinkingConfiguration } from "../../queries/netlinking";

export type FormData = {
  targetUrl: string;
  targetKeywords: string;
  ratio: number;
};

type Props = {
  configuration: NetlinkingConfiguration;
};

const EditionForm: FunctionComponent<Props> = ({ configuration }) => {
  const { errors, register } = useFormContext<FormData>();

  return (
    <Fragment>
      <TextField
        defaultValue={configuration.targetUrl}
        error={!!errors.targetUrl}
        fullWidth
        inputRef={register({
          pattern: /^https?:\/\/.*$/,
          required: true,
        })}
        label="Target URL"
        margin="dense"
        name="targetUrl"
        required
      />

      <TextField
        defaultValue={configuration.targetKeywords}
        error={!!errors.targetKeywords}
        fullWidth
        inputRef={register({ required: true })}
        label="Target keywords"
        margin="dense"
        name="targetKeywords"
        required
      />

      <TextField
        defaultValue={configuration.ratio}
        error={!!errors.ratio}
        fullWidth
        inputProps={{ max: 1, min: 0, step: 0.001 }}
        inputRef={register({ min: 0, max: 1, required: true })}
        label="Ratio"
        margin="dense"
        name="ratio"
        type="number"
        required
      />
    </Fragment>
  );
};

export default EditionForm;
