import history from "../history";

export const login = (email: string, password: string) =>
  fetch(`${process.env.REACT_APP_API_URL}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  }).then(async (res) => {
    if (res.ok) {
      const { accessToken, refreshToken } = await res.json();

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.removeItem("disconnected");
      history.push("/");
    }

    return res;
  });

export const logout = () => {
  // TODO /logout request (which is best effort)

  // Clear all tokens in localStorage by precaution
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.setItem("disconnected", "true");

  // Redirect the user on the login page
  history.push("/login");
};

/**
 *
 */
export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  // Logout user if no refresh token is present in localStorage
  if (!refreshToken) {
    logout();
    return Promise.reject();
  }

  return fetch(`${process.env.REACT_APP_API_URL}/refresh-token`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refreshToken }),
  })
    .then((res) => {
      // Logout user if the request failed
      if (!res.ok) {
        logout();
        return Promise.reject();
      }

      return res.json();
    })
    .then(({ accessToken }: { accessToken: string }) => {
      localStorage.setItem("accessToken", accessToken);

      return accessToken;
    })
    .catch(() => {
      // Logout user if the request failed
      logout();
      return Promise.reject();
    });
};
