import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import api from "../../api";
import { F_ORDER_ERR, fetchOrder } from "../../actions/orders";
import { clearError } from "../../actions/errors";
import { F_TODOS_ERR, fetchTodos } from "../../actions/todos";
import { displayError } from "../../utils/display";
import { findErrors } from "../../utils/errors";

import NewQuoteTodos from "./NewQuoteTodos";
import NewQuoteUser from "./NewQuoteUser";
import Spinner from "../Spinner";
import Tile from "../UI/Tile/Tile";

import styles from "./NewQuote.module.css";
import globalStyles from "../../index.module.css";

const DEFAULT_HOURLY_RATE = 40;

// Merge audit todos information and the full Octopulse todos list
const mapTodos = (auditTodos, todos) =>
  todos.map((todo) => {
    const auditTodo = auditTodos.find((el) => todo.id === el.id_tag);

    // Todo has been raised by the audit
    if (auditTodo) {
      return {
        ...todo,
        enabled: true,
        resettable: false,
        value: auditTodo.value,
      };
    }

    // Otherwise
    return {
      ...todo,
      enabled: false,
      resettable: false,
      value: undefined,
    };
  });

class NewQuoteView extends Component {
  state = {
    auditTodos: null,
    error: "",
    hourlyRate: DEFAULT_HOURLY_RATE,
    mappedTodos: null,
    user: null,
    submitting: false,
  };

  componentDidMount() {
    this.shouldFetchOrder();
    this.shouldFetchTodos();
    this.shouldFetchAuditTodos();
  }

  componentDidUpdate(prevProps /* prevState, snapshot */) {
    const { order } = this.props;

    // Fetch audit todos after a new order has been fetched
    if (order && (!prevProps.order || order.id !== prevProps.order.id)) {
      this.shouldFetchAuditTodos();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { order, todos } = props;
    const { auditTodos, mappedTodos } = state;

    let newState = { error: "" };

    // Search for error
    findErrors(["F_AUDIT_TODOS_ERR", F_ORDER_ERR, F_TODOS_ERR], (error) => {
      newState = { ...newState, error };
    });

    // Copy user information to local state when fetched
    if (!state.user && order) {
      newState = {
        ...newState,
        user: order.user,
      };
    }

    // Map todos when necessary
    if (!mappedTodos && auditTodos && todos) {
      newState = {
        ...newState,
        mappedTodos: mapTodos(auditTodos, todos),
      };
    }

    return newState;
  }

  // Compute total quote price
  computeTotal = () => {
    const { hourlyRate, mappedTodos } = this.state;

    return Math.trunc(
      mappedTodos.reduce((sum, todo) => {
        if (todo.enabled) {
          return sum + ((todo.duration * (todo.value || 1)) / 60) * hourlyRate;
        }

        return sum;
      }, 0)
    );
  };

  handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    this.setState({
      [name]: value,
    });
  };

  handleTodoChange = (event, id) => {
    const {
      target: { checked, name, type, value },
    } = event;
    const { mappedTodos } = this.state;

    const updatedTodos = mappedTodos.map((todo) => {
      if (id === todo.id)
        return {
          ...todo,
          [name]: type === "checkbox" ? checked : value,
          resettable: true,
        };

      return todo;
    });

    this.setState({
      mappedTodos: updatedTodos,
    });
  };

  handleUserChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  // Reset all mapped todos to their default values
  resetAllTodos = () => {
    const {
      props: { todos },
      state: { auditTodos },
    } = this;

    this.setState({
      mappedTodos: mapTodos(auditTodos, todos),
    });
  };

  // Reset a single mapped todo to its default value
  resetTodo = (id) => {
    const {
      props: { todos },
      state: { auditTodos, mappedTodos },
    } = this;

    // Find the initial todo value from the audit's todos list
    const auditTodo = auditTodos.find((el) => id === el.id_tag);

    // Find the initial todo duration from the todos list
    const originalTodo = todos.find((el) => id === el.id);

    // Remap todos
    const updatedTodos = mappedTodos.map((todo) => {
      if (id === todo.id) {
        // The todo was raised by the audit and should be reset
        // with its default values
        if (auditTodo)
          return {
            ...originalTodo,
            enabled: true,
            resettable: false,
            value: auditTodo.value,
          };

        // The todo was not raised by the audit and should be reset disabled
        return {
          ...originalTodo,
          enabled: false,
          resettable: false,
          value: undefined,
        };
      }

      return todo;
    });

    this.setState({
      mappedTodos: updatedTodos,
    });
  };

  switchAll = (enable = true) => {
    const { mappedTodos } = this.state;

    this.setState({
      mappedTodos: mappedTodos.map((todo) => ({
        ...todo,
        enabled: enable,
        resettable: true,
      })),
    });
  };

  // Try fetching again and clear current error
  retryFetch = () => {
    const { dispatch } = this.props;
    const { error } = this.state;

    if (error === "F_AUDIT_TODOS_ERR") {
      this.shouldFetchAuditTodos();
      dispatch(clearError("F_AUDIT_TODOS_ERR"));
    }

    if (error === F_ORDER_ERR) {
      this.shouldFetchOrder();
      dispatch(clearError(F_ORDER_ERR));
    }

    if (error === F_TODOS_ERR) {
      this.shouldFetchTodos();
      dispatch(clearError(F_TODOS_ERR));
    }
  };

  // Fetch audit todos only after the order has been fetched
  shouldFetchAuditTodos = () => {
    const { dispatch, order } = this.props;

    if (order) {
      api
        .get(`/audits/${order.auditId}/todos`)
        .then((auditTodos) => auditTodos.data)
        .then((auditTodos) => this.setState({ auditTodos }))
        .catch(() => dispatch({ type: "F_AUDIT_TODOS_ERR" }));
    }
  };

  // Fetch the order information if the version in redux store it is null
  // or out of sync with the current URL
  shouldFetchOrder = () => {
    const { dispatch, match, order } = this.props;

    if (!order || order.id !== match.params.id)
      dispatch(fetchOrder(match.params.id));
  };

  // Fetch todos list only if not present in redux store
  shouldFetchTodos = () => {
    const { dispatch, todos } = this.props;

    if (!todos) dispatch(fetchTodos());
  };

  submitQuote = async (event) => {
    const {
      props: { history, order },
      state: {
        hourlyRate,
        mappedTodos,
        user: { email, firstName, lastName },
      },
    } = this;

    event.preventDefault();

    const priceExVAT = this.computeTotal();
    const VAT = (priceExVAT * 20) / 100;

    // Used to display spinner locally as creation could take some time
    this.setState({ submitting: true });

    // Create quote in database
    const {
      data: { id: quoteId },
    } = await api.post("/quotes/", {
      orderId: order.id,
      price: priceExVAT,
    });

    // Generate quote's pdf
    api
      .post(`/quotes/${quoteId}/pdf`, {
        email,
        firstName,
        lastName,
        quoteId,
        date: Date.now(),
        expireOn: Date.now() + 7,
        totalExVAT: priceExVAT,
        VAT,
        items: mappedTodos
          .filter((todo) => todo.enabled)
          .map((todo) => ({
            ...todo,
            price: Math.trunc(
              ((todo.duration * (todo.value || 1)) / 60) * hourlyRate
            ),
          })),
      })
      .then(() => history.push(`/orders/${order.id}`))
      .catch((e) => {
        this.setState({ submitting: false });
        console.err(e);
      });
  };

  render() {
    // Display error if fetch failed with an error
    const { error } = this.state;

    if (error)
      return (
        <div className={styles.defaultWrapper}>
          <p>{displayError(error)}</p>
          <button
            className={globalStyles.retryTrigger}
            onClick={() => this.retryFetch()}
            type="button"
          >
            Retry
          </button>
        </div>
      );

    // Display loading spinner if data is still fetching or quote is creating
    const { mappedTodos, submitting } = this.state;

    if (!mappedTodos || submitting)
      return (
        <div className={styles.defaultWrapper}>
          <Spinner />
        </div>
      );

    // Destructure data and display component otherwise
    const { hourlyRate, user } = this.state;

    const totalExVAT = this.computeTotal();
    const VAT = (totalExVAT * 20) / 100;

    return (
      <form>
        <div className={styles.wrapper}>
          <NewQuoteUser handleInputChange={this.handleUserChange} user={user} />
          <Tile name="Wappalyzer">
            <div>Coming soon ...</div>
          </Tile>
          <NewQuoteTodos
            handleInputChange={this.handleTodoChange}
            hourlyRate={hourlyRate}
            todos={mappedTodos}
            resetAll={this.resetAllTodos}
            resetOne={this.resetTodo}
            switchAll={this.switchAll}
          />
        </div>
        <div className={styles.finalizationWrapper}>
          <label htmlFor="hourly-rate">
            Hourly rate (€)
            <input
              id="hourly-rate"
              name="hourlyRate"
              onChange={this.handleInputChange}
              type="number"
              value={hourlyRate}
            />
          </label>
          <label htmlFor="totalExVAT">
            Total (€ ex. VAT)
            <input
              id="totalExVAT"
              name="totalExVAT"
              type="number"
              readOnly
              value={totalExVAT}
            />
          </label>
          <label htmlFor="totalIncVAT">
            Total (€ inc. VAT)
            <input
              id="totalIncVAT"
              name="totalIncVAT"
              type="number"
              readOnly
              value={totalExVAT + VAT}
            />
          </label>
          <button
            className={globalStyles.confirmationTrigger}
            type="submit"
            onClick={this.submitQuote}
          >
            Create Quote
          </button>
        </div>
      </form>
    );
  }
}

NewQuoteView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  order: PropTypes.shape(),
  todos: PropTypes.arrayOf(PropTypes.shape()),
  // auditTodos: PropTypes.arrayOf(PropTypes.shape()),
};

NewQuoteView.defaultProps = {
  order: null,
  todos: null,
  // auditTodos: null,
};

const mapStateToProps = (state) => ({
  order: state.order,
  todos: state.todos,
});

export default connect(mapStateToProps)(NewQuoteView);
