import { Button, Snackbar, Typography } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import CreationFormDialog from "../CreationDialogs/NetlinkingConfiguration";

// Assets
import styles from "./CampaignView.module.css";

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

type Props = {
  refetch: () => any;
};

const Configurations: FunctionComponent<Props> = ({ children, refetch }) => {
  const [openedFormDialog, displayFormDialog] = useState(false);
  const [shouldDisplaySuccess, displaySuccess] = useState(false);

  const { id: campaignId } = useParams();

  return (
    <div className={styles.configuration}>
      <div className={styles.heading}>
        <Typography variant="h2">Configurations</Typography>
        <Button
          color="primary"
          onClick={() => {
            displayFormDialog(true);
          }}
          variant="contained"
        >
          Add
        </Button>
      </div>

      {children}

      <CreationFormDialog
        campaignId={campaignId}
        onCancel={() => {
          displayFormDialog(false);
        }}
        onSuccess={async () => {
          displayFormDialog(false);
          displaySuccess(true);

          await refetch();
        }}
        open={openedFormDialog}
      />

      <Snackbar
        autoHideDuration={6000}
        onClose={() => displaySuccess(false)}
        open={shouldDisplaySuccess}
      >
        <Alert>Created new configuration</Alert>
      </Snackbar>
    </div>
  );
};

export default Configurations;
