import { Button, Snackbar, Typography } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import CreationDialog from "../CreationDialogs/NetlinkingPublication";

// Assets
import styles from "./CampaignView.module.css";
import { NetlinkingConfiguration } from "../../queries/netlinking";

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

type Props = {
  configurations: NetlinkingConfiguration[];
  refetch: () => Promise<any>;
};

const Publications: FunctionComponent<Props> = ({
  children,
  configurations,
  refetch,
}) => {
  const [success, showSuccess] = useState(false);

  const [openedFormDialog, displayFormDialog] = useState(false);

  const { id: campaignId } = useParams();

  return (
    <div className={styles.published}>
      <div className={styles.heading}>
        <Typography variant="h2">Publications</Typography>
        {configurations && configurations.length ? (
          <Button
            color="primary"
            onClick={() => displayFormDialog(true)}
            variant="contained"
          >
            Add
          </Button>
        ) : undefined}
      </div>

      {children}

      {configurations.length ? (
        <CreationDialog
          campaignId={campaignId}
          configurations={configurations}
          open={openedFormDialog}
          onCancel={() => displayFormDialog(false)}
          onSuccess={async () => {
            displayFormDialog(false);
            showSuccess(true);

            await refetch();
          }}
        />
      ) : undefined}

      <Snackbar
        autoHideDuration={6000}
        onClose={() => showSuccess(false)}
        open={success}
      >
        <Alert>Created new publication</Alert>
      </Snackbar>
    </div>
  );
};

export default Publications;
