import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import styles from "./ProfilePicture.module.css";

const ProfilePicture: FunctionComponent = () => (
  <Card>
    <CardHeader avatar={<Icon>portrait</Icon>} title="Profile picture" />

    <CardContent className={styles.content}>
      <img
        alt="User Profile Pic"
        className={styles.profilePicture}
        src="/user-default-avatar.png"
      />
    </CardContent>

    <CardActions>
      <Button
        color="primary"
        disabled
        name="profile-picture"
        // type="file"
      >
        Upload
      </Button>
    </CardActions>
  </Card>
);

export default ProfilePicture;
