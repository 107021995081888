import gql from "graphql-tag";

export const CREATE_NETLINKING_CAMPAIGN = gql`
  mutation($input: NetlinkingCampaignInput) {
    createNetlinkingCampaign(input: $input) {
      createdAt
      duration
      id
      monthlyArticlesNumber
      name
      status
    }
  }
`;

export const DELETE_NETLINKING_CAMPAIGN = gql`
  mutation($id: ID!) {
    deleteNetlinkingCampaign(id: $id)
  }
`;

export const GET_NETLINKING_CAMPAIGN = gql`
  query($id: ID!) {
    netlinkingCampaign(id: $id) {
      createdAt
      duration
      id
      monthlyArticlesNumber
      name
      status
    }
  }
`;

export const GET_NETLINKING_CAMPAIGNS = gql`
  query($input: NetlinkingCampaignInput, $limit: Int, $offset: Int) {
    netlinkingCampaigns(input: $input, limit: $limit, offset: $offset) {
      id
      name
      status
    }
  }
`;

export const GET_NETLINKING_CAMPAIGNS_COUNT = gql`
  query($input: NetlinkingCampaignInput) {
    netlinkingCampaignsCount(input: $input)
  }
`;

export type NetlinkingConfiguration = {
  id: string;
  ratio: string;
  targetKeywords: string;
  targetUrl: string;
};

export type CreateNetlinkingConfigurationData = {
  configuration: NetlinkingConfiguration;
};

export type CreateNetlinkingConfigurationVariables = {
  input: any;
};

export const CREATE_NETLINKING_CONFIGURATION = gql`
  mutation($input: NetlinkingConfigurationInput) {
    configuration: createNetlinkingConfiguration(input: $input) {
      id
      ratio
      targetKeywords
      targetUrl
    }
  }
`;

export type DeleteNetlinkingConfigurationData = {};

export type DeleteNetlinkingConfigurationVariables = {
  id: string;
};

export const DELETE_NETLINKING_CONFIGURATION = gql`
  mutation($id: ID!) {
    deleteNetlinkingConfiguration(id: $id)
  }
`;

export type UpdateNetlinkingConfigurationData = {
  configuration: NetlinkingConfiguration;
};

export type UpdateNetlinkingConfigurationVariables = {
  id: string;
  input: any;
};

export const UPDATE_NETLINKING_CONFIGURATION = gql`
  mutation($id: ID!, $input: NetlinkingConfigurationInput!) {
    configuration: updateNetlinkingConfiguration(id: $id, input: $input) {
      id
    }
  }
`;

export const GET_NETLINKING_CONFIGURATIONS = gql`
  query($input: NetlinkingConfigurationInput) {
    netlinkingConfigurations(input: $input) {
      id
      ratio
      targetKeywords
      targetUrl
    }
  }
`;

export enum NetlinkingPublicationType {
  Generic = "GENERIC",
  Thematic = "THEMATIC",
  DedicatedWebsite = "DEDICATED_WEBSITE",
  Premium = "PREMIUM",
}

export type NetlinkingPublication = {
  anchorText: string;
  createdAt: string;
  citationFlow: number;
  date: string;
  id: string;
  netlinkingConfigurationId: string;
  trustFlowScore: number;
  trustFlowTopic: string;
  type: NetlinkingPublicationType;
  url: string;
};

export type CreateNetlinkingPublicationData = {
  publication: NetlinkingPublication;
};

export type CreateNetlinkingPublicationVariables = {
  input: any;
};

export const CREATE_NETLINKING_PUBLICATION = gql`
  mutation($input: NetlinkingPublicationInput) {
    publication: createNetlinkingPublication(input: $input) {
      date
      id
      netlinkingConfigurationId
      trustFlowTopic
      type
      url
    }
  }
`;

export type DeleteNetlinkingPublicationData = {};

export type DeleteNetlinkingPublicationVariables = {
  id: string;
};

export const DELETE_NETLINKING_PUBLICATION = gql`
  mutation($id: ID!) {
    deleteNetlinkingPublication(id: $id)
  }
`;

export type UpdateNetlinkingPublicationData = {
  publication: NetlinkingPublication;
};

export type UpdateNetlinkingPublicationVariables = {
  id: string;
  input: any;
};

export const UPDATE_NETLINKING_PUBLICATION = gql`
  mutation($id: ID!, $input: NetlinkingPublicationInput!) {
    publication: updateNetlinkingPublication(
      publicationId: $id
      input: $input
    ) {
      id
    }
  }
`;

export const GET_NETLINKING_PUBLICATIONS = gql`
  query($input: NetlinkingPublicationInput) {
    netlinkingPublications(input: $input) {
      date
      id
      netlinkingConfigurationId
      trustFlowTopic
      type
      url
    }
  }
`;
