import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import styles from "./Label.module.css";

class Label extends Component {
  componentWillMount() {
    this.updateLabelContent();
  }

  componentWillReceiveProps() {
    this.updateLabelContent();
  }

  updateLabelContent() {
    const { difficulty } = this.props;

    let color;
    let label = `urlFinder.label.${difficulty}`;

    switch (difficulty) {
      case "info":
        color = styles.default;
        break;

      case "notset":
      case "client_error":
      case "server_error":
      case "hn_hierarchy_jump":
      case "notfound":
      case "different":
        color = styles.red;
        break;

      case "incomplete":
      case "duplicate":
      case "redirection":
        color = styles.orange;
        break;

      case "unique":
      case "set":
      case "equal":
      case "success":
      case "complete":
      case "correct":
        color = styles.green;
        break;

      default:
        color = styles.black;
        label = "urlFinder.label.unknown";
        break;
    }

    this.setState({ color, label });
  }

  render() {
    const { color, label } = this.state;

    if (color == null) return null;

    return (
      <span className={`${styles.label} ${color}`}>
        <FormattedMessage id={label} />
      </span>
    );
  }
}

Label.labelEnum = {
  Hard: 0,
  Medium: 1,
  Easy: 2,
};

Label.propTypes = {
  difficulty: PropTypes.string,
};

Label.defaultProps = {
  difficulty: "",
};

export default Label;
