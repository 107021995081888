import { ApolloProvider } from "@apollo/client";
import React, { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import "./index.css";

import App from "./components/App";
// import registerServiceWorker from "./registerServiceWorker";
import store from "./store";
import client from "./apollo";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://8e82026a4475424895a44560d2ad7096@sentry.io/1431307",
  });
}

render(
  <StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// registerServiceWorker();
