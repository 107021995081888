import { Dispatch } from "redux";

import api from "../api";

/*
Action dispatched to update the redux store with a fetched todos list
 */
export const RECEIVE_TODOS = "RECEIVE_TODOS";
export const F_TODOS_ERR = "F_TODOS_ERR";

function receiveTodos(todos: any) {
  return {
    type: RECEIVE_TODOS,
    todos,
  };
}

/*
Action dispatched to fetch an todos list from the API
 */
export function fetchTodos() {
  return (dispatch: Dispatch) => {
    api
      .get(`/todos`)
      .then((todos) => todos.data)
      .then((todos) => {
        dispatch(receiveTodos(todos));
      })
      .catch(() => dispatch({ type: F_TODOS_ERR }));
  };
}
