import { useLazyQuery } from "@apollo/client";
import { Delete as DeleteIcon } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import gql from "graphql-tag";
import React, { FunctionComponent, Fragment, useState } from "react";
import { connect } from "react-redux";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// Typings
import { Website } from "../types/website";

// Components
import TableView, { Column } from "../components/TableView/TableView";
import { Snackbar } from "@material-ui/core";

const QUERY = gql`
  query(
    $accountId: ID
    $domain: String
    $url: String
    $limit: Int
    $offset: Int
  ) {
    websites(
      accountId: $accountId
      domain: $domain
      url: $url
      limit: $limit
      offset: $offset
    ) {
      accountId
      domain
      id
      url
    }
    websitesCount(input: { accountId: $accountId, domain: $domain, url: $url })
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    website(id: $id) {
      accountId
      domain
      id
      url
    }
  }
`;

interface QueryData {
  websites: Website[];
  websitesCount: number;
}

interface QuerySingleData {
  website?: Website;
}

interface QueryVariables {
  accountId?: number;
  domain?: string;
  url?: string;
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number; // TODO Remove
  offset: number; // TODO Remove
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const WebsitesTableView: FunctionComponent<Props> = ({ filters }) => {
  const [open, setOpen] = useState(false);

  const columns: Column<Website>[] = [
    {
      centered: true,
      computeLink: ({ id }: Website) => `/websites/${id}`,
      computeValue: ({ id }: Website) => id,
      exclusiveFilter: true,
      filter: Filter.WEBSITE_ID,
      placeholder: "Id",
      width: 1,
    },
    {
      computeValue: ({ domain }: Website) => domain,
      filter: Filter.WEBSITE_DOMAIN,
      placeholder: "Domain",
      width: 4,
    },
    {
      computeValue: ({ url }: Website) => url,
      filter: Filter.WEBSITE_URL,
      placeholder: "Url",
      width: 7,
    },
  ];

  const rowActions = [
    {
      confirmable: true,
      handler: async () => {
        setOpen(true);
      },
      Icon: DeleteIcon,
    },
  ];

  const {
    [Filter.WEBSITE_ACCOUNT_ID]: accountId,
    [Filter.WEBSITE_ID]: id,
    [Filter.WEBSITE_DOMAIN]: domain,
    [Filter.WEBSITE_URL]: url,
  } = filters;

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: {
      accountId: +accountId,
      domain,
      url,
      limit: 40,
      offset: 0,
    },
  });

  return (
    <Fragment>
      {id ? (
        <TableView<Website, QuerySingleData, QuerySingleVariables>
          columns={columns}
          data={singleData}
          filtersScope={FilterScope.WEBSITE}
          loading={singleLoading}
          normalizeQueryData={({ website }) =>
            website
              ? { dataSet: [website], total: 1 }
              : { dataSet: [], total: 0 }
          }
          onFetch={fetchSingle}
          onRefetch={async (variables) => {
            if (refetchSingle) await refetchSingle(variables);
          }}
          rowActions={rowActions}
        />
      ) : (
        <TableView<Website, QueryData, QueryVariables>
          columns={columns}
          data={data}
          filtersScope={FilterScope.WEBSITE}
          loading={loading}
          normalizeQueryData={({ websites, websitesCount }) => ({
            dataSet: websites || [],
            total: websitesCount || 0,
          })}
          onFetch={fetch}
          onRefetch={async (variables) => {
            if (refetch) await refetch(variables);
          }}
          rowActions={rowActions}
        />
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="info">Not implemented yet</Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(WebsitesTableView);
