import { Dispatch } from "redux";

import apollo from "../apollo";

import {
  GET_DETAILED_AUDIT,
  DELETE_AUDIT,
  GET_AUDIT_URL_INFO,
} from "../queries/audits";

// Action dispatched to update the redux store with a fetched audits list
export const D_AUDIT = "D_AUDIT";
export const D_AUDIT_OK = "D_AUDIT_OK";
export const D_AUDIT_ERR = "D_AUDIT_ERR";

// Action dispatched to request an audit project deletion from the API
export function deleteAudit(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: D_AUDIT });

    try {
      await apollo.mutate({
        mutation: DELETE_AUDIT,
        variables: { id },
      });

      dispatch({
        type: D_AUDIT_OK,
        id,
      });
    } catch (e) {
      dispatch({ type: D_AUDIT_ERR });
    }
  };
}

// TODO Comment
export const F_AUDIT = "F_AUDIT";
export const F_AUDIT_OK = "F_AUDIT_OK";
export const F_AUDIT_ERR = "F_AUDIT_ERR";

// TODO Comment
export function fetchAudit(auditId: number) {
  return async (dispatch: any) => {
    dispatch({ type: F_AUDIT });

    try {
      // Fetch audit
      const {
        data: { audit },
      } = await apollo.query<any>({
        query: GET_DETAILED_AUDIT,
        variables: { id: auditId },
      });

      dispatch({
        type: F_AUDIT_OK,
        audit: { ...audit, content: audit.content.hits[0]._source },
      });
    } catch (e) {
      dispatch({ type: F_AUDIT_ERR });
    }
  };
}

// TODO Comment
export const F_AUDIT_URL_INFO = "F_AUDIT_URL_INFO";
export const F_AUDIT_URL_INFO_OK = "F_AUDIT_URL_INFO_OK";
export const F_AUDIT_URL_INFO_ERR = "F_AUDIT_URL_INFO_ERR";

export function fetchAuditUrlInfo(auditId: number, url: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: F_AUDIT_URL_INFO });

    try {
      const {
        data: {
          audit: { page },
        },
      } = await apollo.query<any>({
        query: GET_AUDIT_URL_INFO,
        variables: {
          auditId,
          url,
        },
      });

      dispatch({
        type: F_AUDIT_URL_INFO_OK,
        urlInfo: page,
      });
    } catch (e) {
      dispatch({ type: F_AUDIT_URL_INFO_ERR });
    }
  };
}
