import { useLazyQuery } from "@apollo/client";
import { Delete as DeleteIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";

// Components
import TableView, { Column } from "../components/TableView/TableView";
import CreationForm from "../components/CreationForms/NetlinkingCampaign";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// GraphQL
import apollo from "../apollo";
import { DELETE_NETLINKING_CAMPAIGN } from "../queries/netlinking";

// Typings
import {
  NetlinkingCampaign,
  NetlinkingCampaignStatus,
} from "../types/netlinking";

const QUERY = gql`
  query($input: NetlinkingCampaignInput, $limit: Int, $offset: Int) {
    netlinkingCampaigns(input: $input, limit: $limit, offset: $offset) {
      id
      name
      status
    }
    netlinkingCampaignsCount(input: $input)
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    netlinkingCampaign(id: $id) {
      id
      name
      status
    }
  }
`;

interface QueryData {
  netlinkingCampaigns: NetlinkingCampaign[];
  netlinkingCampaignsCount: number;
}

interface QuerySingleData {
  netlinkingCampaign?: NetlinkingCampaign;
}

interface QueryVariables {
  input: {
    name?: string;
    status?: NetlinkingCampaignStatus;
  };
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number;
  offset: number;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const NetlinkingCampaignsTableView: FunctionComponent<Props> = ({
  filters,
}) => {
  const columns: Column<NetlinkingCampaign>[] = [
    {
      centered: true,
      computeLink: ({ id }: NetlinkingCampaign) =>
        `/netlinking-campaigns/${id}`,
      computeValue: ({ id }: NetlinkingCampaign) => id,
      exclusiveFilter: true,
      filter: Filter.NETLINKING_CAMPAIGN_ID,
      placeholder: "Id",
      width: 2,
    },
    {
      computeLink: ({ id }: NetlinkingCampaign) =>
        `/netlinking-campaigns/${id}`,
      computeValue: ({ name }: NetlinkingCampaign) => name,
      filter: Filter.NETLINKING_CAMPAIGN_NAME,
      placeholder: "Name",
      width: 8,
    },
    {
      centered: true,
      computeValue: ({ status }: NetlinkingCampaign) => status,
      filter: Filter.NETLINKING_CAMPAIGN_STATUS,
      options: [
        { name: "Any", value: NetlinkingCampaignStatus.Any },
        { name: "Pending", value: NetlinkingCampaignStatus.Pending },
        { name: "Running", value: NetlinkingCampaignStatus.Running },
        { name: "Finished", value: NetlinkingCampaignStatus.Finished },
      ],
      placeholder: "Status",
      width: 2,
    },
  ];

  const rowActions = [
    {
      confirmable: true,
      handler: ({ id }: NetlinkingCampaign) =>
        apollo.mutate({
          mutation: DELETE_NETLINKING_CAMPAIGN,
          variables: { id },
        }),
      Icon: DeleteIcon,
    },
  ];

  const {
    [Filter.NETLINKING_CAMPAIGN_ID]: id,
    [Filter.NETLINKING_CAMPAIGN_NAME]: name,
    [Filter.NETLINKING_CAMPAIGN_STATUS]: status,
  } = filters;

  const input: QueryVariables["input"] = {
    name,
  };

  if (status && status !== NetlinkingCampaignStatus.Any) {
    input.status = status as NetlinkingCampaignStatus;
  }

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: { input, limit: 40, offset: 0 },
  });

  if (id) {
    return (
      <TableView<NetlinkingCampaign, QuerySingleData, QuerySingleVariables>
        columns={columns}
        CreationForm={CreationForm}
        data={singleData}
        filtersScope={FilterScope.NETLINKING_CAMPAIGN}
        loading={singleLoading}
        normalizeQueryData={({ netlinkingCampaign }) =>
          netlinkingCampaign
            ? { dataSet: [netlinkingCampaign], total: 1 }
            : { dataSet: [], total: 0 }
        }
        onFetch={fetchSingle}
        onRefetch={async (variables) => {
          if (refetchSingle) await refetchSingle(variables);
        }}
        rowActions={rowActions}
      />
    );
  }

  return (
    <TableView<NetlinkingCampaign, QueryData, QueryVariables>
      columns={columns}
      CreationForm={CreationForm}
      data={data}
      filtersScope={FilterScope.NETLINKING_CAMPAIGN}
      loading={loading}
      normalizeQueryData={({
        netlinkingCampaigns,
        netlinkingCampaignsCount,
      }) => ({
        dataSet: netlinkingCampaigns || [],
        total: netlinkingCampaignsCount || 0,
      })}
      onFetch={fetch}
      onRefetch={async (variables) => {
        if (refetch) await refetch(variables);
      }}
      rowActions={rowActions}
    />
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(NetlinkingCampaignsTableView);
