import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";

// GraphQL
import apollo from "../../apollo";
import {
  CREATE_ADS_CAMPAIGN,
  UPDATE_ADS_CAMPAIGN,
  DELETE_ADS_CAMPAIGN,
} from "../../queries/adsCampaigns";

// Typings
import { CreationForm } from "./index";
import { AdsCampaignStatus } from "../../types/adsCampaigns";

type FormData = {
  googleAdsId: string;
  datastudioId: string;
  status: string;
  budget: number;
  websiteId: number;
};

const AdsCampaign: CreationForm = ({ closeDialog }) => {
  const { control, errors, handleSubmit, register } = useForm<FormData>();

  const onSubmit = handleSubmit((data) => {
    apollo
      .mutate({
        mutation: CREATE_ADS_CAMPAIGN,
        variables: {
          ...data,
          budget: +data.budget,
          websiteId: +data.websiteId,
        },
      })
      .then(() => closeDialog());
  });

  return (
    <Fragment>
      <DialogTitle>Create a campaign</DialogTitle>

      <DialogContent>
        <TextField
          error={!!errors.googleAdsId}
          fullWidth
          inputRef={register({ required: false })}
          label="Google Ads Customer Account ID"
          margin="dense"
          name="googleAdsCustomerAccountId"
        />

        <TextField
          error={!!errors.datastudioId}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Data Studio ID"
          margin="dense"
          name="datastudioId"
          required
          type="string"
        />

        <TextField
          error={!!errors.budget}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Budget mensuel"
          margin="dense"
          name="budget"
          required
          type="number"
        />

        <FormControl error={!!errors.status} fullWidth margin="dense" required>
          <InputLabel id="status-label">Status</InputLabel>
          <Controller
            as={
              <Select labelId="status-label">
                <MenuItem value={AdsCampaignStatus.Running}>Running</MenuItem>
                <MenuItem value={AdsCampaignStatus.Stopped}>Stopped</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={AdsCampaignStatus.Running}
            name="status"
            rules={{ required: true }}
          />
        </FormControl>

        <TextField
          error={!!errors.websiteId}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Website ID"
          margin="dense"
          name="websiteId"
          required
          type="number"
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeDialog} variant="contained">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AdsCampaign;
