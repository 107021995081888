import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Icon,
  TextField,
} from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";

import api from "../../api";

type Props = {
  userId: number;
};

const Impersonation: FunctionComponent<Props> = ({ userId }) => {
  // Declare React hooks
  const [token, setToken] = useState("");

  // Fetch the impersonation token if necessary
  useEffect(() => {
    if (userId && !token) {
      api
        .get(`/users/${userId}/impersonate`)
        .then((res) => setToken(res.data.accessToken));
    }
  });

  const copyTokenToClipboard = () => {
    navigator.clipboard.writeText(token);
  };

  return (
    <Card>
      <CardHeader avatar={<Icon>engineering</Icon>} title="Impersonation" />

      <CardContent>
        <p>
          You can connect to app.octopulse.io as the user in order to access his
          account for debugging / support purpose.
        </p>
        <p>Copy the token below and paste it on Octopulse.</p>
        <TextField disabled value={token} />
      </CardContent>
      {token && (
        <CardActions>
          <Button color="primary" onClick={copyTokenToClipboard}>
            Copy token
          </Button>
          <Button
            color="primary"
            href={`${process.env.REACT_APP_FRONT_URL}/impersonate`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Octopulse
          </Button>
        </CardActions>
      )}
      {!token && (
        <CardActions>
          <CircularProgress />
        </CardActions>
      )}
    </Card>
  );
};

export default Impersonation;
