import gql from "graphql-tag";

export const CREATE_ADS_CAMPAIGN = gql`
  mutation(
    $googleAdsCustomerAccountId: String
    $datastudioId: String
    $status: String
    $budget: Int
    $websiteId: ID!
  ) {
    createAdsCampaign(
      googleAdsCustomerAccountId: $googleAdsCustomerAccountId
      datastudioId: $datastudioId
      status: $status
      budget: $budget
      websiteId: $websiteId
    ) {
      id
      websiteId
      googleAdsCustomerAccountId
      datastudioId
      status
      budget
    }
  }
`;

export const UPDATE_ADS_CAMPAIGN = gql`
  mutation(
    $id: ID!
    $googleAdsCustomerAccountId: String
    $datastudioId: String
    $status: String
    $budget: Int
    $websiteId: ID!
  ) {
    updateAdsCampaign(
      id: $id
      googleAdsCustomerAccountId: $googleAdsCustomerAccountId
      datastudioId: $datastudioId
      budget: $budget
      status: $status
      websiteId: $websiteId
    ) {
      id
    }
  }
`;

export const DELETE_ADS_CAMPAIGN = gql`
  mutation($id: ID!) {
    deleteAdsCampaign(id: $id)
  }
`;
