import React from "react";
import { IntlShape } from "react-intl";
import { Dispatch } from "redux";
import numbro from "numbro";

import { isItemSelected } from "./utils";

import styles from "./keywords.module.css";
import { addItemToFilters } from "../../../../actions/rankings";

interface KeywordIconProps {
  rank: any;
}

const KeywordIcon = ({ rank }: KeywordIconProps) => {
  if (rank.diff === 0 && rank.value > 0 && rank.value < 101) {
    // return null;
    return <b style={{ marginLeft: "5px", color: "lightgrey" }}>=</b>;
  }
  if (typeof rank.diff === "string")
    return (
      <span
        className={styles.label}
        style={{
          background: rank.diff === "gone" ? "red" : "darkblue",
        }}
      >
        {rank.diff}
      </span>
    );
  if (rank.value > 0 && rank.value < 101)
    return (
      <i
        className={`fa fa-caret-${rank.diff > 0 ? "up" : "down"}`}
        aria-hidden="true"
        style={{
          marginLeft: "5px",
          color: rank.diff > 0 ? "green" : "red",
        }}
      />
    );
  return null;
};

function isExist(value: any) {
  return typeof value !== "undefined" && value !== null;
}

export default function getKeywordsColumn(
  dispatch: Dispatch,
  intl: IntlShape,
  rankingData: any
) {
  return [
    {
      Header: `${intl.formatMessage({
        id: "ranking.keywords.table.keywords",
      })} (${
        rankingData && rankingData.keywords ? rankingData.keywords.total : "0"
      })`,
      accessor: "keyword",
      Filter: (
        { filter, onChange }: { filter: any; onChange: any } // use custom filter in order to put a placeholder
      ) => (
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: "ranking.keywords.table.searchPlaceholder",
          })}
          value={filter ? filter.value : ""}
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
        />
      ),
      Cell: (row: any) =>
        isItemSelected(row.value, rankingData.selectedKeywords, "keyword") ? (
          <span>
            <i
              className="fa fa-check"
              aria-hidden="true"
              style={{ marginRight: "5px", color: "green" }}
            />
            {row.value}
          </span>
        ) : (
          <span
            onClick={() =>
              dispatch(addItemToFilters("selectedKeywords", row.original))
            }
            className={styles.clickable}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            />
            {row.value}
          </span>
        ),
    },
    {
      Header: intl.formatMessage({
        id: "ranking.keywords.table.rank",
      }),
      accessor: "rank",
      filterable: false,
      maxWidth: 100,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {row.value.value < 0 || row.value.value > 100 ? "-" : row.value.value}
          <KeywordIcon rank={row.original.rank} />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({
        id: "ranking.keywords.table.searchVolume",
      }),
      accessor: "search_volume",
      maxWidth: 200,
      filterable: false,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {isExist(row.value) && row.value >= 0
            ? numbro(row.value).format()
            : "-"}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({
        id: "ranking.keywords.table.estimatedTraffic",
      }),
      accessor: "traffic",
      maxWidth: 180,
      filterable: false,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {isExist(row.value) ? numbro(row.value).format() : "-"}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({
        id: "ranking.keywords.table.competition",
      }),
      accessor: "competition",
      maxWidth: 160,
      filterable: false,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {isExist(row.value) && row.value >= 0 ? row.value.toFixed(2) : "-"}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({
        id: "ranking.keywords.table.url",
      }),
      accessor: "url",
      sortable: false,
      filterable: false,
      Cell: (row: any) => (
        <a href={row.value} target="_blank" rel="noopener noreferrer">
          {row.value}
        </a>
      ),
    },
  ];
}
