import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Row from "./Row";
import UrlFinderView from "./UrlFinderView";

const pageStructure = [
  {
    title: "urlFinder.mainTags",
    data: [
      {
        title: "urlFinder.title",
        value: "title",
        status: "title_status",
      },
      {
        title: "urlFinder.description",
        value: "description",
        status: "description_status",
      },
      {
        title: "urlFinder.h1",
        value: "h1",
        status: "h1_status",
      },
      {
        title: "urlFinder.canonical",
        value: "canonical.raw",
        status: "canonical_status",
        link: true,
      },
    ],
  },
  {
    title: "urlFinder.socialTags",
    data: [
      {
        title: "urlFinder.openGraph",
        value: "opengraph",
        status: "opengraph_status",
        type: "social_tags",
      },
      {
        title: "urlFinder.twitterCard",
        value: "twittercard",
        status: "twittercard_status",
        type: "social_tags",
      },
    ],
  },
  {
    title: "urlFinder.tagsHierarchy",
    status: "hn_hierarchy_jump",
    data: [
      {
        title: "urlFinder.h1",
        value: "h1",
        type: "hn",
      },
      {
        title: "urlFinder.h2",
        value: "h2",
        type: "hn",
      },
      {
        title: "urlFinder.h3",
        value: "h3",
        type: "hn",
      },
      {
        title: "urlFinder.h4",
        value: "h4",
        type: "hn",
      },
      {
        title: "urlFinder.h5",
        value: "h5",
        type: "hn",
      },
      {
        title: "urlFinder.h6",
        value: "h6",
        type: "hn",
      },
    ],
  },
];

class HtmlTags extends Component {
  static parseHn(hnArray, name) {
    if (!hnArray) return undefined;
    let highestH = 0;
    const hn = [];

    hnArray.forEach(element => {
      highestH =
        Number(element.name[1]) > highestH ? Number(element.name[1]) : highestH;
      if (element.name === name) {
        hn.push(element.value);
      }
    });

    // Si array vide et pas de Hn plus tard, return undefined pour ne pas afficher les titres vides
    if (hn.length === 0 && Number(name[1]) > highestH) {
      return undefined;
    }

    return hn;
  }

  static parseSocialTags(socialTagsArray) {
    if (!socialTagsArray) return undefined;

    return socialTagsArray.map(element => (
      <span>
        <b>{element.name}</b> : {element.content}
      </span>
    ));
  }

  titleStatus(status) {
    const { audit } = this.props;

    if (audit.current.url[status]) {
      return "hn_hierarchy_jump";
    }

    return "correct";
  }

  renderContainersRows(rowData) {
    // const { audit, fetchUrlData } = this.props;
    const { audit } = this.props;

    return rowData.data.map((element, index) => {
      let data;
      let status;

      if (element.type === "hn") {
        data = HtmlTags.parseHn(audit.current.url.hn, element.value);
      } else if (element.type === "social_tags") {
        data = HtmlTags.parseSocialTags(audit.current.url[element.value]);
        status = audit.current.url[element.status];
      } else {
        data = audit.current.url[element.value];
        status = audit.current.url[element.status];
      }

      if (data !== undefined) {
        return (
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={element.title}
            data={data}
            status={status}
            link={element.link}
            // fetchUrlData={fetchUrlData}
          />
        );
      }

      return null;
    });
  }

  render() {
    const { audit } = this.props;

    return (
      <UrlFinderView
        audit={audit}
        pageStructure={pageStructure}
        renderContainersRows={rowData => this.renderContainersRows(rowData)}
        titleStatus={status => this.titleStatus(status)}
      />
    );
  }
}

HtmlTags.propTypes = {
  audit: PropTypes.shape().isRequired,
  // fetchUrlData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ audit }) => ({ audit });

export default connect(mapStateToProps)(HtmlTags);
