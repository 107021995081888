import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";

import api from "../../api";
import { fetchOrder } from "../../actions/orders";
import { requestQuoteDeletion } from "../../actions/quotes";

import Spinner from "../Spinner";
import Tile from "../UI/Tile/Tile";

import styles from "./OrderView.module.css";
import globalStyles from "../../index.module.css";

class OrderView extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(fetchOrder(match.params.id));
  }

  downloadHandler = (id) => {
    api
      .get(`/quotes/${id}/pdf`, {
        responseType: "blob",
      })
      .then((res) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([res.data], { type: "application/pdf" });

        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((e) => console.error(e));
  };

  deleteHandler = (id) => {
    const { dispatch } = this.props;

    dispatch(requestQuoteDeletion(id));
  };

  render() {
    const { match, quotes } = this.props;

    if (!quotes)
      return (
        <div className={styles.wrapper}>
          <Tile name="orders.quotes">
            <Spinner />
          </Tile>
        </div>
      );

    return (
      <div className={styles.wrapper}>
        <Tile name="orders.quotes">
          <div className={styles.quotesWrapper}>
            <div className={styles.header}>
              <div>Id</div>
              <div>Price</div>
              <div>Status</div>
              <div>
                <a
                  className={globalStyles.primaryTrigger}
                  href="/commercial-proposal.pdf"
                  download
                >
                  Commercial Proposal
                </a>
                <Link
                  className="button default"
                  to={`/orders/${match.params.id}/new-quote`}
                >
                  Add
                </Link>
              </div>
            </div>
            <div className={styles.quotesList}>
              {quotes.length ? (
                quotes.map((quote) => (
                  <div className={styles.quoteRow} key={quote.id}>
                    <div>{quote.id}</div>
                    <div>
                      {Number.isInteger(quote.price)
                        ? `${quote.price} €`
                        : "Unknown"}
                    </div>
                    <div>{_.capitalize(quote.status) || "Unknown"}</div>
                    <div>
                      <button
                        className={globalStyles.primaryTrigger}
                        onClick={() => this.downloadHandler(quote.id)}
                        type="button"
                      >
                        <i className="fas fa-download" />
                      </button>
                      <button
                        className={globalStyles.deleteTrigger}
                        onClick={() => this.deleteHandler(quote.id)}
                        type="button"
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <span> There is no quotes for the moment</span>
              )}
            </div>
          </div>
        </Tile>
      </div>
    );
  }
}

OrderView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  quotes: PropTypes.arrayOf(PropTypes.shape()),
};

OrderView.defaultProps = {
  quotes: null,
};

const mapStateToProps = (state) => ({
  quotes: state.quotes,
});

export default connect(mapStateToProps)(OrderView);
