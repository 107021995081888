import gql from "graphql-tag";

import api from "../../api";
import apollo from "../../apollo";

const helpers = {
  launchSerpCrawl(rankId: any, lang: any) {
    return api.post(`/rankings/${rankId}/run`, {
      lang,
    });
  },

  postRankingProject(
    name: any,
    country: any,
    date: any,
    url: any,
    domain: any
  ) {
    return api.post(`/rankings/`, {
      name,
      country,
      date,
      url,
      domain,
    });
  },

  postKeywords(id: any, keywords: any, country: any) {
    return api.post(`/rankings/${id}/keywords`, {
      keywords,
      country,
    });
  },

  deleteRankingProject(id: any) {
    return api.delete(`/rankings/${id}`).then((res) => res.data);
  },

  async getRankingProject(id: number | string) {
    const {
      data: { ranking },
    } = await apollo.query<any>({
      query: gql`
        query($id: ID!) {
          ranking(id: $id) {
            country
            id
            language
            periodicity
            website {
              id
              domain
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

    return ranking;
  },

  getKeywords(id: any, from: any, size: any) {
    return api.get(`/rankings/${id}/keywords`, {
      params: {
        from,
        size,
      },
    });
  },

  getKeywordsTotal(id: any) {
    return api
      .get(`/rankings/${id}/keywords/count`)
      .then((response) => response);
  },

  getRankingProjects(last_crawled = 0) {
    return api
      .get(`/users/${0}/rankings/`, {
        params: {
          last_crawled,
        },
      })
      .then((res) => res.data);
  },

  getRankingProjectInfos(id: any, dateFrom: any, dateTo: any, platform: any) {
    return api
      .get(`/rankings/${id}/stats`, {
        params: {
          date_from: dateFrom.format("YYYY-MM-DD"),
          date_to: dateTo.format("YYYY-MM-DD"),
          platform,
        },
      })
      .then((res) => res.data);
  },

  getCompetitors(
    idRank: any,
    dateFrom: any,
    dateTo: any,
    platform: any,
    from: any,
    size: any,
    sort: any
  ) {
    return api
      .get(`/rankings/${idRank}/competitors`, {
        params: {
          date_from: dateFrom.format("YYYY-MM-DD"),
          date_to: dateTo.format("YYYY-MM-DD"),
          platform,
          from,
          size,
          sort,
        },
      })
      .then((res) => res.data);
  },

  addKeywords(keywords: any, idRankingProject: any) {
    return api
      .post(`/rankings/${idRankingProject}/keywords`, {
        keywords,
      })
      .then((res) => res.data);
  },
};

export default helpers;
