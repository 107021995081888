import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

// Components
import AuditOverView from "./OverView/OverView";
import AuditH1View from "../../containers/AuditView/Optimizations/H1";
import AuditHnView from "../../containers/AuditView/Optimizations/Hn";
import AuditTitleView from "../../containers/AuditView/Optimizations/Title";
import AuditMetaView from "../../containers/AuditView/Optimizations/Meta";
import AuditAltView from "../../containers/AuditView/Optimizations/Alt";
import ContentDuplicates from "../../containers/AuditView/Optimizations/Duplicate";
import ContentWordsCount from "../../containers/AuditView/Optimizations/WordCount";
import ContentCanonicals from "../../containers/AuditView/Optimizations/Canonicals";
import ContentOpenGraph from "../../containers/AuditView/Optimizations/OpenGraph";
import ContentTwitterCard from "../../containers/AuditView/Optimizations/TwitterCard";
import PerformanceHTTPCodes from "../../containers/AuditView/Optimizations/HttpCode";
import PerformanceLoadTime from "../../containers/AuditView/Optimizations/LoadTime";
import PerformanceWeight from "../../containers/AuditView/Optimizations/Weight";
import Internationalization from "../../containers/AuditView/Optimizations/International";
import ArchitectureTLS from "../../containers/AuditView/Optimizations/TLS";
import ArchitectureURL from "../../containers/AuditView/Optimizations/URL";
import ArchitectureDepth from "../../containers/AuditView/Optimizations/Depth";
import ArchitectureRobotsTxt from "./Architecture/RobotsTxt";
import UrlFinder from "./UrlFinder/UrlFinder";
import Spinner from "../Spinner";

// GraphQL
import { GET_AUDIT } from "../../queries/audits";

// Redux related
import { fetchAudit } from "../../actions/audits";

// Assets
import styles from "./AuditView.module.css";

// Typings
import { AuditStatus } from "../../types/audit";

interface StateProps {
  audit: any;
}

interface DispatchProps {
  dispatch: ThunkDispatch<{}, {}, AnyAction>;
}

type Props = StateProps & DispatchProps;

const AuditView: FunctionComponent<Props> = ({ audit, dispatch }) => {
  const { id: auditId } = useParams();
  const { loading, data } = useQuery(GET_AUDIT, {
    variables: { id: auditId },
  });

  // TODO Remove
  useEffect(() => {
    dispatch(fetchAudit(auditId));
  }, [dispatch, auditId]);

  // Display a spinner if the audit information is being fetched
  if (loading || !data) {
    return (
      <div className={styles.wrapper}>
        <CircularProgress />
      </div>
    );
  }

  switch (data.audit.status) {
    case AuditStatus.Failed:
    case AuditStatus.Stopped:
    case AuditStatus.Unauthorized:
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.error}>Audit failed</h1>
        </div>
      );
    case AuditStatus.Finished:
      break;
    default:
      return (
        <div className={styles.wrapper}>
          <h1>Audit is still running</h1>
          <Spinner size={32} />
        </div>
      );
  }

  return (
    // prettier-ignore
    <Switch>
        <Route path="/audits/:id/overview" component={AuditOverView} />
        <Route path="/audits/:id/optimizations/h1" component={AuditH1View} />
        <Route path="/audits/:id/optimizations/hn" component={AuditHnView} />
        <Route path="/audits/:id/optimizations/title" component={AuditTitleView} />
        <Route path="/audits/:id/optimizations/meta" component={AuditMetaView} />
        <Route path="/audits/:id/optimizations/alt" component={AuditAltView} />
        <Route path="/audits/:id/optimizations/duplicates" component={ContentDuplicates}/>
        <Route path="/audits/:id/optimizations/words-count" component={ContentWordsCount}/>
        <Route path="/audits/:id/optimizations/canonicals" component={ContentCanonicals}/>
        <Route path="/audits/:id/optimizations/open-graph" component={ContentOpenGraph}/>
        <Route path="/audits/:id/optimizations/twitter-card" component={ContentTwitterCard}/>
        <Route path="/audits/:id/optimizations/http-codes" component={PerformanceHTTPCodes}/>
        <Route path="/audits/:id/optimizations/load-time" component={PerformanceLoadTime}/>
        <Route path="/audits/:id/optimizations/weight" component={PerformanceWeight}/>
        <Route path="/audits/:id/optimizations/internationalization" component={Internationalization}/>
        <Route path="/audits/:id/optimizations/tls" component={ArchitectureTLS}/>
        <Route path="/audits/:id/optimizations/url" component={ArchitectureURL}/>
        <Route path="/audits/:id/optimizations/depth" component={ArchitectureDepth}/>
        <Route path="/audits/:id/optimizations/robots-txt" component={ArchitectureRobotsTxt}/>
        <Route path="/audits/:id/optimizations"
          render={() => (
            <Redirect to={`/audits/${auditId}/optimizations/h1`} />
          )}
        />
        <Route path="/audits/:id/url-finder" component={UrlFinder} />
        <Redirect to={`/audits/${auditId}/overview`} />
      </Switch>
  );
};

const mapStateToProps = ({ audit }: any) => ({ audit });

export default connect(mapStateToProps)(AuditView);
