import {
  CardContent,
  Card,
  CardActions,
  CardHeader,
  Icon,
  Button,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import moment from "moment";

// Redux
import { Filter, setFilter } from "../../actions/filters";

// Routing
import history from "../../history";

import styles from "./Activity.module.css";

interface OwnProps {
  accountId: number;
  audits: any[];
  createdAt: any;
  rankings: any[];
  websites: any[];
}

interface DispatchProps {
  filterAuditsByAccountId: Function;
  filterRankingsByAccountId: Function;
  filterWebsitesByAccountId: Function;
  filterAdsCampaignsByAccountId: Function;
}

type Props = OwnProps & DispatchProps;

const Activity: FunctionComponent<Props> = ({
  accountId,
  audits,
  createdAt,
  rankings,
  websites,
  filterAuditsByAccountId,
  filterRankingsByAccountId,
  filterAdsCampaignsByAccountId,
  filterWebsitesByAccountId,
}) => (
  <Card>
    <CardHeader avatar={<Icon>explore</Icon>} title="Activity" />

    <CardContent>
      <p>
        User registered&nbsp;
        <span className={styles.emphasized}>
          {moment(createdAt).calendar()}
        </span>
        . His last recorded activity was&nbsp;
        <span className={styles.emphasized}>unknown</span>. For now, he is
        in&nbsp;
        <span className={styles.emphasized}>{audits.length}</span> audit(s)
        and&nbsp;
        <span className={styles.emphasized}>{rankings.length}</span>
        &nbsp;ranking(s) projects and has&nbsp;
        <span className={styles.emphasized}>{websites.length}</span>
        &nbsp;website(s).
      </p>
    </CardContent>

    <CardActions>
      <Button
        color="primary"
        onClick={() => {
          filterAuditsByAccountId(accountId);
          history.push("/audits");
        }}
      >
        Audits
      </Button>
      <Button
        color="primary"
        onClick={() => {
          filterRankingsByAccountId(accountId);
          history.push("/rankings");
        }}
      >
        Rankings
      </Button>
      <Button
        color="primary"
        onClick={() => {
          filterAdsCampaignsByAccountId(accountId);
          history.push("/ads-campaigns");
        }}
      >
        Ads
      </Button>
      <Button
        color="primary"
        onClick={() => {
          filterWebsitesByAccountId(accountId);
          history.push("/websites");
        }}
      >
        Websites
      </Button>
    </CardActions>
  </Card>
);

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
  filterAuditsByAccountId: (accountId: number) =>
    dispatch(setFilter(Filter.AUDIT_ACCOUNT_ID, accountId)),
  filterRankingsByAccountId: (accountId: number) =>
    dispatch(setFilter(Filter.RANKING_ACCOUNT_ID, accountId)),
  filterAdsCampaignsByAccountId: (accountId: number) =>
    dispatch(setFilter(Filter.ADS_CAMPAIGN_ACCOUNT_ID, accountId)),
  filterWebsitesByAccountId: (accountId: number) =>
    dispatch(setFilter(Filter.WEBSITE_ACCOUNT_ID, accountId)),
});

export default connect(null, mapDispatchToProps)(Activity);
