import React, { Component } from "react";
import numbro from "numbro";

import tileStyles from "./OverviewTile.module.css";

interface ArrowIconProps {
  value: any;
}

const ArrowIcon = ({ value }: ArrowIconProps) => {
  return (
    <i
      className={`fa fa-caret-${value > 0 ? "up" : "down"}`}
      aria-hidden="true"
      style={{
        margin: "0px 5px",
        color: value > 0 ? "green" : "red",
      }}
    />
  );
};

interface TileProps {
  diff?: any;
  diff2?: any;
  history?: any;
  link: any;
  percentage?: any;
  position?: any;
  title: any;
  value: any;
}

class Tile extends Component<TileProps> {
  getColorPosition = (pos: any) => {
    switch (true) {
      case !pos:
        return "darkgrey";
      case pos <= 10:
        return "green";
      case pos > 10 && pos <= 20:
        return "orange";
      case pos > 20:
        return "red";
      default:
        return "grey";
    }
  };

  showPercentage = (value: any) => {
    if (value * 100 >= 0 && value * 100 < 1) return <span>{"<1%"}</span>;
    return <span>{Math.round(value * 100)}%</span>;
  };

  showPosition = (value: any) => {
    if (value === 101) return <span>n/a</span>;
    return (
      <span style={{ color: this.getColorPosition(value), fontWeight: "bold" }}>
        {value}
      </span>
    );
  };

  renderValue = (value: any) => {
    const { percentage, position } = this.props;

    if (position) {
      return this.showPosition(value);
    }

    if (percentage) {
      return this.showPercentage(value);
    }

    // @ts-ignore
    return <span>{numbro(value).format()}</span>;
  };

  render() {
    const { value, diff, history, title, diff2, link, percentage } = this.props;

    return (
      <div
        className={tileStyles.wrapper}
        onClick={() => link && history.push(link)}
      >
        <div className={tileStyles.title}>
          <span>{title}</span>
        </div>
        <div className={tileStyles.container}>
          <div className={tileStyles.value}>
            <span style={{ fontSize: "3em", lineHeight: 1 }}>
              {this.renderValue(value)}
            </span>
            <div style={{ marginTop: "10px" }}>
              {diff !== undefined && Number(diff) !== 0 && (
                <span
                  style={{
                    fontSize: "1.5em",
                    lineHeight: 1,
                    color: diff > 0 ? "green" : "red",
                  }}
                >
                  {percentage ? `${Math.abs(diff * 100)}%` : Math.abs(diff)}
                  <ArrowIcon value={diff} />
                </span>
              )}
              {diff2 !== undefined && Number(diff2) !== 0 && (
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "1.5em",
                    lineHeight: 1,
                    color: diff2 > 0 ? "green" : "red",
                  }}
                >
                  <ArrowIcon value={diff2} />
                  {/*
                    // @ts-ignore */}
                  {numbro(Math.abs(diff2)).format()}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tile;
