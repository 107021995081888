import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import moment from "moment/moment";

// Types
import { Audit } from "../../../types/audit";
import api from "../../../api";

type Props = {
  audit: Audit;
};

const Information: FunctionComponent<Props> = ({ audit }) => {
  // @ts-ignore
  const auditObsolete = audit.content.hits.length === 0;
  const ms = moment(audit.dateEnd).diff(moment(audit.dateStart));
  let duration = moment.duration(ms);
  const [confirmed, setConfirmation] = useState(false);
  const [error, setError] = useState();

  const s =
    // eslint-disable-next-line no-underscore-dangle
    // @ts-ignore
    duration._data.hours > 0
      ? `${Math.floor(duration.asHours())} hours`
      : `${Math.floor(duration.asMinutes())} minutes`;

  // @ts-ignore
  duration = s === "0 minutes" ? "1 minute" : s;

  let scoreColor = "darkgrey";

  switch (true) {
    case audit.score >= 90:
      scoreColor = "#27A49B";
      break;
    case audit.score >= 60 && audit.score < 90:
      scoreColor = "#EEB656";
      break;
    case audit.score >= 0 && audit.score < 60:
      scoreColor = "#f24d4b";
      break;
    default:
      break;
  }

  const onClick = async () => {
    try {
      await api.post(`/audits/${audit.id}/order`);
      setError(undefined);
    } catch (err) {
      setError(err);
    }
    setConfirmation(true);
  };

  return (
    <Card>
      <Dialog open={confirmed}>
        <DialogTitle>Confirmation</DialogTitle>

        <DialogContent>
          {error ? `Error : ${error}` : "Generation started ..."}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setConfirmation(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: scoreColor }}>
            {audit.score || "?"}
          </Avatar>
        }
        title={audit.website.url}
        subheader={audit.crawlId}
      />

      <CardContent>
        <div>
          Ended on{" "}
          <b>{audit.dateEnd && new Date(audit.dateEnd).toDateString()}</b>
        </div>
        <div>
          Lasted <b>{duration}</b>
        </div>
        {auditObsolete && (
          <div style={{ color: "red" }}>Audit obsolete, rerun an audit</div>
        )}
        {!!audit.pagesCount && (
          <div>
            <b>{audit.pagesCount}</b> pages analysed
          </div>
        )}
        <Button style={{ marginTop: "20px" }} color="primary" onClick={onClick}>
          Generate Quote & Briefing
        </Button>
      </CardContent>
    </Card>
  );
};

export default Information;
