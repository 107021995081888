import gql from "graphql-tag";

export const CREATE_WEBSITE = gql`
  mutation($input: WebsiteInput!) {
    createWebsite(input: $input) {
      id
    }
  }
`;

export const GET_WEBSITE = gql`
  query($id: ID!) {
    website(id: $id) {
      domain
      id
      url
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation($id: ID!, $input: WebsiteInput!) {
    updateWebsite(id: $id, input: $input)
  }
`;

export const GET_WEBSITES = gql`
  query(
    $accountId: ID
    $domain: String
    $url: String
    $limit: Int
    $offset: Int
  ) {
    websites(
      accountId: $accountId
      domain: $domain
      url: $url
      limit: $limit
      offset: $offset
    ) {
      domain
      id
      url
    }
  }
`;

export const GET_WEBSITES_COUNT = gql`
  query($input: WebsiteInput) {
    websitesCount(input: $input)
  }
`;
