import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import Add from "./Add";
import AddKeywords from "./AddKeywords";
import Competitors from "./Competitors";
import Keywords from "./Keywords";
import Overview from "./Overview";

const RankingView = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => (
  <Switch>
    <Route path="/rankings/add" component={Add} />
    <Route path="/rankings/:id/overview" component={Overview} />
    <Route path="/rankings/:id/add-keywords" component={AddKeywords} />
    <Route path="/rankings/:id/competitors" component={Competitors} />
    <Route path="/rankings/:id/keywords" component={Keywords} />
    <Redirect to={`/rankings/${id}/overview`} />
  </Switch>
);

export default RankingView;
