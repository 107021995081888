import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  Button,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

// Assets
import styles from "./Ban.module.css";

type Props = {
  userId: number;
  isBlocked: boolean;
  updateUser: any;
};

const Ban: FunctionComponent<Props> = ({ isBlocked, userId, updateUser }) => (
  <Card>
    <CardHeader avatar={<Icon>block</Icon>} title="Account suspension" />

    <CardContent>
      <Typography variant="body2">
        If a user break our terms of agreement or has his account compromised,
        you can suspend it.
        <br />
        <br />
        When suspended, nobody can log in an account anymore, even with the
        right credentials.
        <br />
        <br />
        Currently, the user account is
        {isBlocked ? (
          <span className={styles.unauthorized}> unauthorized</span>
        ) : (
          <span className={styles.authorized}> authorized</span>
        )}
        .
      </Typography>
    </CardContent>

    <CardActions>
      <Button
        color={isBlocked ? "primary" : "secondary"}
        onClick={() =>
          updateUser({
            variables: {
              id: userId,
              input: { isBlocked: !isBlocked },
            },
          })
        }
      >
        {isBlocked ? "Allow user" : "Block user"}
      </Button>
    </CardActions>
  </Card>
);

export default Ban;
