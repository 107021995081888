import { useLazyQuery } from "@apollo/client";
import { Delete as DeleteIcon, Person as PersonIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import _ from "lodash";
import moment, { duration } from "moment";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

// Routing
import history from "../history";

// GraphQL
import apollo from "../apollo";
import { DELETE_AUDIT } from "../queries/audits";

// Redux
import { Filter, FilterScope, setFilter } from "../actions/filters";

// Components
import TableView, {
  Column,
  RowAction,
} from "../components/TableView/TableView";
import CreationForm from "../components/CreationForms/Audit";

// Typings
import { Audit, AuditStatus } from "../types/audit";

const QUERY = gql`
  query(
    $accountId: ID
    $status: String
    $url: String
    $limit: Int
    $offset: Int
  ) {
    audits(
      accountId: $accountId
      status: $status
      url: $url
      offset: $offset
      limit: $limit
    ) {
      crawlId
      createdAt
      dateEnd
      dateStart
      depth
      id
      maxURL
      score
      speed
      status
      website {
        accountId
        id
        domain
        url
      }
    }
    auditsCount(accountId: $accountId, status: $status, url: $url)
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    audit(id: $id) {
      crawlId
      createdAt
      dateEnd
      dateStart
      depth
      id
      maxURL
      score
      speed
      status
      website {
        accountId
        id
        domain
        url
      }
    }
  }
`;

interface QueryData {
  audits: Audit[];
  auditsCount: number;
}

interface QuerySingleData {
  audit?: Audit;
}

interface QueryVariables {
  accountId?: number;
  status?: AuditStatus;
  url?: string;
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number; // TODO Remove
  offset: number; // TODO Remove
}

interface StateProps {
  filters: { [key in Filter]: string };
}

interface DispatchProps {
  rowActions: RowAction<Audit>[];
}

type Props = StateProps & DispatchProps;

const AuditsTableView: FunctionComponent<Props> = ({ filters, rowActions }) => {
  const columns: Column<Audit>[] = [
    {
      centered: true,
      computeLink: ({ id }: Audit) => `/audits/${id}`,
      computeValue: ({ id }: Audit) => id,
      exclusiveFilter: true,
      filter: Filter.AUDIT_ID,
      placeholder: "Id",
      width: 1,
    },
    {
      computeValue: ({ website }: Audit) => (website ? website.url : "-"),
      filter: Filter.AUDIT_URL,
      placeholder: "Url",
      width: 5,
    },
    {
      centered: true,
      disabledFilter: true,
      computeValue: ({ dateStart }: Audit) =>
        dateStart ? moment(dateStart).fromNow() : "-",
      filter: Filter.UNKNOWN,
      placeholder: "Start",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ dateStart, dateEnd }: Audit) =>
        dateStart
          ? duration(moment(dateStart).diff(moment(dateEnd))).humanize()
          : "-",
      disabledFilter: true,
      filter: Filter.UNKNOWN,
      placeholder: "Duration",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ status }: Audit) => _.capitalize(status) || "-",
      filter: Filter.AUDIT_STATUS,
      options: [
        { name: "Any", value: AuditStatus.Any },
        { name: "Analysing", value: AuditStatus.Analysing },
        { name: "Crawling", value: AuditStatus.Crawling },
        { name: "Finished", value: AuditStatus.Finished },
        { name: "Stopped", value: AuditStatus.Stopped },
        { name: "Failed", value: AuditStatus.Failed },
        { name: "Unauthorized", value: AuditStatus.Unauthorized },
      ],
      placeholder: "Status",
      width: 2,
    },
  ];

  const {
    [Filter.AUDIT_ACCOUNT_ID]: accountId,
    [Filter.AUDIT_ID]: id,
    [Filter.AUDIT_STATUS]: status,
    [Filter.AUDIT_URL]: url,
  } = filters;

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: {
      accountId: +accountId,
      status: status as AuditStatus,
      url,
      limit: 40,
      offset: 0,
    },
  });

  if (id) {
    return (
      <TableView<Audit, QuerySingleData, QuerySingleVariables>
        columns={columns}
        CreationForm={CreationForm}
        data={singleData}
        filtersScope={FilterScope.AUDIT}
        loading={singleLoading}
        normalizeQueryData={({ audit }) =>
          audit ? { dataSet: [audit], total: 1 } : { dataSet: [], total: 0 }
        }
        onFetch={fetchSingle}
        onRefetch={async (variables) => {
          if (refetchSingle) await refetchSingle(variables);
        }}
        rowActions={rowActions}
      />
    );
  }

  return (
    <TableView<Audit, QueryData, QueryVariables>
      columns={columns}
      CreationForm={CreationForm}
      data={data}
      filtersScope={FilterScope.AUDIT}
      loading={loading}
      normalizeQueryData={({ audits, auditsCount }) => ({
        dataSet: audits || [],
        total: auditsCount || 0,
      })}
      onFetch={fetch}
      onRefetch={async (variables) => {
        if (refetch) await refetch(variables);
      }}
      rowActions={rowActions}
    />
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  rowActions: [
    {
      handler: async ({ website: { accountId } }: any) => {
        dispatch(setFilter(Filter.USER_ACCOUNT_ID, accountId || 0));
        history.push("/users");
      },
      Icon: PersonIcon,
    },
    // { type: ActionType.REDO },
    {
      confirmable: true,
      handler: async ({ id }: any) =>
        apollo.mutate({ mutation: DELETE_AUDIT, variables: { id } }),
      Icon: DeleteIcon,
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditsTableView);
