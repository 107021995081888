import { AnyAction } from "redux";

// Redux actions
import {
  CLR_FILTER,
  CLR_FILTERS,
  Filter,
  Filters,
  FilterScope,
  SET_FILTER,
} from "../actions/filters";

// const defaultState = Object.keys(Filter).reduce(
const defaultState = Object.keys(Filter).reduce<Filters>(
  (filters, key) =>
    typeof Filter[key as any] !== "number"
      ? { ...filters, [key]: "" }
      : filters,
  {}
);

function clearFilters(state: typeof defaultState, scope: FilterScope) {
  switch (scope) {
    case FilterScope.ACCOUNT:
      return {
        ...state,
        [Filter.ACCOUNT_ID]: "",
        [Filter.ACCOUNT_NAME]: "",
        [Filter.ACCOUNT_SUBSCRIPTION]: "",
      };
    case FilterScope.ADS_CAMPAIGN:
      return {
        ...state,
        [Filter.ADS_CAMPAIGN_ID]: "",
        [Filter.ADS_CAMPAIGN_DOMAIN]: "",
        [Filter.ADS_CAMPAIGN_STATUS]: "",
        [Filter.ADS_CAMPAIGN_GOOGLE_ADS_ID]: "",
        [Filter.ADS_CAMPAIGN_ACCOUNT_ID]: "",
      };
    case FilterScope.AUDIT:
      return {
        ...state,
        [Filter.AUDIT_ACCOUNT_ID]: "",
        [Filter.AUDIT_ID]: "",
        [Filter.AUDIT_STATUS]: "",
        [Filter.AUDIT_URL]: "",
      };
    case FilterScope.NETLINKING_CAMPAIGN:
      return {
        ...state,
        [Filter.NETLINKING_CAMPAIGN_ID]: "",
        [Filter.NETLINKING_CAMPAIGN_NAME]: "",
        [Filter.NETLINKING_CAMPAIGN_STATUS]: "",
      };
    case FilterScope.ORDER:
      return {
        ...state,
        [Filter.ORDER_ID]: "",
        [Filter.ORDER_CREATED_AT]: "",
        [Filter.ORDER_PRICE]: "",
        [Filter.ORDER_STATUS]: "",
        [Filter.ORDER_UPDATED_AT]: "",
        [Filter.ORDER_WEBSITE]: "",
      };
    case FilterScope.RANKING:
      return {
        ...state,
        [Filter.RANKING_ACCOUNT_ID]: "",
        [Filter.RANKING_CREATED_AT]: "",
        [Filter.RANKING_DOMAIN]: "",
        [Filter.RANKING_ID]: "",
      };
    case FilterScope.USER:
      return {
        ...state,
        [Filter.USER_ACCOUNT_ID]: "",
        [Filter.USER_EMAIL]: "",
        [Filter.USER_LAST_ACTIVITY]: "",
        [Filter.USER_IS_BLOCKED]: "",
        [Filter.USER_IS_VERIFIED]: "",
        [Filter.USER_NAME]: "",
      };
    case FilterScope.WEBSITE:
      return {
        ...state,
        [Filter.WEBSITE_DOMAIN]: "",
        [Filter.WEBSITE_ID]: "",
        [Filter.WEBSITE_URL]: "",
        [Filter.WEBSITE_ACCOUNT_ID]: "",
      };
    default:
      return state;
  }
}

function filters(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        [action.name]: action.value,
      };
    case CLR_FILTER:
      return {
        ...state,
        [action.name]: "",
      };
    case CLR_FILTERS:
      return clearFilters(state, action.scope);
    default:
      return state;
  }
}

export default filters;
