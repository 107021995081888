import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Label from "./Label";

import styles from "./Row.module.css";

class Row extends Component {
  static renderMultipleValues(data) {
    const values = [];

    data.forEach((element, index) => {
      // eslint-disable-next-line react/no-array-index-key
      values.push(<div key={index}>{element}</div>);
    });

    return values;
  }

  render() {
    // const { data, fetchUrlData, link, status, title } = this.props;
    const { data, link, status, title } = this.props;

    return (
      <div className={styles.row}>
        <div className={styles.titleColumn}>
          <div>
            <b>
              <FormattedMessage id={title} />
            </b>
            {status && <Label key="difficulty" difficulty={status} />}
          </div>
        </div>
        {link ? (
          <div className={styles.valueColumn}>
            {data instanceof Array ? (
              Row.renderMultipleValues(data, true)
            ) : (
              <span>
                <span
                  // onClick={() => fetchUrlData(data)}
                  className={styles.input}
                >
                  {data}
                </span>
                <a
                  href={data}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "5px" }}
                >
                  <i className="fas fa-external-link-alt" aria-hidden="true" />
                </a>
              </span>
            )}
          </div>
        ) : (
          <div className={styles.valueColumn}>
            {data instanceof Array
              ? Row.renderMultipleValues(data, true)
              : data}
          </div>
        )}
      </div>
    );
  }
}

Row.propTypes = {
  // TODO Fix
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  // fetchUrlData: PropTypes.func.isRequired,
  link: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Row.defaultProps = {
  data: "",
  link: "",
  status: "",
};

export default Row;
