export enum NetlinkingCampaignStatus {
  Any = "ANY",
  Pending = "PENDING",
  Running = "RUNNING",
  Finished = "FINISHED",
}

export type NetlinkingCampaign = {
  createdAt: string;
  duration: number;
  id: string;
  monthlyArticlesNumber: number;
  name: string;
  status: NetlinkingCampaignStatus;
};
