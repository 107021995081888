import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";

// Typings
import { AdsCampaign, AdsCampaignStatus } from "../../types/adsCampaigns";

type Props = {
  adsCampaign: AdsCampaign;
};

const EditionForm: FunctionComponent<Props> = ({
  adsCampaign: {
    id,
    googleAdsCustomerAccountId,
    budget,
    createdAt,
    datastudioId,
    website,
    status,
  },
}) => {
  const { errors, register } = useFormContext<AdsCampaign>();

  return (
    <Fragment>
      <TextField
        defaultValue={googleAdsCustomerAccountId}
        error={!!errors.googleAdsCustomerAccountId}
        fullWidth
        inputRef={register({ required: false })}
        label="Google Ads Customer Account ID"
        margin="dense"
        name="googleAdsCustomerAccountId"
      />

      <TextField
        defaultValue={datastudioId}
        error={!!errors.datastudioId}
        fullWidth
        inputRef={register({ required: true })}
        label="Data Studio ID"
        margin="dense"
        name="datastudioId"
        required
        type="string"
      />

      <TextField
        defaultValue={budget}
        error={!!errors.budget}
        fullWidth
        inputProps={{ min: 1 }}
        inputRef={register({ min: 1, required: true })}
        label="Budget mensuel"
        margin="dense"
        name="budget"
        required
        type="number"
      />

      <FormControl error={!!errors.status} fullWidth margin="dense" required>
        <InputLabel id="status-label">Status</InputLabel>
        <Controller
          as={
            <Select labelId="status-label">
              <MenuItem value={AdsCampaignStatus.Running}>Running</MenuItem>
              <MenuItem value={AdsCampaignStatus.Stopped}>Stopped</MenuItem>
            </Select>
          }
          // control={control}
          defaultValue={status}
          name="status"
          rules={{ required: true }}
        />
      </FormControl>

      <TextField
        error={!!errors.website}
        defaultValue={website.id}
        fullWidth
        inputProps={{ min: 1 }}
        inputRef={register({ min: 1, required: true })}
        label="Website ID"
        margin="dense"
        name="websiteId"
        required
        type="number"
      />
    </Fragment>
  );
};

export default EditionForm;
