import React, { Component, FormEvent } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import FlagIconFactory from "react-flag-icon-css";
import { injectIntl, WrappedComponentProps } from "react-intl";

// Components
import Metric from "./Header/Metric";

// Redux actions
import * as actions from "../../../actions/rankings";

// Assets
import "react-dates/lib/css/_datepicker.css";
import styles from "./Header.module.css";

const countries = {
  fr: "fr",
  com: "us",
  "co.uk": "gb",
};

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface OwnProps {
  errors?: any;
  id: any;
  loading?: any;
  nometrics?: any;
  rankingData: any;
  type?: any;
}

interface DispatchProps {
  changeDate: any;
  changeDevice: any;
  cleanStore: any;
  getCompetitors: any;
  getCompetitorsByArray: any;
  getKeywords: any;
  getKeywordsByArray: any;
  getProject: any;
  getProjectInfos: any;
  getTotalNumKeywords: any;
}

type Props = DispatchProps & OwnProps & WrappedComponentProps;

interface State {
  focusedInput: any;
}

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focusedInput: null,
    };
  }

  componentDidMount() {
    const { getProject, getTotalNumKeywords, id, rankingData } = this.props;

    if (!rankingData.project) {
      getTotalNumKeywords(id);

      getProject(id).then(() => {
        this.fetchData();
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { cleanStore, getProject, getTotalNumKeywords, id } = this.props;

    if (prevProps.id !== id) {
      cleanStore();

      getTotalNumKeywords(id);

      getProject(id).then(() => {
        this.fetchData();
      });
    }
  }

  fetchData = () => {
    const { getCompetitors, getKeywords, getProjectInfos, id } = this.props;

    getProjectInfos(id).then(() => {
      // @ts-ignore
      getKeywords(id, 0, 10).then(() => {
        this.updateSelectedKeywords();
      });

      // @ts-ignore
      getCompetitors(id, 0, 10).then(() => {
        this.updateSelectedCompetitors();
      });
    });
  };

  updateSelectedKeywords = () => {
    const { getKeywordsByArray, id, rankingData } = this.props;

    if (rankingData.selectedKeywords.length > 0) {
      const arr = rankingData.selectedKeywords.map(
        (element: any) => element.keyword
      );

      getKeywordsByArray(id, arr);
    }
  };

  updateSelectedCompetitors = () => {
    const { getCompetitorsByArray, id, rankingData } = { ...this.props };

    if (rankingData.selectedCompetitors.length > 0) {
      const arr = rankingData.selectedCompetitors.map(
        (element: any) => element.domain
      );

      getCompetitorsByArray(id, arr);
    }
  };

  onDatesChange = (startDate: any, endDate: any) => {
    const { changeDate } = this.props;

    changeDate(startDate, endDate);

    if (startDate && endDate) {
      this.fetchData();
    }
  };

  onSelectChange = (e: FormEvent<HTMLSelectElement>) => {
    const { changeDevice } = this.props;

    changeDevice(e.currentTarget.value);

    this.fetchData();
  };

  render() {
    const { intl, nometrics, rankingData } = this.props;
    const { focusedInput } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.topContainer}>
          <div>
            {rankingData.project && rankingData.project.Website.domain && (
              <h1>
                {rankingData.project.Website.domain}
                {/*
                // @ts-ignore */}
                {rankingData.project && countries[rankingData.project.country] && (
                  <span style={{ marginLeft: "12px" }}>
                    {/*
                    // @ts-ignore */}
                    <FlagIcon code={countries[rankingData.project.country]} />
                  </span>
                )}
              </h1>
            )}
          </div>
          <div className={styles.rightContainer}>
            <div>
              <select
                value={rankingData.device}
                onChange={(e) => this.onSelectChange(e)}
                style={{ width: "150px" }}
              >
                <option value={0}>
                  {intl.formatMessage({ id: "ranking.device.desktop" })}
                </option>
                <option value={1}>
                  {intl.formatMessage({ id: "ranking.device.mobile" })}
                </option>
              </select>
            </div>
            <div>
              <DateRangePicker
                startDate={rankingData.startDate}
                startDateId="startDateId"
                endDate={rankingData.endDate}
                endDateId="endDateId"
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                }
                focusedInput={focusedInput}
                onFocusChange={(focusedInput1) =>
                  this.setState({ focusedInput: focusedInput1 })
                }
                isOutsideRange={() => false}
                // isOutsideRange={day => day.isBefore(moment(rankingData.project.min_date)) || day.isAfter(moment(rankingData.project.max_date))}
                showDefaultInputIcon
              />
            </div>
          </div>
        </div>

        {!nometrics && rankingData.infos && (
          <div className={styles.metricsContainer}>
            <Metric
              position
              data={rankingData.infos ? rankingData.infos.avg : null}
              title="ranking.averageRank"
            />
            <Metric
              data={rankingData.infos ? rankingData.infos.top3 : null}
              title="ranking.top3"
            />
            <Metric
              data={rankingData.infos ? rankingData.infos.top10 : null}
              title="ranking.top10"
            />
            <Metric
              data={
                rankingData.totalNumKeywords
                  ? rankingData.totalNumKeywords
                  : null
              }
              title="ranking.keywordsNumber"
              totalNumKeywords
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  changeDate: (startDate: any, endDate: any) =>
    dispatch(actions.changeDate(startDate, endDate)),
  changeDevice: (device: any) => dispatch(actions.changeDevice(device)),
  cleanStore: () => dispatch(actions.cleanStore()),
  getCompetitors: (id: any, from: any, size: any, sort: any, search: any) =>
    dispatch(actions.getCompetitors(id, from, size, sort, search)),
  getCompetitorsByArray: (id: any, array: any) =>
    dispatch(actions.getCompetitorsByArray(id, array)),
  getKeywords: (id: any, from: any, size: any, sort: any, search: any) =>
    dispatch(actions.getKeywords(id, from, size, sort, search)),
  getKeywordsByArray: (id: any, array: any) =>
    dispatch(actions.getKeywordsByArray(id, array)),
  getProject: (id: any) => dispatch(actions.fetchRanking(id)),
  getProjectInfos: (id: any) => dispatch(actions.getProjectInfos(id)),
  getTotalNumKeywords: (id: any) => dispatch(actions.getTotalNumKeywords(id)),
});

export default connect(null, mapDispatchToProps)(injectIntl(Header));
