import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Label from "./Label";
import Tile from "../../UI/Tile/Tile";

import styles from "./UrlFinderView.module.css";

const renderTile = (children, index, title, type) => {
  if (type === "array" || type === "dynamic_array") {
    return (
      <div key={index}>
        <h3>
          <FormattedMessage id={title} />
        </h3>
        {children}
      </div>
    );
  }

  return (
    <Tile key={index} name={title}>
      {children}
    </Tile>
  );
};

const UrlFinderView = ({
  audit,
  pageStructure,
  renderContainersRows,
  titleStatus,
}) => (
  <div className={styles.wrapper}>
    <h2>{audit.current.url.url.raw}</h2>
    {pageStructure.map((element, index) => {
      let status;

      if (element.status) {
        status = titleStatus(element.status);
      }

      const rows = renderContainersRows(element);

      if (rows.length > 0) {
        return renderTile(
          <div className={styles.tileContent}>
            {status ? (
              <div className={styles.row}>
                <div className={styles.titleColumn}>
                  <b>Overall</b>
                </div>
                <div className={styles.valueColumn}>
                  <Label key="difficulty" difficulty={status} text={status} />
                </div>
              </div>
            ) : null}
            <div className={styles.dataContainer}>{rows}</div>
          </div>,
          index,
          element.title,
          element.data[0].type
        );
        // eslint-disable-next-line react/no-array-index-key
        /* <Tile key={index} name={element.title}>
            <div className={styles.tileContent}>
              {status ? (
                <div className={styles.row}>
                  <div className={styles.titleColumn}>
                    <b>Overall</b>
                  </div>
                  <div className={styles.valueColumn}>
                    <Label key="difficulty" difficulty={status} text={status} />
                  </div>
                </div>
              ) : null}
              <div className={styles.dataContainer}>{rows}</div>
            </div>
          </Tile> */
      }

      return null;
    })}
  </div>
);

UrlFinderView.propTypes = {
  audit: PropTypes.shape().isRequired,
  pageStructure: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  renderContainersRows: PropTypes.func.isRequired,
  titleStatus: PropTypes.func.isRequired,
};

export default UrlFinderView;
