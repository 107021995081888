import { Dispatch } from "redux";
import moment from "moment";

import api from "../api";
import apollo from "../apollo";
import { DELETE_RANKING, GET_RANKING } from "../queries/rankings";

export const CHANGE_DEVICE = "CHANGE_DEVICE";
export const CHANGE_DATE = "CHANGE_DATE";
export const CLEAN_STORE = "CLEAN_STORE";

export const ADD_ITEM_TO_FILTERS = "ADD_ITEM_TO_FILTERS";
export const REMOVE_ITEM_TO_FILTERS = "REMOVE_ITEM_TO_FILTERS";
export const REMOVE_ALL_ITEMS_TO_FILTERS = "REMOVE_ALL_ITEMS_TO_FILTERS";
export const UPDATE_FILTERS_ITEMS = "UPDATE_FILTERS_ITEMS";

export function changeDevice(device: any) {
  return {
    type: CHANGE_DEVICE,
    device,
  };
}

export function addItemToFilters(path: any, item: any) {
  return {
    type: ADD_ITEM_TO_FILTERS,
    path,
    item,
  };
}

export function removeItemToFilters(path: any, index: any) {
  return {
    type: REMOVE_ITEM_TO_FILTERS,
    path,
    index,
  };
}

export function removeAllItemsToFilters(path: any) {
  return {
    type: REMOVE_ALL_ITEMS_TO_FILTERS,
    path,
  };
}

export function updateFiltersItems(path: any, newData: any) {
  return {
    type: UPDATE_FILTERS_ITEMS,
    path,
    newData,
  };
}

export function changeDate(startDate: any, endDate: any) {
  return {
    type: CHANGE_DATE,
    startDate,
    endDate,
  };
}

export function cleanStore() {
  return {
    type: CLEAN_STORE,
  };
}

// -----------------ASYNC ACTION CREATORS-----------------

export function getProjectInfos(id: any) {
  return (dispatch: Dispatch, getState: any) => {
    if (getState().ranking.startDate && getState().ranking.endDate) {
      // make sure both dates are not null
      dispatch({ type: "GET_RANKING_PROJECT_INFOS_REQUEST" });

      return api
        .get(`/rankings/${id}/stats`, {
          params: {
            date_from: getState().ranking.startDate.toISOString(),
            date_to: getState().ranking.endDate.toISOString(),
            platform: getState().ranking.device,
          },
        })
        .then((res) => {
          if (res.status === 204)
            dispatch({
              type: "GET_RANKING_PROJECT_INFOS_FAILURE",
              message: "No data",
            });
          else
            dispatch({
              type: "GET_RANKING_PROJECT_INFOS_SUCCESS",
              path: "infos",
              data: res.data,
            });
        })
        .catch(() => {});
    }

    return new Promise(() => Promise.resolve());
  };
}

//
// export const F_RANKING = "F_RANKING";
// export const F_RANKING_OK = "F_RANKING_OK";
export const F_RANKING_ERR = "F_RANKING_ERR";

//
export function fetchRanking(id: number | string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: "GET_RANKING_PROJECT_REQUEST" });

      const {
        data: { ranking },
      } = await apollo.query<any>({
        query: GET_RANKING,
        variables: { id },
      });

      dispatch(
        changeDate(
          moment(ranking.max_date).subtract(7, "days"),
          moment(ranking.max_date)
        )
      );

      dispatch({
        type: "GET_RANKING_PROJECT_SUCCESS",
        path: "project",
        ranking,
      });
    } catch (e) {
      dispatch({ type: F_RANKING_ERR });
    }
  };
}

export function getKeywordsByArray(id: any, array: any) {
  return (dispatch: Dispatch, getState: any) => {
    if (getState().ranking.startDate && getState().ranking.endDate) {
      // make sure both dates are not null
      dispatch({ type: "GET_KEYWORDS_BY_ARRAY_REQUEST" });
      return api
        .get(`/rankings/${id}/keywords/ranks`, {
          params: {
            date_from: getState().ranking.startDate.toISOString(),
            date_to: getState().ranking.endDate.toISOString(),
            platform: getState().ranking.device,
            keywords: array,
          },
        })
        .then((res) => {
          if (res.data.total !== 0) {
            dispatch(
              updateFiltersItems("selectedKeywords", {
                ...res.data,
                data: res.data.data.map((item: any) => ({
                  ...item,
                  keyword: item.keyword.keyword,
                })),
              })
            );
          }
        })
        .catch(() => {});
    }

    return new Promise(() => Promise.resolve());
  };
}

export function getKeywords(
  id: any,
  from: any,
  size: any,
  sort: any,
  search = null
) {
  return (dispatch: Dispatch, getState: any) => {
    if (getState().ranking.startDate && getState().ranking.endDate) {
      // make sure both dates are not null
      dispatch({ type: "GET_KEYWORDS_REQUEST" });

      if (!sort) {
        // eslint-disable-next-line no-param-reassign
        sort = [{ id: "rank", desc: false }];
      } // default sort for the first call

      return api
        .get(`/rankings/${id}/keywords/ranks`, {
          params: {
            date_from: getState().ranking.startDate.toISOString(),
            date_to: getState().ranking.endDate.toISOString(),
            platform: getState().ranking.device,
            from,
            size,
            sort,
            // @ts-ignore
            ...(search && { search }),
          },
        })
        .then((res) => {
          if (res.data.total === 0) {
            // dispatch({ type: "GET_KEYWORDS_FAILURE", message: "No data" });
            dispatch({
              type: "GET_KEYWORDS_SUCCESS",
              path: "keywords",
              data: [],
            }); // clean the array when no data
          } else
            dispatch({
              type: "GET_KEYWORDS_SUCCESS",
              path: "keywords",
              data: {
                ...res.data,
                data: res.data.data.map((item: any) => ({
                  ...item,
                  keyword: item.keyword.keyword,
                })),
              },
            });
        })
        .catch(() => {});
    }

    return new Promise(() => Promise.resolve());
  };
}

export function getCompetitorsByArray(id: any, array: any) {
  return (dispatch: Dispatch, getState: any) => {
    if (getState().ranking.startDate && getState().ranking.endDate) {
      // make sure both dates are not null
      dispatch({ type: "GET_COMPETITORS_BY_ARRAY_REQUEST" });
      return api
        .get(`/rankings/${id}/competitors`, {
          params: {
            date_from: getState().ranking.startDate.toISOString(),
            date_to: getState().ranking.endDate.toISOString(),
            platform: getState().ranking.device,
            competitors: array,
          },
        })
        .then((res) => {
          if (res.data.total === 0) {
            dispatch({
              type: "GET_COMPETITORS_BY_ARRAY_FAILURE",
              message: "No data",
            });
          } else {
            dispatch({ type: "GET_COMPETITORS_BY_ARRAY_SUCCESS" });
            dispatch(updateFiltersItems("selectedCompetitors", res.data));
          }
        })
        .catch(() => {});
    }

    return new Promise(() => Promise.resolve());
  };
}

export function getCompetitors(
  id: any,
  from: any,
  size: any,
  sort: any,
  search = null
) {
  return (dispatch: Dispatch, getState: any) => {
    if (getState().ranking.startDate && getState().ranking.endDate) {
      // make sure both dates are not null
      dispatch({ type: "GET_COMPETITORS_REQUEST" });

      if (!sort) {
        // eslint-disable-next-line no-param-reassign
        sort = [{ id: "total_matching_keywords", desc: true }];
      } // default sort for the first call

      return api
        .get(`/rankings/${id}/competitors`, {
          params: {
            date_from: getState().ranking.startDate.toISOString(),
            date_to: getState().ranking.endDate.toISOString(),
            platform: getState().ranking.device,
            from,
            size,
            sort,
            // @ts-ignore
            ...(search && { search }),
          },
        })
        .then((res) => {
          if (res.data.total === 0) {
            // dispatch({ type: "GET_COMPETITORS_FAILURE", message: "No data" });
            dispatch({
              type: "GET_COMPETITORS_SUCCESS",
              path: "competitors",
              data: [],
            }); // clean the array when no data
          } else
            dispatch({
              type: "GET_COMPETITORS_SUCCESS",
              path: "competitors",
              data: res.data,
            });
        })
        .catch(() => {});
    }

    return new Promise(() => Promise.resolve());
  };
}

export function getTotalNumKeywords(id: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: "GET_TOTAL_NUM_KEYWORDS_REQUEST" });

    return api
      .get(`/rankings/${id}/keywords/count`)
      .then((res) => {
        dispatch({
          type: "GET_TOTAL_NUM_KEYWORDS_SUCCESS",
          path: "totalNumKeywords",
          data: res.data.count,
        });
      })
      .catch(() => {});
  };
}

// Action dispatched to delete a ranking project from the list stored in redux
// export const D_RANKING = "D_RANKING";
export const D_RANKING_OK = "D_RANKING_OK";
export const D_RANKING_ERR = "D_RANKING_ERR";

// Action dispatched to request a ranking project deletion from the API
export function deleteRanking(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      await apollo.mutate({
        mutation: DELETE_RANKING,
        variables: { id },
      });

      dispatch({
        type: D_RANKING_OK,
        id,
      });
    } catch (e) {
      dispatch({ type: D_RANKING_ERR });
    }
  };
}
