import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExitToApp as ExitToAppIcon,
  List as ListIcon,
  LocalOffer as LocalOfferIcon,
  MonetizationOn,
  People as PeopleIcon,
  Person as PersonIcon,
  ScatterPlot as ScatterPlotIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  ShowChart as ShowChartIcon,
  Translate as TranslateIcon,
  Visibility as VisibilityIcon,
  Web as WebIcon,
} from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FunctionComponent, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

// Components
import NavItem from "./NavItem";

// Utils
import { logout } from "../../utils/auth";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      width: drawerWidth,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
  })
);

const NavBar: FunctionComponent = () => {
  const location = useLocation();
  const classes = useStyles();
  const [minimized, minimize] = useState(false);

  const minimizable =
    location.pathname.startsWith("/audits") ||
    location.pathname.startsWith("/rankings")
      ? location.pathname.split("/").length < 3
      : true;

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !minimized,
        [classes.drawerClose]: minimized,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !minimized,
          [classes.drawerClose]: minimized,
        }),
      }}
      variant="permanent"
    >
      <List>
        <ListItem
          component={Link}
          to="/"
          style={{ marginBottom: 12, marginTop: 4 }}
        >
          <ListItemAvatar>
            <Avatar
              src="/octopulse_logo_noname.png"
              style={{ maxHeight: 24, maxWidth: 24 }}
            />
          </ListItemAvatar>
          <ListItemText primary="Office" />
        </ListItem>

        <Divider />

        <NavItem
          icon={<PeopleIcon />}
          link="/accounts"
          message="menu.accounts"
        />
        <NavItem icon={<PersonIcon />} link="/users" message="menu.users" />
        <NavItem icon={<WebIcon />} link="/websites" message="menu.websites" />
        <NavItem
          icon={<VisibilityIcon />}
          link="/audits"
          message="menu.audits"
        />

        {/* Single audit */}
        <Route
          path="/audits/:id"
          render={({ match }) => {
            if (minimized) minimize(false);

            return (
              <List dense>
                {/* Overview */}
                <NavItem
                  link={`/audits/${match.params.id}/overview`}
                  message="menu.overview"
                />

                {/* HTML tags */}
                <NavItem
                  link={`/audits/${match.params.id}/optimizations`}
                  message="menu.optimizations"
                />
                <Route
                  path="/audits/:id/optimizations"
                  render={() => (
                    <List dense style={{ background: "whitesmoke" }}>
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/h1`}
                        message="menu.h1"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/hn`}
                        message="menu.hn"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/title`}
                        message="menu.title"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/meta`}
                        message="menu.meta"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/alt`}
                        message="menu.alt"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/duplicates`}
                        message="menu.duplicate"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/words-count`}
                        message="menu.wordsCount"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/canonicals`}
                        message="menu.canonicals"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/open-graph`}
                        message="menu.openGraph"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/twitter-card`}
                        message="menu.twitterCards"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/http-codes`}
                        message="menu.httpCodes"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/load-time`}
                        message="menu.loadTime"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/weight`}
                        message="menu.weight"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/tls`}
                        message="menu.tls"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/url`}
                        message="menu.url"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/depth`}
                        message="menu.depth"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/robots-txt`}
                        message="menu.robotsTxt"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/optimizations/internationalization`}
                        message="menu.internationalization"
                      />
                    </List>
                  )}
                />

                {/* URL finder */}
                <NavItem
                  link={`/audits/${match.params.id}/url-finder`}
                  message="menu.urlFinder"
                />
                <Route
                  path="/audits/:id/url-finder"
                  render={() => (
                    <List dense style={{ background: "whitesmoke" }}>
                      <NavItem
                        link={`/audits/${match.params.id}/url-finder/html-tags`}
                        message="menu.htmlTags"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/url-finder/performance`}
                        message="menu.performance"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/url-finder/content`}
                        message="menu.content"
                      />
                      <NavItem
                        link={`/audits/${match.params.id}/url-finder/architecture`}
                        message="menu.architecture"
                      />
                    </List>
                  )}
                />
              </List>
            );
          }}
        />

        {/* Rankings */}
        <NavItem
          icon={<ShowChartIcon />}
          link="/rankings"
          message="menu.rankings"
        />

        {/* Single rankings */}
        <Route
          path="/rankings/:id"
          render={({ match }) => {
            if (minimized) minimize(false);
            return (
              <List dense>
                {/* Overview */}
                <NavItem
                  link={`/rankings/${match.params.id}/overview`}
                  message="menu.overview"
                />

                {/* Competitors */}
                <NavItem
                  link={`/rankings/${match.params.id}/competitors`}
                  message="menu.competitors"
                />

                {/* Add keywords */}
                <NavItem
                  link={`/rankings/${match.params.id}/add-keywords`}
                  message="menu.addKeywords"
                />

                {/* Keywords */}
                <NavItem
                  link={`/rankings/${match.params.id}/keywords`}
                  message="menu.keywords"
                />
              </List>
            );
          }}
        />

        {/* Netlinking Campaigns */}
        <NavItem
          icon={<ScatterPlotIcon />}
          link="/netlinking-campaigns"
          message="menu.netlinkingCampaigns"
        />

        {/* Ads Campaigns */}
        <NavItem
          icon={<MonetizationOn />}
          link="/ads-campaigns"
          message="menu.adsCampaigns"
        />

        {/* Orders */}
        <NavItem
          icon={<ShoppingCartIcon />}
          link="/orders"
          message="menu.orders"
        />

        {/* Todos */}
        <NavItem icon={<ListIcon />} link="/todos" message="menu.todos" />

        {/* Wording */}
        <NavItem
          icon={<TranslateIcon />}
          link="/wording"
          message="menu.wording"
        />

        {/* Sponsorships */}
        <NavItem
          icon={<LocalOfferIcon />}
          link="/sponsorships"
          message="menu.sponsorships"
        />

        {/* Settings */}
        <NavItem
          icon={<SettingsIcon />}
          link="/settings"
          message="menu.settings"
        />
      </List>

      <Divider />

      <List>
        {minimizable && (
          <ListItem button onClick={() => minimize(!minimized)}>
            <ListItemIcon>
              {minimized ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </ListItemIcon>
            <ListItemText primary="Minimize" />
          </ListItem>
        )}

        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default NavBar;
