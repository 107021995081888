import { AnyAction } from "redux";

import { FETCH_ORDER, RECEIVE_ORDER, UPDATE_ORDER } from "../actions/orders";
import { orderIn } from "../utils/format";

function order(state = null, action: AnyAction) {
  switch (action.type) {
    case FETCH_ORDER:
      return null;
    case RECEIVE_ORDER:
      return orderIn(action.order);
    case UPDATE_ORDER:
      return Object.assign({}, state, action.fields);
    default:
      return state;
  }
}

export default order;
