import { useMutation } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";

// Components
import ConfirmationDialog from "../UI/Dialog/ConfirmationDialog";
import EditionDialog from "../EditionDialogs/NetlinkingConfiguration";

// GraphQL
import {
  DELETE_NETLINKING_CONFIGURATION,
  DeleteNetlinkingConfigurationData,
  DeleteNetlinkingConfigurationVariables,
  NetlinkingConfiguration,
} from "../../queries/netlinking";

type Props = {
  configurations: NetlinkingConfiguration[];
  refetch: () => Promise<any>;
};

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ConfigurationsTable: FunctionComponent<Props> = ({
  configurations,
  refetch,
}) => {
  const [openedConfirmation, displayConfirmation] = useState(false);
  const [deferredAction, setDeferredAction] = useState<() => Promise<any>>();

  const [openedEditionDialog, displayEditionDialog] = useState(false);
  const [succeeded, showSuccess] = useState(false);
  const [
    editableConfiguration,
    setEditableConfiguration,
  ] = useState<NetlinkingConfiguration | null>(null);

  const [deleteConfiguration] = useMutation<
    DeleteNetlinkingConfigurationData,
    DeleteNetlinkingConfigurationVariables
  >(DELETE_NETLINKING_CONFIGURATION);

  // Pop up confirmation dialog if a new action has been deferred
  useEffect(() => {
    if (deferredAction) displayConfirmation(true);
  }, [deferredAction]);

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Target URL</TableCell>
              <TableCell>Ratio</TableCell>
              <TableCell>Keywords</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configurations.map((configuration) => (
              <TableRow key={configuration.id}>
                <TableCell>{configuration.targetUrl}</TableCell>
                <TableCell>{configuration.ratio}</TableCell>
                <TableCell>{configuration.targetKeywords}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setEditableConfiguration(configuration);
                      displayEditionDialog(true);
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      setDeferredAction(() => () =>
                        deleteConfiguration({
                          variables: { id: configuration.id },
                        }).then(() => refetch())
                      )
                    }
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editableConfiguration && (
        <EditionDialog
          configuration={editableConfiguration}
          onCancel={() => {
            displayEditionDialog(false);
          }}
          onSuccess={async () => {
            displayEditionDialog(false);
            showSuccess(true);

            await refetch();
          }}
          open={openedEditionDialog}
        />
      )}

      <ConfirmationDialog
        action={() => {
          if (deferredAction)
            deferredAction().then(() => displayConfirmation(false));
        }}
        cancel={() => displayConfirmation(false)}
        open={openedConfirmation}
      />

      <Snackbar
        autoHideDuration={6000}
        onClose={() => showSuccess(false)}
        open={succeeded}
      >
        <Alert>
          Updated configuration
          {editableConfiguration && ` n°${editableConfiguration.id}`}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default ConfigurationsTable;
