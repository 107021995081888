import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Header from "./component/Header";
import Filter from "./component/Filter";
import Table from "./component/Table";
import Chart from "./component/Chart";

import styles from "./Competitors.module.css";

interface Props {
  ranking: any;
  actions: any;
}

function Competitors({
  match,
  ranking,
}: Props & RouteComponentProps<{ id: string }>) {
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Header
            id={match.params.id}
            rankingData={ranking}
            type="competitors"
          />
          <div className={styles.contentContainer}>
            <div className={styles.chartContainer}>
              <Chart rankingData={ranking} type="competitors" />
            </div>
            <Filter
              rankingData={ranking}
              filters={ranking.selectedCompetitors}
              type="competitors"
            />
          </div>
          <div className={styles.tableContainer}>
            <Table
              id={match.params.id}
              rankingData={ranking}
              type="competitors"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ ranking }: { ranking: any }) => ({ ranking });

export default connect(mapStateToProps)(Competitors);
