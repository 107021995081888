import React from "react";
import moment from "moment";

import Spinner from "../components/Spinner";
import styles from "../components/UsersList/UsersList.module.css";

import { F_ORDER_ERR } from "../actions/orders";
import { F_TODOS_ERR } from "../actions/todos";

/**
 * Return error string corresponding to the error type
 * @param error
 * @returns {string}
 */
export const displayError = error => {
  switch (error) {
    case F_ORDER_ERR:
      return "Oops, something went wrong when fetching order, please try again later";
    case F_TODOS_ERR:
      return "Oops, something went wrong when fetching todos, please try again later";
    default:
      break;
  }

  return "Oops, something went wrong, please try again later";
};

/**
 * Return formatted full name
 * @param firstName
 * @param lastName
 * @returns {string}
 */
export const displayName = (firstName, lastName) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;

  return "-";
};

/**
 * Display a user's last recorded activity's datetime
 * @param date
 * @return {*}
 */
export const displayLastActivity = date => {
  switch (date) {
    case undefined:
      return (
        <div className={styles.spinnerContainer}>
          <Spinner size={18} />
        </div>
      );
    case null:
      return <i className="material-icons">error_outline</i>;
    default:
      return <span>{moment(date).fromNow()}</span>;
  }
};
