import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import api from "../../../api";
import Row from "./Row";
import UrlFinderView from "./UrlFinderView";

import styles from "./Architecture.module.css";

// function retPageStructure(crawlId, fetchUrlData) {
function retPageStructure() {
  return [
    {
      title: "urlFinder.links",
      data: [
        {
          type: "array",
          columns: [
            {
              Header: "URL",
              accessor: "href.raw",
              Cell: sent => (
                <span>
                  <span
                    // onClick={() => fetchUrlData(sent.value)}
                    className={styles.input}
                  >
                    {sent.value}
                  </span>
                  <a
                    href={sent.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "5px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              ),
            },
            {
              Header: (
                <div>
                  <FormattedMessage id="urlFinder.anchorText" />
                </div>
              ),
              accessor: "anchor",
              Cell: sent => <span>{sent.value}</span>,
            },
          ],
          value: "links",
        },
      ],
    },
    {
      title: "urlFinder.internalLinks",
      data: [
        {
          type: "dynamic_array",
          columns: [
            {
              Header: "URL",
              accessor: "url",
              Cell: sent => (
                <span>
                  <span
                    // onClick={() => fetchUrlData(sent.value)}
                    className={styles.input}
                  >
                    {sent.value}
                  </span>
                  <a
                    href={sent.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "5px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              ),
            },
          ],
          value: "pointingPages",
        },
      ],
    },
    {
      title: "urlFinder.images",
      data: [
        {
          type: "array",
          columns: [
            {
              Header: "URL",
              accessor: "src.raw",
              Cell: sent => (
                <span>
                  <span>{sent.value}</span>
                  <a
                    href={sent.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "5px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      aria-hidden="true"
                    />
                  </a>
                </span>
              ),
            },
            {
              Header: (
                <div>
                  <FormattedMessage id="urlFinder.imageAlt" />
                </div>
              ),
              accessor: "alt",
              Cell: sent => <span>{sent.value}</span>,
            },
            {
              Header: (
                <div>
                  <FormattedMessage id="urlFinder.imageTitle" />
                </div>
              ),
              accessor: "title",
              Cell: sent => <span>{sent.value}</span>,
            },
          ],
          value: "imgs",
        },
      ],
    },
  ];
}

Object.resolve = (path, obj) =>
  path
    .split(".")
    .reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || this);

class Architecture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointingPages: [],
      pointingPagesPage: 0,
      pointingPagesSize: 10,
    };
  }

  componentWillMount() {
    const { pointingPagesPage, pointingPagesSize } = this.state;

    this.fetchPoitingUrls(pointingPagesPage, pointingPagesSize);
  }

  fetchPoitingUrls(page, pageSize) {
    const { audit } = this.props;

    if (audit.crawlId) {
      // legacy route /audit/${crawlId}/pages/${encodeURIComponent(url)}/pointingPages
      api
        .get(
          `/audit/${audit.id}/pages/${encodeURIComponent(
            audit.current.url.url.raw
          )}/pointingPages?from=${page * pageSize}&size=${pageSize}`
        )
        .then(res => {
          this.setState({
            pointingPages: res.urls,
            pointingMaxPages: (Math.ceil(res.total / 10) * 10) / pageSize,
            pointingPagesSize: pageSize,
            pointingPagesPage: page,
          });
        });
    }
  }

  renderTable(data, columns, index) {
    const { pages, fetchTableData } = this.props;

    return (
      <div key={index}>
        <ReactTable
          pages={pages}
          data={data}
          defaultPageSize={10}
          columns={columns}
          onFetchData={state => {
            if (fetchTableData !== undefined) {
              fetchTableData(state.page, state.pageSize);
            }
          }}
        />
      </div>
    );
  }

  renderDynamicTable(data, columns, index, maxPage, size) {
    return (
      <div key={index}>
        <ReactTable
          manual
          data={data}
          defaultPageSize={size}
          pages={maxPage}
          columns={columns}
          onFetchData={state => {
            this.fetchPoitingUrls(state.page, state.pageSize);
          }}
        />
      </div>
    );
  }

  renderContainersRows(rowData) {
    const rows = [];
    // const { audit, fetchUrlData } = this.props;
    const { audit } = this.props;
    const { pointingMaxPages, pointingPagesSize } = this.state;

    rowData.data.forEach((element, index) => {
      let data;
      let status;

      if (element.type === "array") {
        rows.push(
          this.renderTable(
            Object.resolve(element.value, audit.current.url),
            element.columns,
            index
          )
        );
      } else if (element.type === "dynamic_array") {
        rows.push(
          this.renderDynamicTable(
            Object.resolve(element.value, this.state),
            element.columns,
            index,
            pointingMaxPages,
            pointingPagesSize
          )
        );
      } else {
        data = Object.resolve(element.value, audit.current.url);
        status = element.status
          ? Object.resolve(element.status, audit.current.url)
          : null;
      }

      if (data !== undefined) {
        rows.push(
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={element.title}
            data={data}
            status={status}
            link={element.link}
            // fetchUrlData={fetchUrlData}
            crawlId={audit.crawlId}
          />
        );
      }
    });

    return rows;
  }

  render() {
    // const { audit, fetchUrlData } = this.props;
    const { audit } = this.props;

    return (
      <UrlFinderView
        audit={audit}
        // pageStructure={retPageStructure(audit.crawlId, fetchUrlData)}
        pageStructure={retPageStructure(audit.crawlId)}
        renderContainersRows={rowData => this.renderContainersRows(rowData)}
        titleStatus={status => this.titleStatus(status)}
      />
    );
  }
}

Architecture.propTypes = {
  audit: PropTypes.shape().isRequired,
  fetchTableData: PropTypes.func.isRequired,
  // fetchUrlData: PropTypes.func.isRequired,
  pages: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ audit }) => ({ audit });

export default connect(mapStateToProps)(Architecture);
