import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_WEBSITE } from "../../queries/websites";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from "@material-ui/core";
import styles from "../UserView/UserView.module.css";

const WebsiteView = () => {
  // Get websiteId from URL
  const { id: websiteId } = useParams();

  const { loading, error, data } = useQuery(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  if (error)
    return (
      <Card>
        <CardHeader avatar={<Icon>http</Icon>} title="Subscription" />
        <CardContent>
          <Typography>Error</Typography>
        </CardContent>
      </Card>
    );

  if (loading)
    return (
      <Card>
        <CardHeader avatar={<Icon>http</Icon>} title="Subscription" />
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );

  return (
    <div>
      <h1>{data.website.domain}</h1>
      <Grid container spacing={2}>
        <Grid className={styles.gridItem} item>
          <Card>
            <CardHeader avatar={<Icon>contacts</Icon>} title="Informations" />
            <CardContent style={{ maxHeight: "200px", overflowX: "auto" }}>
              <p>
                <b>Id :</b> {data.website.id}
              </p>
              <p>
                <b>URL :</b> <a href={data.website.url}>{data.website.url}</a>
              </p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default WebsiteView;
