import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import psl from "psl";

// Legacy api client
import api from "../../api";

// GraphQL
import apollo from "../../apollo";
import { GET_USER_ACCOUNT_ID } from "../../queries/users";
import { CREATE_WEBSITE, GET_WEBSITES } from "../../queries/websites";
import { CREATE_AUDIT } from "../../queries/audits";

// Routing
import history from "../../history";

// Typings
import { CreationForm } from "./index";

type FormData = {
  website: string;
  userId: number;
  depth: number;
  maxURLs: number;
  speed: number;
  notifyByEmail: boolean;
  crawlVersion: string;
  batchVersion: string;
};

const Audit: CreationForm = ({ closeDialog }) => {
  const { errors, handleSubmit, register } = useForm<FormData>();

  const onSubmit = handleSubmit(
    async ({
      batchVersion,
      crawlVersion,
      depth,
      maxURLs,
      notifyByEmail,
      speed,
      userId,
      website: websiteUrl,
    }) => {
      try {
        // Parse the URL if it is valid, throws an error otherwise
        const url = new URL(websiteUrl);
        const sldTld = psl.get(url.hostname);

        // Fetch the user's accountId
        const {
          data: {
            user: { accountId },
          },
        } = await apollo.query<any>({
          query: GET_USER_ACCOUNT_ID,
          variables: {
            id: userId,
          },
        });

        // Try to find a website he owns with the same domain
        const {
          data: { websites },
        } = await apollo.query<any>({
          query: GET_WEBSITES,
          variables: {
            accountId,
            domain: sldTld,
          },
        });

        // Website ID the audit will be attached to
        let websiteId;

        // If a website already exists, use it, otherwise create a new one
        if (websites.length) {
          websiteId = websites[0].id;
        } else {
          const {
            data: {
              // @ts-ignore
              createWebsite,
            },
          } = await apollo.mutate({
            mutation: CREATE_WEBSITE,
            variables: {
              input: {
                accountId,
                domain: sldTld,
                url: url.toString(),
              },
            },
          });

          websiteId = createWebsite.id;
        }

        // Create the audit
        const {
          data: {
            // @ts-ignore
            createAudit: { id: auditId },
          },
        } = await apollo.mutate({
          mutation: CREATE_AUDIT,
          variables: {
            input: {
              depth: depth ? +depth : 1,
              maxURL: maxURLs ? +maxURLs : 100,
              speed: speed ? +speed : 1,
              websiteId,
            },
          },
        });

        // Run the audit
        await api.post(`/audits/${auditId}/_run`, {
          disableEmail: !notifyByEmail,
          version: {
            crawl: crawlVersion,
            batch: batchVersion,
          },
        });

        closeDialog();
        // dispatch(clearFilters(FilterScope.AUDIT)); // TODO Enable
        history.push(`/audits/${auditId}`);
      } catch (err) {
        // TODO
      }
    }
  );

  return (
    <Fragment>
      <DialogTitle>Run a new audit</DialogTitle>

      <DialogContent>
        <TextField
          error={!!errors.website}
          fullWidth
          inputRef={register({
            pattern: /^https?:\/\/.*$/,
            required: true,
          })}
          label="Website"
          margin="dense"
          name="website"
          required
        />

        <TextField
          error={!!errors.userId}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="User id"
          margin="dense"
          name="userId"
          required
          type="number"
        />

        <TextField
          error={!!errors.depth}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1 })}
          label="Depth"
          margin="dense"
          name="depth"
          type="number"
        />

        <TextField
          error={!!errors.maxURLs}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1 })}
          label="Max URLs"
          margin="dense"
          name="maxURLs"
          type="number"
        />

        <TextField
          error={!!errors.speed}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1 })}
          label="Speed"
          margin="dense"
          name="speed"
          type="number"
        />

        <TextField
          error={!!errors.crawlVersion}
          fullWidth
          inputRef={register()}
          label="Crawl version"
          margin="dense"
          name="crawlVersion"
        />

        <TextField
          error={!!errors.batchVersion}
          fullWidth
          inputRef={register()}
          label="Batch version"
          margin="dense"
          name="batchVersion"
        />

        <FormControlLabel
          control={<Switch color="primary" />}
          inputRef={register()}
          label="Notify user by email"
          name="notifyByEmail"
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeDialog} variant="contained">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Audit;
