export enum AccountSubscription {
  Any = "ANY",
  Free = "FREE",
  ProMonthly = "PRO_MONTHLY",
  Unlimited = "UNLIMITED",
  Admin = "ADMIN",
}

export type Account = {
  company: string;
  id: number;
  subscription: AccountSubscription;
};
