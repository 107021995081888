import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

type Props = {
  action: any;
  cancel: any;
  open: boolean;
};

const ConfirmationDialog: FunctionComponent<Props> = ({
  action,
  cancel,
  open,
}) => (
  <Dialog open={open}>
    <DialogTitle>Confirmation</DialogTitle>

    <DialogContent>Are you sure you want to proceed ?</DialogContent>

    <DialogActions>
      <Button color="secondary" onClick={() => cancel()}>
        No
      </Button>
      <Button color="primary" onClick={() => action()}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
