import {
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Icon,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useMutation } from "@apollo/client";

// Components
import ConfirmationDialog from "../UI/Dialog/ConfirmationDialog";

// GraphQL
import { DELETE_USER } from "../../queries/users";

// Routing
import history from "../../history";

type Props = {
  userId: number;
};

const Delete: FunctionComponent<Props> = ({ userId }) => {
  const [shouldConfirm, showConfirmation] = useState(false);
  const [deleteUser] = useMutation(DELETE_USER);

  return (
    <Card>
      <CardHeader avatar={<Icon>delete</Icon>} title="Account deletion" />

      <CardContent>
        <Typography variant="body2">
          You can delete the user&rsquo;s account. He/she will not be able to
          log in anymore.
          <br />
          <br />
          However, the user account will only be soft deleted, meaning it can be
          retrieved at any moment with its associated data.
          <br />
          <br />
          The website&rsquo;s account to which the user belongs to will not be
          affected, meaning the other users in this account will be able to log
          in as usual.
        </Typography>
      </CardContent>

      <CardActions>
        <Button color="secondary" onClick={() => showConfirmation(true)}>
          Delete
        </Button>
      </CardActions>

      <ConfirmationDialog
        action={() =>
          deleteUser({ variables: { id: userId } }).then(() =>
            history.push("/users")
          )
        }
        cancel={() => showConfirmation(false)}
        open={shouldConfirm}
      />
    </Card>
  );
};

export default Delete;
