import React from "react";
import PropTypes from "prop-types";

import Tile from "../UI/Tile/Tile";

import styles from "./NewQuoteUser.module.css";

const NewQuoteUser = ({
  user: { firstName, lastName, email, company },
  handleInputChange,
}) => (
  <Tile icon="fa-user" name="User information">
    <div className={styles.wrapper}>
      <label htmlFor="first-name">
        First Name
        <input
          id="first-name"
          name="firstName"
          onChange={handleInputChange}
          type="text"
          value={firstName || ""}
        />
      </label>
      <label htmlFor="last-name">
        Last Name
        <input
          id="last-name"
          name="lastName"
          onChange={handleInputChange}
          type="text"
          value={lastName || ""}
        />
      </label>
      <label htmlFor="email">
        Email
        <input
          id="email"
          name="email"
          onChange={handleInputChange}
          type="email"
          value={email || ""}
        />
      </label>
      <label htmlFor="company">
        Company
        <input
          id="company"
          name="company"
          onChange={handleInputChange}
          type="text"
          value={company || ""}
        />
      </label>
    </div>
  </Tile>
);

NewQuoteUser.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func,
};

NewQuoteUser.defaultProps = {
  handleInputChange: () => {},
};

export default NewQuoteUser;
