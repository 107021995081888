import { connect } from "react-redux";
import { Dispatch } from "redux";

// Component to containerize
import OptimizationView from "../../../components/AuditView/OptimizationView";

// Helper functions and enums
import { DataType } from "../formatChartData";
import { fetchTableData2 } from "../fetchTableData";
import {
  depth as depthColumn,
  hnHierarchyJump as hnHierarchyJumpColumn,
  url as urlColumn,
} from "../tableColumns";

interface OwnProps {}

interface StateProps {
  [index: string]: any;
}

interface DispatchProps {
  dispatch: Dispatch;
}

const mapStateToProps = ({ audit }: any) => ({
  audit,
  catchLabel: true,
  chartContentField: "hn_hierarchy",
  chartDataType: DataType.HnHierarchy,
  fetchTableData: fetchTableData2("hn_hierarchy_jump"),
});

const mergeProps = (
  stateProps: StateProps,
  { dispatch }: DispatchProps,
  ownProps: OwnProps
) => ({
  ...stateProps,
  dispatch,
  ...ownProps,
  tableColumns: [
    urlColumn(stateProps.audit.id, dispatch),
    depthColumn,
    hnHierarchyJumpColumn,
  ],
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
  mergeProps
)(OptimizationView);
