import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import jwtDecode from "jwt-decode";

import { logout, refreshAccessToken } from "./utils/auth";

// Set the proper Authorization header for every request
const authLink = setContext(async () => {
  let accessToken = localStorage.getItem("accessToken");

  // Get a new access token if one is not already present in the localStorage
  // e.g. the user just logged in
  if (!accessToken) {
    accessToken = await refreshAccessToken();
  }

  // Refresh the access token if it is has expired
  const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);

  if (decodedAccessToken.exp * 1000 < Date.now()) {
    accessToken = await refreshAccessToken();
  }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

// Disconnect user if authentication problems are encountered
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      for (const error of graphQLErrors) {
        if (
          error &&
          error.extensions &&
          error.extensions.code === "UNAUTHENTICATED"
        ) {
          if (error.message.endsWith("TokenExpired")) {
            return forward(operation);
          }

          logout();
        }
      }

    // @ts-ignore
    if (networkError && networkError.statusCode === 401) {
      logout();
    }
  }
);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const cache = new InMemoryCache();

export default new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  // TODO Remove to leverage cache
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
  cache,
});
