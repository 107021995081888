import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";

// Components
import Form, { FormData } from "../EditionForms/NetlinkingPublication";

// GraphQL
import {
  NetlinkingConfiguration,
  NetlinkingPublication,
  UPDATE_NETLINKING_PUBLICATION,
  UpdateNetlinkingPublicationData,
  UpdateNetlinkingPublicationVariables,
} from "../../queries/netlinking";

type Props = {
  configurations: NetlinkingConfiguration[];
  publication: NetlinkingPublication;
  open: boolean;
  onCancel: () => any;
  onSuccess: () => any;
};

const EditionDialog: FunctionComponent<Props> = ({
  configurations,
  publication,
  open,
  onCancel,
  onSuccess,
}) => {
  const formMethods = useForm<FormData>();

  const [updatePublication] = useMutation<
    UpdateNetlinkingPublicationData,
    UpdateNetlinkingPublicationVariables
  >(UPDATE_NETLINKING_PUBLICATION);

  const onEditionSubmit = formMethods.handleSubmit((data) =>
    updatePublication({
      variables: {
        id: publication.id,
        input: {
          ...data,
          citationFlow: +data.citationFlow,
          date: data.date ? data.date : Date.now(),
          trustFlowScore: +data.trustFlowScore,
        },
      },
    }).then(() => onSuccess())
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Update publication</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <Form publication={publication} configurations={configurations} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color="primary" onClick={onEditionSubmit} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditionDialog;
