import { connect } from "react-redux";
import { Dispatch } from "redux";

// Component to containerize
import OptimizationView from "../../../components/AuditView/OptimizationView";

// Helper functions and enums
import { DataType } from "../formatChartData";
import { fetchTableData2 } from "../fetchTableData";
import { depth as depthColumn, url as urlColumn } from "../tableColumns";

interface OwnProps {}

interface StateProps {
  [index: string]: any;
}

interface DispatchProps {
  dispatch: Dispatch;
}

const mapStateToProps = ({ audit }: any) => ({
  audit,
  chartContentField: DataType.Depths, // TODO Deprecated
  chartDataType: DataType.Depths, // TODO Deprecated
  chartType: "bar",
  dataType: DataType.Depths,
  fetchTableData: fetchTableData2("depth"),
});

const mergeProps = (
  stateProps: StateProps,
  { dispatch }: DispatchProps,
  ownProps: OwnProps
) => ({
  ...stateProps,
  dispatch,
  ...ownProps,
  tableColumns: [urlColumn(stateProps.audit.id, dispatch), depthColumn],
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
  mergeProps
)(OptimizationView);
