import { AnyAction } from "redux";

import { CLEAR_ERROR, CLEAR_ERRORS } from "../actions/errors";

function errors(state: string[] = [], action: AnyAction) {
  // Check if the action is an error thrown by another asynchronous action,
  // and add it into the errors array if so
  // An action thrown by another asynchronous action ends with _ERR
  if (/^(?:[A-Z]+|_)+_ERR$/.test(action.type)) {
    return [...state, action.type];
  }

  switch (action.type) {
    case CLEAR_ERROR:
      return state.filter((error) => error !== action.error);
    case CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
}

export default errors;
