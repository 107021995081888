import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";

// Components
import Form, { FormData } from "../EditionForms/NetlinkingConfiguration";

// GraphQL
import {
  NetlinkingConfiguration,
  UPDATE_NETLINKING_CONFIGURATION,
  UpdateNetlinkingConfigurationData,
  UpdateNetlinkingConfigurationVariables,
} from "../../queries/netlinking";

type Props = {
  configuration: NetlinkingConfiguration;
  open: boolean;
  onCancel: () => any;
  onSuccess: () => any;
};

const EditionDialog: FunctionComponent<Props> = ({
  configuration,
  open,
  onCancel,
  onSuccess,
}) => {
  const formMethods = useForm<FormData>();

  const [updateConfiguration] = useMutation<
    UpdateNetlinkingConfigurationData,
    UpdateNetlinkingConfigurationVariables
  >(UPDATE_NETLINKING_CONFIGURATION);

  const onEditionSubmit = formMethods.handleSubmit((data) =>
    updateConfiguration({
      variables: {
        id: configuration.id,
        input: {
          ...data,
          ratio: +data.ratio,
        },
      },
    }).then(() => onSuccess())
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Update configuration</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <Form configuration={configuration} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color="primary" onClick={onEditionSubmit} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditionDialog;
