import { AnyAction } from "redux";

import { D_AUDIT_OK } from "../actions/audits";

const defaultState = {
  count: null,
  list: null,
};

function audits(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case D_AUDIT_OK:
      return {
        ...state,
        // @ts-ignore
        list: state.list.filter((audit: any) => audit.id !== action.id),
      };
    default:
      return state;
  }
}

export default audits;
