import gql from "graphql-tag";

import apollo from "../../apollo";
import format from "./formatBoolQuery";

const Tag = {
  Depth: "depth",
  DuplicatePages: "duplicate",
  Lang: "lang",
  LoadTime: "downloadTime",
  PageWeight: "size",
  Protocol: "procotol",
  UrlCompliance: "compliant",
  WordsCount: "wordCount",
};

/**
 *
 * @param auditId
 * @param body
 * @param from
 * @param size
 */
async function fetchFromAPI(
  auditId: number | string,
  { body, from, size }: any
) {
  const {
    data: {
      audit: { pages },
    },
  } = await apollo.query<any>({
    query: gql`
      query($auditId: ID!, $body: String, $from: Int, $size: Int) {
        audit(id: $auditId) {
          id
          pages(stringBody: $body, from: $from, size: $size) {
            hits
            max_score
            total
          }
        }
      }
    `,
    variables: {
      auditId,
      body: JSON.stringify(body),
      from,
      size,
    },
  });

  return pages;
}

function formatResult(hits: any[], pagesCount: number, tag: string) {
  const tableData = hits.map(({ _source }) => {
    switch (tag) {
      case Tag.Depth:
        return {
          url: _source.url.raw,
          status: _source.depth,
          depth: _source.depth,
        };
      case Tag.DuplicatePages:
        return {
          url: _source.url.raw,
          duplicate:
            _source.similar_urls && _source.similar_urls.length > 0
              ? "duplicate"
              : "unique",
          depth: _source.depth,
        };
      case Tag.Lang:
        return {
          url: _source.url.raw,
          lang: _source.lang || "Not set",
          depth: _source.depth,
        };
      case Tag.LoadTime:
        return {
          url: _source.url.raw,
          load_time: `${Number(_source.download_time).toFixed(2)} s`,
          size: `${Math.round(Number(_source.size))} Ko`,
          depth: _source.depth,
        };
      case Tag.Protocol:
        return {
          url: _source.url.raw,
          protocol: _source.url.protocol,
          status: _source.protocol,
        };
      case Tag.PageWeight:
        return {
          url: _source.url.raw,
          size: `${Math.round(Number(_source.size))} Ko`,
          depth: _source.depth,
        };
      case Tag.UrlCompliance:
        return {
          url: _source.url.raw,
          compliant: _source.url.compliant ? "oui" : "non",
          depth: _source.depth,
        };
      case Tag.WordsCount:
        return {
          url: _source.url.raw,
          wordCount: Math.round(Number(_source.word_count)),
          depth: _source.depth,
        };
      default:
        return {
          url: _source.url.raw,
          [tag]: _source[tag],
          depth: _source.depth,
        };
    }
  });

  return {
    tableData,
    tablePages: pagesCount,
  };
}

// TODO Merge with generic function then remove
export async function fetchWeightTableData(
  page: number,
  pageSize: number,
  sort: string[],
  auditId: number | string,
  selectedLabels: string[],
  allLabels: string[]
) {
  let bool: any = { must: [] };

  if (selectedLabels) {
    // split label de la forme "0.2-0.5"
    if (allLabels.includes(selectedLabels[0])) {
      const splitLabel = selectedLabels[0].slice(0, -3).split("-"); // remove - and " s"
      const min = splitLabel[0];
      const max = splitLabel[1];
      bool = {
        must: [
          {
            range: {
              size: {
                gte: min,
                lte: max,
              },
            },
          },
        ],
      };
    } else {
      let values = [];
      let i = 0;
      while (selectedLabels[i]) {
        const splitLabel = selectedLabels[i].slice(0, -3).split("-");

        if (splitLabel.length === 1) {
          const min = splitLabel[0].slice(2);
          values.push({ range: { size: { from: min } } });
        } else {
          const min = splitLabel[0];
          const max = splitLabel[1];

          values.push({ range: { size: { from: min, to: max } } });
        }
        i += 1;
      }

      if (!selectedLabels[0]) {
        values = [];
        values.push({ range: { size: { to: 0 } } });
      }

      bool = {
        must: [{ bool: { should: values } }],
      };
    }
  }

  const sortQuery = format.createSort(sort);

  bool.must.push({ range: { return_code: { lt: 400 } } }); // don't get pages that got an error

  const body = {
    query: {
      bool,
    },
    sort: sortQuery,
  };

  const { hits, total } = await fetchFromAPI(auditId, {
    from: page * pageSize,
    size: pageSize,
    body,
  });

  return formatResult(hits, Math.ceil(total / pageSize), Tag.PageWeight);
}

// TODO Merge with generic function then remove
export async function fetchLoadTimeTableData(
  page: number,
  pageSize: number,
  sort: string[],
  auditId: number | string,
  selectedLabels: string[],
  allLabels: string[]
) {
  let bool = {};

  if (selectedLabels) {
    // split label de la forme "0.2-0.5"
    if (allLabels.includes(selectedLabels[0])) {
      const splitLabel = selectedLabels[0].slice(0, -2).split("-"); // remove - and " s"
      const min = splitLabel[0];
      const max = splitLabel[1];

      bool = {
        must: [
          {
            range: {
              download_time: {
                gte: min,
                lte: max,
              },
            },
          },
        ],
      };
    } else {
      let values = [];
      let i = 0;
      while (selectedLabels[i]) {
        const splitLabel = selectedLabels[i].slice(0, -2).split("-");

        if (splitLabel.length === 1) {
          const min = splitLabel[0];
          values.push({ range: { download_time: { from: min } } });
        } else {
          const min = splitLabel[0];
          const max = splitLabel[1];

          values.push({ range: { download_time: { from: min, to: max } } });
        }

        i += 1;
      }

      if (!selectedLabels[0]) {
        values = [];
        values.push({ range: { download_time: { to: 0 } } });
      }

      bool = {
        must: [{ bool: { should: values } }],
      };
    }
  }

  const body = {
    query: {
      bool,
    },
    sort: format.createSort(sort),
  };

  const { hits, total } = await fetchFromAPI(auditId, {
    from: page * pageSize,
    size: pageSize,
    body,
  });

  return formatResult(hits, Math.ceil(total / pageSize), Tag.LoadTime);
}

// TODO Merge with generic function then remove
export async function fetchWordCountTableData(
  page: number,
  pageSize: number,
  sort: string[],
  auditId: number | string,
  selectedLabels: string[],
  allLabels: string[]
) {
  let bool: any = { must: [] };

  if (selectedLabels) {
    // split label de la forme "0.2-0.5"
    if (allLabels.includes(selectedLabels[0])) {
      const splitLabel = selectedLabels[0].split("-");
      let min = splitLabel[0];
      const max = splitLabel[1];

      if (splitLabel.length === 1) {
        min = min.slice(2);
      }

      bool = {
        must: [
          {
            range: {
              word_count: {
                gte: min,
                lte: max,
              },
            },
          },
        ],
      };
    } else {
      let values = [];
      let i = 0;

      while (selectedLabels[i]) {
        const splitLabel = selectedLabels[i].split("-");

        if (splitLabel.length === 1) {
          const min = splitLabel[0].slice(2);
          values.push({ range: { word_count: { from: min } } });
        } else {
          const min = splitLabel[0];
          const max = splitLabel[1];

          values.push({ range: { word_count: { from: min, to: max } } });
        }
        i += 1;
      }

      if (!selectedLabels[0]) {
        values = [];
        values.push({ range: { word_count: { lt: 0 } } });
      }

      bool = {
        must: [{ bool: { should: values } }],
      };
    }
  }

  bool.must.push({ range: { return_code: { lt: 400 } } });

  const body = {
    query: {
      bool,
    },
    sort: format.createSort(sort),
  };

  const { hits, total } = await fetchFromAPI(auditId, {
    from: page * pageSize,
    size: pageSize,
    body,
  });

  return formatResult(hits, Math.ceil(total / pageSize), Tag.WordsCount);
}

/**
 *
 * @param body
 * @param selectedLabels
 */
function buildDuplicateQuery(body: any, selectedLabels: string[]) {
  const conditionObject = {
    nested: {
      path: "similar_urls",
      query: { bool: { must: [{ exists: { field: "similar_urls" } }] } },
    },
  };

  // Duplicate content has only two states, simplifying query building
  if (selectedLabels[0] === "unique") {
    body.query.bool.must_not.push(conditionObject);
  } else {
    body.query.bool.must.push(conditionObject);
  }

  return body;
}

/**
 *
 * @param body
 * @param selectedLabels
 */
function buildHnHierarchyJumpQuery(body: any, selectedLabels: string[]) {
  // Use the "must" field if there is only one selected label
  if (selectedLabels.length === 1) {
    body.query.bool.must.push({
      match: {
        hn_hierarchy_jump: selectedLabels[0] === "correct" ? "false" : "true",
      },
    });
  }
  // Or use the "should" field if there are multiple selected labels
  else {
    // eslint-disable-next-line no-param-reassign
    body.query.bool.should = selectedLabels.map((label) => ({
      match: {
        hn_hierarchy_jump: label === "correct" ? "false" : "true",
      },
    }));
  }

  return body;
}

/**
 *
 * @param body
 * @param selectedLabels
 */
function buildURLComplianceQuery(body: any, selectedLabels: string[]) {
  // Use the "must" field if there is only one selected label
  if (selectedLabels.length === 1) {
    body.query.bool.must.push({
      nested: {
        path: "url",
        query: {
          match: {
            "url.compliant":
              selectedLabels[0] === "compliant" ? "true" : "false",
          },
        },
      },
    });
  }
  // Or use the "should" field if there are multiple selected labels
  else {
    // eslint-disable-next-line no-param-reassign
    body.query.bool.should = selectedLabels.map((label) => ({
      nested: {
        path: "url",
        query: {
          match: { "url.compliant": label === "compliant" ? "true" : "false" },
        },
      },
    }));
  }

  return body;
}

/**
 *
 * @param body
 * @param selectedLabels
 */
function buildURLProtocolQuery(body: any, selectedLabels: string[]) {
  // Use the "must" field if there is only one selected label
  if (selectedLabels.length === 1) {
    body.query.bool.must.push({
      nested: {
        path: "url",
        query: { match: { "url.protocol": selectedLabels[0] } },
      },
    });
  }
  // Or use the "should" field if there are multiple selected labels
  else {
    // eslint-disable-next-line no-param-reassign
    body.query.bool.should = selectedLabels.map((label) => ({
      nested: {
        path: "url",
        query: { match: { "url.protocol": label } },
      },
    }));
  }

  return body;
}

/**
 *
 * @param body
 * @param selectedLabels
 * @param tag
 * @param nested
 */
function buildDefaultQuery(
  body: any,
  selectedLabels: string[],
  tag: string,
  nested = false
) {
  // Use the "must" field if there is only one selected label
  if (selectedLabels.length === 1) {
    body.query.bool.must.push(
      nested
        ? {
            nested: {
              path: "url",
              query: { match: { [tag]: selectedLabels[0] } },
            },
          }
        : { match: { [tag]: selectedLabels[0] } }
    );
  }
  // Or use the "should" field if there are multiple selected labels
  else {
    // eslint-disable-next-line no-param-reassign
    body.query.bool.should = selectedLabels.map((label) =>
      nested
        ? {
            nested: {
              path: "url",
              query: { match: { [tag]: label } },
            },
          }
        : { match: { [tag]: label } }
    );
  }

  return body;
}

/**
 *
 * @param tag
 */
export function fetchTableData2(tag: string) {
  return async (
    pageNumber: number,
    pageSize: number,
    sort: string[],
    auditId: number | string,
    selectedLabels: string[],
    allLabels: string[]
  ) => {
    // Build ElasticSearch query object
    let body: any = {
      query: {
        bool: {
          must: [
            // Ignore error pages by default
            { range: { return_code: { lt: 400 } } },
          ],
          must_not: [],
          should: [],
        },
      },
      sort: format.createSort(sort),
    };

    // If specific labels have been selected
    if (selectedLabels && selectedLabels.length) {
      switch (tag) {
        case "duplicate_pages":
          body = buildDuplicateQuery(body, selectedLabels);
          break;
        case "hn_hierarchy_jump":
          body = buildHnHierarchyJumpQuery(body, selectedLabels);
          break;
        case "url.compliant":
          body = buildURLComplianceQuery(body, selectedLabels);
          break;
        case "url.protocol":
          body = buildURLProtocolQuery(body, selectedLabels);
          break;
        default:
          body = buildDefaultQuery(body, selectedLabels, tag);
      }
    }

    // Fetch data from API
    const { hits, total } = await fetchFromAPI(auditId, {
      from: pageNumber * pageSize,
      size: pageSize,
      body,
    });

    // Return formatted data
    return formatResult(hits, Math.ceil(total / pageSize), tag);
  };
}
