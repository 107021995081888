import { AnyAction } from "redux";

import { F_AUDIT_OK, F_AUDIT_URL_INFO_OK } from "../actions/audits";

function audit(state: any = null, action: AnyAction) {
  switch (action.type) {
    case F_AUDIT_OK:
      return { ...state, ...action.audit };
    case F_AUDIT_URL_INFO_OK:
      return { ...state, current: { url: action.urlInfo } };
    default:
      return state;
  }
}

export default audit;
