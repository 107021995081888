import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";

// Components
import CreationForm, {
  FormData as CreationFormData,
} from "../CreationForms/NetlinkingPublication";

// GraphQL
import {
  CREATE_NETLINKING_PUBLICATION,
  CreateNetlinkingPublicationData,
  CreateNetlinkingPublicationVariables,
  NetlinkingConfiguration,
} from "../../queries/netlinking";

type Props = {
  campaignId: string;
  configurations: NetlinkingConfiguration[];
  open: boolean;
  onCancel: () => any;
  onSuccess: () => any;
};

const NetlinkingPublication: FunctionComponent<Props> = ({
  campaignId,
  configurations,
  open,
  onCancel,
  onSuccess,
}) => {
  const formMethods = useForm<CreationFormData>();

  const [createPublication] = useMutation<
    CreateNetlinkingPublicationData,
    CreateNetlinkingPublicationVariables
  >(CREATE_NETLINKING_PUBLICATION);

  const onSubmit = formMethods.handleSubmit((data) =>
    createPublication({
      variables: {
        input: {
          ...data,
          citationFlow: +data.citationFlow,
          date: data.date ? data.date : Date.now(),
          netlinkingCampaignId: campaignId,
          netlinkingConfigurationId: +data.netlinkingConfigurationId,
          trustFlowScore: +data.trustFlowScore,
        },
      },
    }).then(async () => {
      onSuccess();
    })
  );

  return (
    <Dialog open={open}>
      <DialogTitle>New publication</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <CreationForm configurations={configurations} />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NetlinkingPublication;
