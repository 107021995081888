import React from "react";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

const NotFound = () => (
  <div className={styles.container}>
    <h1>Not Found</h1>
    <Link to="/">
      <button type="button">Go to homepage</button>
    </Link>
  </div>
);

export default NotFound;
