import React, { Component } from "react";
import FlagIconFactory from "react-flag-icon-css";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface Props {
  className: any;
  isFocused: any;
  onFocus: any;
  onSelect: any;
  option: any;
}

class FlagOption extends Component<Props> {
  handleMouseDown = (event: any) => {
    const { option, onSelect } = this.props;

    event.preventDefault();
    event.stopPropagation();

    onSelect(option, event);
  };

  handleMouseEnter = (event: any) => {
    const { option, onFocus } = this.props;

    onFocus(option, event);
  };

  handleMouseMove = (event: any) => {
    const { option, isFocused, onFocus } = this.props;

    if (isFocused) {
      return;
    }

    onFocus(option, event);
  };

  render() {
    const { option, children, className } = this.props;

    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={option.title}
      >
        <div
          style={{
            display: "inline-block",
            marginRight: 10,
            position: "relative",
            verticalAlign: "middle",
          }}
        >
          <FlagIcon code={option.value} />
        </div>
        {children}
      </div>
    );
  }
}

export default FlagOption;
