import { useLazyQuery } from "@apollo/client";
import { Delete as DeleteIcon, Person as PersonIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

// Redux
import { Filter, FilterScope, setFilter } from "../actions/filters";

// Routing
import history from "../history";

// GraphQL
import apollo from "../apollo";
import { DELETE_RANKING } from "../queries/rankings";

// Components
import TableView, {
  Column,
  RowAction,
} from "../components/TableView/TableView";
import CreationForm from "../components/CreationForms/Ranking";

// Typings
import { Ranking } from "../types/ranking";

const QUERY = gql`
  query($accountId: ID, $domain: String, $limit: Int, $offset: Int) {
    rankings(
      accountId: $accountId
      domain: $domain
      limit: $limit
      offset: $offset
    ) {
      country
      createdAt
      id
      language
      periodicity
      website {
        accountId
        id
        domain
      }
    }
    rankingsCount(accountId: $accountId, domain: $domain)
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    ranking(id: $id) {
      country
      createdAt
      id
      language
      periodicity
      website {
        accountId
        id
        domain
      }
    }
  }
`;

interface QueryData {
  rankings: Ranking[];
  rankingsCount: number;
}

interface QuerySingleData {
  ranking?: Ranking;
}

interface QueryVariables {
  accountId?: string;
  domain?: string;
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number; // TODO Remove
  offset: number; // TODO Remove
}

interface StateProps {
  filters: { [key in Filter]: string };
}

interface DispatchProps {
  rowActions: RowAction<Ranking>[];
}

type Props = StateProps & DispatchProps;

const RankingsTableView: FunctionComponent<Props> = ({
  filters,
  rowActions,
}) => {
  const columns: Column<Ranking>[] = [
    {
      centered: true,
      computeLink: ({ id }: Ranking) => `/rankings/${id}`,
      computeValue: ({ id }: Ranking) => id,
      exclusiveFilter: true,
      filter: Filter.RANKING_ID,
      placeholder: "Id",
      width: 1,
    },
    {
      computeValue: ({ website }: Ranking) => (website ? website.domain : "-"),
      filter: Filter.RANKING_DOMAIN,
      placeholder: "Domain",
      width: 8,
    },
    {
      centered: true,
      computeValue: ({ createdAt }: Ranking) => moment(createdAt).fromNow(),
      disabledFilter: true,
      filter: Filter.RANKING_CREATED_AT,
      placeholder: "Creation",
      width: 3,
    },
  ];

  const {
    [Filter.RANKING_ACCOUNT_ID]: accountId,
    [Filter.RANKING_DOMAIN]: domain,
    [Filter.RANKING_ID]: id,
  } = filters;

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: {
      accountId,
      domain,
      limit: 40,
      offset: 0,
    },
  });

  if (id) {
    return (
      <TableView<Ranking, QuerySingleData, QuerySingleVariables>
        columns={columns}
        CreationForm={CreationForm}
        data={singleData}
        filtersScope={FilterScope.RANKING}
        loading={singleLoading}
        normalizeQueryData={({ ranking }) =>
          ranking ? { dataSet: [ranking], total: 1 } : { dataSet: [], total: 0 }
        }
        onFetch={fetchSingle}
        onRefetch={async (variables) => {
          if (refetchSingle) await refetchSingle(variables);
        }}
        rowActions={rowActions}
      />
    );
  }

  return (
    <TableView<Ranking, QueryData, QueryVariables>
      columns={columns}
      CreationForm={CreationForm}
      data={data}
      filtersScope={FilterScope.RANKING}
      loading={loading}
      normalizeQueryData={({ rankings, rankingsCount }) => ({
        dataSet: rankings || [],
        total: rankingsCount || 0,
      })}
      onFetch={fetch}
      onRefetch={async (variables) => {
        if (refetch) await refetch(variables);
      }}
      rowActions={rowActions}
    />
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  rowActions: [
    {
      handler: async ({ website: { accountId } }: any) => {
        dispatch(setFilter(Filter.USER_ACCOUNT_ID, accountId || 0));
        history.push("/users");
      },
      Icon: PersonIcon,
    },
    {
      confirmable: true,
      handler: async ({ id }: any) =>
        apollo.mutate({ mutation: DELETE_RANKING, variables: { id } }),
      Icon: DeleteIcon,
    },
  ],
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingsTableView);
