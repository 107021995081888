/*
Action dispatched to clear an error from the redux store
 */
export const CLEAR_ERROR = "CLEAR_ERROR";

export function clearError(error: string) {
  return {
    type: CLEAR_ERROR,
    error,
  };
}

/*
Action dispatched to clear all errors from the redux store
 */
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}
