import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

// Assets
import styles from "./Tile.module.css";

interface OwnProps {
  className?: string;
  icon?: string;
  name?: string;
}

type Props = OwnProps;

const Tile: FunctionComponent<Props> = ({
  children,
  className = "",
  icon = "",
  name = "Unknown",
}) => (
  <div className={`${className} ${styles.container}`}>
    <div className={styles.header}>
      <i className={`fas ${icon || "fa-arrows-alt"}`} />
      <h4>
        <FormattedMessage id={name} />
      </h4>
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

export default Tile;
