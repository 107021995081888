import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import Row from "./Row";
import UrlFinderView from "./UrlFinderView";

const pageStructure = [
  {
    title: "urlFinder.languages",
    data: [
      {
        title: "urlFinder.languageAttribute",
        value: "lang",
      },
      {
        title: "urlFinder.canonical",
        value: "canonical.raw",
        status: "canonical_status",
        link: true,
      },
    ],
  },
  {
    title: "urlFinder.href",
    status: "true",
    data: [
      {
        title: "urlFinder.hrefTag",
        value: "hreflangs",
        type: "hreflang",
        link: true,
      },
    ],
  },
  {
    title: "urlFinder.duplicates",
    data: [
      {
        type: "duplicate_pages",
        columns: [
          {
            Header: "URL",
            accessor: "raw",
            Cell: sent => <span>{sent.value}</span>,
          },
        ],
        value: "similar_urls",
      },
    ],
  },
];

Object.resolve = (path, obj) =>
  path
    .split(".")
    .reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || this);

class Content extends Component {
  static parseHrefLangs(hrefLangArray, link) {
    if (!hrefLangArray) return undefined;
    const hrefLangFormated = [];

    // const { fetchUrlData } = this.props;

    hrefLangArray.forEach((element, index) => {
      hrefLangFormated.push(
        <Row
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          title={element.lang}
          data={element.url.raw}
          link={link}
          // fetchUrlData={fetchUrlData}
        />
      );
    });
    return hrefLangFormated;
  }

  titleStatus() {
    const { audit } = this.props;

    if (audit.current.url.hreflangs.length > 0) {
      return "set";
    }

    return "notset";
  }

  renderTable(data, columns, index) {
    const { fetchTableData, pages } = this.props;

    return (
      <div key={index}>
        <ReactTable
          pages={pages}
          data={data}
          defaultPageSize={10}
          columns={columns}
          onFetchData={state => {
            if (fetchTableData !== undefined) {
              fetchTableData(state.page, state.pageSize);
            }
          }}
        />
      </div>
    );
  }

  renderContainersRows(rowData) {
    const rows = [];
    // const { audit, fetchUrlData } = this.props;
    const { audit } = this.props;

    rowData.data.forEach((element, index) => {
      let data;
      let status;

      if (element.type === "hreflang") {
        rows.push(
          Content.parseHrefLangs(
            Object.resolve(element.value, audit.current.url),
            element.link
          )
        );
      } else if (element.type === "duplicate_pages") {
        const rowData2 = Object.resolve(element.value, audit.current.url);

        if (rowData2 && rowData2.length > 0) {
          rows.push(this.renderTable(rowData2, element.columns, index));
        }
      } else {
        data = Object.resolve(element.value, audit.current.url);
        status = element.status
          ? Object.resolve(element.status, audit.current.url)
          : null;
      }

      if (data !== undefined) {
        rows.push(
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={element.title}
            data={data}
            status={status}
            link={element.link}
            // fetchUrlData={fetchUrlData}
          />
        );
      }
    });
    return rows;
  }

  render() {
    const { audit } = this.props;

    return (
      <UrlFinderView
        audit={audit}
        pageStructure={pageStructure}
        renderContainersRows={rowData => this.renderContainersRows(rowData)}
        titleStatus={status => this.titleStatus(status)}
      />
    );
  }
}

Content.propTypes = {
  audit: PropTypes.shape().isRequired,
  fetchTableData: PropTypes.func.isRequired,
  // fetchUrlData: PropTypes.func.isRequired,
  pages: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ audit }) => ({ audit });

export default connect(mapStateToProps)(Content);
