import { useLazyQuery } from "@apollo/client";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";

// Components
import TableView, { Column } from "../components/TableView/TableView";
import CreationForm from "../components/CreationForms/AdsCampaign";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// GraphQL
import apollo from "../apollo";
import { DELETE_ADS_CAMPAIGN } from "../queries/adsCampaigns";

// Typings
import { AdsCampaign, AdsCampaignStatus } from "../types/adsCampaigns";
import { Dialog } from "@material-ui/core";
import EditionDialog from "../components/EditionDialogs/AdsCampaign";
import { NetlinkingCampaignStatus } from "../types/netlinking";

const QUERY = gql`
  query(
    $accountId: ID
    $domain: String
    $googleAdsCustomerAccountId: String
    $status: String
    $budget: Int
    $limit: Int
    $offset: Int
  ) {
    adsCampaigns(
      accountId: $accountId
      domain: $domain
      googleAdsCustomerAccountId: $googleAdsCustomerAccountId
      budget: $budget
      status: $status
      limit: $limit
      offset: $offset
    ) {
      id
      status
      googleAdsCustomerAccountId
      datastudioId
      budget
      website {
        id
        accountId
        domain
      }
    }
    adsCampaignsCount(
      accountId: $accountId
      domain: $domain
      budget: $budget
      status: $status
      googleAdsCustomerAccountId: $googleAdsCustomerAccountId
    )
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    adsCampaign(id: $id) {
      id
      status
      googleAdsCustomerAccountId
      website {
        id
        accountId
        domain
      }
    }
  }
`;

interface QueryData {
  adsCampaigns: AdsCampaign[];
  adsCampaignsCount: number;
}

interface QuerySingleData {
  adsCampaign?: AdsCampaign;
}

interface QueryVariables {
  accountId?: string;
  domain?: string;
  googleAdsCustomerAccountId?: string;
  status?: AdsCampaignStatus;
  budget: number;
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number;
  offset: number;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const AdsCampaignsTableView: FunctionComponent<Props> = ({ filters }) => {
  const [openedEditForm, displayEditForm] = useState(false);
  const [
    editableAdsCampaign,
    setEditableAdsCampaign,
  ] = useState<AdsCampaign | null>(null);

  const columns: Column<AdsCampaign>[] = [
    {
      centered: true,
      computeValue: ({ id }: AdsCampaign) => id,
      exclusiveFilter: true,
      filter: Filter.ADS_CAMPAIGN_ID,
      placeholder: "ID",
      width: 1,
    },
    {
      computeValue: ({ website: { domain } }: AdsCampaign) => domain,
      filter: Filter.ADS_CAMPAIGN_DOMAIN,
      placeholder: "Domain",
      width: 5,
    },
    {
      computeValue: ({ googleAdsCustomerAccountId }: AdsCampaign) =>
        googleAdsCustomerAccountId,
      filter: Filter.ADS_CAMPAIGN_GOOGLE_ADS_ID,
      placeholder: "Google Ads ID",
      width: 2,
    },
    {
      computeValue: ({ budget }: AdsCampaign) => budget,
      filter: Filter.ADS_CAMPAIGN_BUDGET,
      placeholder: "Budget mensuel",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ status }: AdsCampaign) => status,
      filter: Filter.ADS_CAMPAIGN_STATUS,
      options: [
        { name: "Any", value: AdsCampaignStatus.Any },
        { name: "Running", value: AdsCampaignStatus.Running },
        { name: "Stopped", value: AdsCampaignStatus.Stopped },
      ],
      placeholder: "Status",
      width: 2,
    },
  ];

  const rowActions = [
    {
      confirmable: false,
      handler: async (adsCampaign: AdsCampaign) => {
        setEditableAdsCampaign(adsCampaign);
        displayEditForm(true);
      },
      Icon: EditIcon,
    },
    {
      confirmable: true,
      handler: ({ id }: AdsCampaign) =>
        apollo.mutate({
          mutation: DELETE_ADS_CAMPAIGN,
          variables: { id },
        }),
      Icon: DeleteIcon,
    },
  ];

  const {
    [Filter.ADS_CAMPAIGN_ID]: id,
    [Filter.ADS_CAMPAIGN_DOMAIN]: domain,
    [Filter.ADS_CAMPAIGN_GOOGLE_ADS_ID]: googleAdsCustomerAccountId,
    [Filter.ADS_CAMPAIGN_STATUS]: status,
    [Filter.ADS_CAMPAIGN_ACCOUNT_ID]: accountId,
    [Filter.ADS_CAMPAIGN_BUDGET]: budget,
  } = filters;

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: {
      ...(status &&
        status !== NetlinkingCampaignStatus.Any && {
          status: status as AdsCampaignStatus,
        }),
      accountId,
      domain,
      googleAdsCustomerAccountId,
      budget: +budget,
      limit: 40,
      offset: 0,
    },
  });

  if (id) {
    return (
      <div>
        <TableView<AdsCampaign, QuerySingleData, QuerySingleVariables>
          columns={columns}
          CreationForm={CreationForm}
          onSuccessCreation={refetch}
          data={singleData}
          filtersScope={FilterScope.ADS_CAMPAIGN}
          loading={singleLoading}
          normalizeQueryData={({ adsCampaign }) =>
            adsCampaign
              ? { dataSet: [adsCampaign], total: 1 }
              : { dataSet: [], total: 0 }
          }
          onFetch={fetchSingle}
          onRefetch={async (variables) => {
            if (refetchSingle) await refetchSingle(variables);
          }}
          rowActions={rowActions}
        />
        <EditionDialog
          adsCampaign={editableAdsCampaign!}
          open={openedEditForm}
          onCancel={() => displayEditForm(false)}
          onSuccess={async () => {
            displayEditForm(false);
            if (refetch) await refetch();
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <TableView<AdsCampaign, QueryData, QueryVariables>
        columns={columns}
        CreationForm={CreationForm}
        onSuccessCreation={refetch}
        data={data}
        filtersScope={FilterScope.ADS_CAMPAIGN}
        loading={loading}
        normalizeQueryData={({ adsCampaigns, adsCampaignsCount }) => ({
          dataSet: adsCampaigns || [],
          total: adsCampaignsCount || 0,
        })}
        onFetch={fetch}
        onRefetch={async (variables) => {
          if (refetch) await refetch(variables);
        }}
        rowActions={rowActions}
      />
      <EditionDialog
        adsCampaign={editableAdsCampaign!}
        open={openedEditForm}
        onCancel={() => displayEditForm(false)}
        onSuccess={async () => {
          displayEditForm(false);
          if (refetch) await refetch();
        }}
      />
    </div>
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(AdsCampaignsTableView);
