/**
 *
 * @param query
 */
function formatQuery(query: any[]) {
  return query.map((element) =>
    element.value ? { match: { [element.field]: [element.value] } } : element
  );
}

/**
 *
 * @param query
 */
function createBool(query: any) {
  const bool: any = {};

  if (query && "must" in query) {
    bool.must = formatQuery(query.must);
  }

  if (query && "must_not" in query) {
    bool.must_not = formatQuery(query.must_not);
  }

  if (query && "should" in query) {
    bool.should = formatQuery(query.should);
  }

  if (query && "exists" in query) {
    bool.exists = formatQuery(query.exists);
  }

  if (query && "bool" in query) {
    bool.bool = formatQuery(query.bool);
  }

  return bool;
}

/**
 *
 * @param sort
 * @param nestedPath
 * @returns {Array|*[]}
 */
function createSort(sort: any[], nestedPath: any = null) {
  if (!sort.length) {
    return [];
  }

  // Unique case
  if (sort[0].id === "duplicate") {
    return [
      {
        _script: {
          nested_path: "similar_urls",
          script: "doc['similar_urls.raw'].value.length()",
          order: sort[0].desc ? "desc" : "asc",
          type: "number",
        },
      },
    ];
  }

  const path: any = {
    order: sort[0].desc ? "desc" : "asc",
  };

  if (nestedPath) {
    path.nested_path = nestedPath;
  }

  const esPath = (() => {
    switch (sort[0].id) {
      case "compliant":
        return "url.compliant";
      // case "duplicate":
      //   return "similar_urls";
      case "load_time":
        return "download_time";
      case "protocol":
        return "url.protocol";
      case "url":
        return "url.raw";
      case "wordCount":
        return "word_count";
      default:
        return sort[0].id;
    }
  })();

  return [{ [esPath]: path }];
}

export default { createBool, createSort };
