import { Dispatch } from "redux";

export const Modals = {
  CONFIRMATION: "CONFIRMATION",
};

/*
Action to trigger a modal
 */
export const SHOW_MODAL = "SHOW_MODAL";

export function showModal(action: any, modal: any) {
  return {
    type: SHOW_MODAL,
    action,
    modal,
  };
}

/*
Action triggered when the user confirm an action from the modal
(Should only be called from the modal itself)
 */
export const CONFIRM = "CONFIRM";

export function confirm(action: any) {
  return (dispatch: Dispatch) => {
    dispatch(action());
    dispatch((() => ({ type: CONFIRM }))());
  };
}

/*
Action triggered when the user cancel an action from the modal
(Should only be called from the modal itself)
 */
export const DISMISS = "DISMISS";

export function dismiss() {
  return {
    type: DISMISS,
  };
}
