import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import UrlFinderView from "./UrlFinderView";
import Row from "./Row";

const pageStructure = [
  {
    title: "urlFinder.main",
    data: [
      {
        title: "urlFinder.previousPage",
        value: "previous_url.raw",
        link: true,
      },
      {
        title: "urlFinder.httpReturnCode",
        value: "return_code",
        type: "return_code",
      },
      {
        title: "urlFinder.redirectFrom",
        value: "redirect_from.raw",
      },
      {
        title: "urlFinder.depth",
        value: "depth",
      },
      {
        title: "urlFinder.startAt",
        value: "ts",
        type: "date",
      },
    ],
  },
  {
    title: "urlFinder.download",
    data: [
      {
        title: "urlFinder.weight",
        value: "size",
        type: "size",
      },
      {
        title: "urlFinder.downloadTime",
        value: "download_time",
        type: "second",
      },
    ],
  },
];

Object.resolve = (path, obj) =>
  path
    .split(".")
    .reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || this);

class Performance extends Component {
  static getReturnCodeStatus(returnCode) {
    let status;

    switch (true) {
      case returnCode < 200:
        status = "info";
        break;
      case returnCode < 300:
        status = "success";
        break;
      case returnCode < 400:
        status = "redirection";
        break;
      case returnCode < 500:
        status = "client_error";
        break;
      case returnCode < 600:
        status = "server_error";
        break;

      default:
        status = "info";
        break;
    }
    return status;
  }

  renderContainersRows(rowData) {
    const rows = [];
    // const { audit, fetchUrlData } = this.props;
    const { audit } = this.props;

    rowData.data.forEach((element, index) => {
      let data = Object.resolve(element.value, audit.current.url);
      let status;

      if (element.type === "return_code") {
        status = Performance.getReturnCodeStatus(data);
      } else if (element.type === "date") {
        data = moment(data).format("LLL");
        // data = moment().format("LLLL"); // сәрсенбі, 4 шілде 2018 19:08
      } else if (element.type === "size") {
        data = `${data.toFixed(1)} ko`;
      } else if (element.type === "second") {
        data = `${data.toFixed(2)}  s`;
      } else {
        status = element.status
          ? Object.resolve(element.status, audit.current.url)
          : null;
      }
      if (data !== undefined) {
        rows.push(
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={element.title}
            data={data}
            status={status}
            link={element.link}
            // fetchUrlData={fetchUrlData}
          />
        );
      }
    });
    return rows;
  }

  render() {
    const { audit } = this.props;

    return (
      <UrlFinderView
        audit={audit}
        pageStructure={pageStructure}
        renderContainersRows={rowData => this.renderContainersRows(rowData)}
        titleStatus={status => this.titleStatus(status)}
      />
    );
  }
}

Performance.propTypes = {
  audit: PropTypes.shape().isRequired,
  // fetchUrlData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ audit }) => ({ audit });

export default connect(mapStateToProps)(Performance);
