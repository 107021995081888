import { Website } from "./website";

export enum AdsCampaignStatus {
  Any = "ANY",
  Running = "RUNNING",
  Stopped = "STOPPED",
}

export type AdsCampaign = {
  id: string;
  googleAdsCustomerAccountId: string;
  datastudioId: string;
  budget: number;
  status: AdsCampaignStatus;
  createdAt: string;
  website: Website;
};
