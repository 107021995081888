import React, { Component } from "react";
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl";
import { Chart as ChartJs } from "chart.js";
import moment from "moment";
import _ from "lodash";

function getChartOptions(intl: IntlShape) {
  return {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 1,
            max: 100,
            reverse: true,
            // eslint-disable-next-line consistent-return
            callback(value: any) {
              // enleve les float sur l'axe
              if (value % 1 === 0) {
                return value;
              }
            },
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "ranking.keywords.yAxisLabel",
            }),
            fontStyle: "bold",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({
              id: "ranking.keywords.xAxisLabel",
            }),
            fontStyle: "bold",
          },
        },
      ],
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    spanGaps: true,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any) {
          return parseFloat(tooltipItem.yLabel.toFixed(1));
        },
      },
    },
    legend: {
      display: false,
    },
  };
}

interface OwnProps {
  rankingData: any;
  type: any;
}

interface State {
  chart: any;
}

type Props = OwnProps & WrappedComponentProps;

class Chart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chart: null,
    };
  }

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps: Props) {
    const { rankingData, type } = this.props;

    if (
      rankingData.infos &&
      !_.isEqual(prevProps.rankingData.infos, rankingData.infos)
    ) {
      // non null & when infos changed (device/date changed)
      this.drawDefaultLine(rankingData.infos.avg_by_date);
    }
    if (
      type === "keywords" &&
      !_.isEqual(
        prevProps.rankingData.selectedKeywords,
        rankingData.selectedKeywords
      )
    ) {
      // keyword has been selected/removed or their data has changed
      this.updateChart(rankingData.selectedKeywords);
    }
    if (
      type === "competitors" &&
      !_.isEqual(
        prevProps.rankingData.selectedCompetitors,
        rankingData.selectedCompetitors
      )
    ) {
      // competitor has been selected/removed or their data has changed
      this.updateChart(rankingData.selectedCompetitors);
    }
  }

  updateChart = (selectedItems: any[]) => {
    const { chart } = this.state;

    const saveDefaultLine = chart.data.datasets.shift(); // get first element (default line)
    chart.data.datasets = []; // clean the chart because selectedItems is empty
    chart.data.datasets.push(saveDefaultLine); // put default line back in the chart

    selectedItems.forEach((item, index) => {
      const data = item.by_date
        ? item.by_date.map((element: any) =>
            element.rank > 0 && element.rank <= 100 ? element.rank : null
          )
        : [];

      chart.data.datasets[index + 1] = {
        borderColor: item.color,
        backgroundColor: item.color,
        fill: false,
        borderWidth: 2,
        data,
      }; // +1 to keep the default line unchanged
    });

    // Commented in legacy
    // if (selectedItems.length > 0) {
    //   selectedItems.forEach((item, index) => {
    //     let _data = [];
    //     item.by_date.forEach(element => {
    //       if (element.rank > 0 && element.rank <= 100) _data.push(element.rank);
    //       else _data.push(null);
    //     });
    //     const dataset = {
    //       borderColor: item.color,
    //       backgroundColor: item.color,
    //       fill: false,
    //       borderWidth: 2,
    //       data: _data,
    //     };
    //     chart.data.datasets[index + 1] = dataset; // +1 to keep the default line unchanged
    //   });
    // } else {
    //   const saveDefaultLine = chart.data.datasets.shift(); // get first element (default line)
    //   chart.data.datasets = []; // clean the chart because selectedItemsis empty
    //   chart.data.datasets.push(saveDefaultLine); // put default line back in the chart
    // }

    chart.update();
  };

  drawDefaultLine = (averageByDate: any) => {
    if (averageByDate && averageByDate.length > 0) {
      // make sure there is data in the array
      const { chart } = this.state;

      chart.data.labels = []; // clean the chart's labels

      const data = averageByDate.map((element: any) => {
        chart.data.labels.push(moment(element.ts).format("DD-MM-YYYY"));

        return element.rank > 0 && element.rank <= 100 ? element.rank : null;
      });

      chart.data.datasets[0] = {
        borderColor: "#0086DC",
        backgroundColor: "#0086DC",
        fill: false,
        borderWidth: 2,
        data,
      }; // update only the first line so it doesn't mutate the chart if there is data in it
      chart.update();
    }
  };

  initChart = () => {
    const { intl, rankingData, type } = this.props;

    const ctx = document.getElementById("ranking-chart");

    // @ts-ignore
    const chart = new ChartJs(ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: getChartOptions(intl),
    });

    this.setState({ chart }, () => {
      // infos already fetched so can directly draw on chart (called when page changed)
      if (rankingData.infos) {
        this.drawDefaultLine(rankingData.infos.avg_by_date);
        if (type === "keywords" && rankingData.selectedKeywords.length > 0)
          this.updateChart(rankingData.selectedKeywords);
        if (
          type === "competitors" &&
          rankingData.selectedCompetitors.length > 0
        )
          this.updateChart(rankingData.selectedCompetitors);
      }
    });
  };

  render() {
    return (
      <div
        className="chart-container"
        style={{ position: "relative", height: "100%", width: "100%" }}
      >
        <canvas id="ranking-chart" />
      </div>
    );
  }
}

export default injectIntl(Chart);
