import { combineReducers } from "redux";

import audit from "./reducers/audit";
import audits from "./reducers/audits";
import errors from "./reducers/errors";
import filters from "./reducers/filters";
import loaders from "./reducers/loaders";
import modal from "./reducers/modal";
import order from "./reducers/order";
import quotes from "./reducers/quotes";
import ranking from "./reducers/ranking";
import rankings from "./reducers/rankings";
import todos from "./reducers/todos";

const rootReducer = combineReducers({
  audit,
  audits,
  errors,
  filters,
  loaders,
  modal,
  order,
  quotes,
  ranking,
  rankings,
  todos,
});

export default rootReducer;
