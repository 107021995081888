import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";

import Information from "./Information";
import List from "./List";

// GraphQL
import { GET_DETAILED_AUDIT } from "../../../queries/audits";
import { GET_MODULES } from "../../../queries/queries";

const Overview: FunctionComponent = () => {
  const [selection, setSelection] = useState<number[]>([]);
  const { id: auditId } = useParams();
  const { loading, data } = useQuery(GET_DETAILED_AUDIT, {
    variables: { id: auditId },
  });
  const { loading: loading1, data: data1 } = useQuery(GET_MODULES);

  if (loading || loading1) return <CircularProgress />;

  const modules = data1.modules.map((module: any) => ({
    ...module,
    todosCount: data.audit.todos.reduce(
      (count: any, todo: any) =>
        todo.optimisation.module.name === module.name || module.name === "all"
          ? count + 1
          : count,
      0
    ),
  }));
  /*
    Store current module selection in state
   */
  const switchModuleSelection = (moduleId: number) => {
    if (selection.includes(moduleId)) {
      setSelection(selection.filter((id) => id !== moduleId));
    } else {
      setSelection([...selection, moduleId]);
    }
  };

  // Filter the displayed todo list by module(s)
  const todos = data.audit.todos.filter((todo: any) => {
    // If modules list is empty, keep all todos
    if (!selection.length) {
      return true;
    }

    return selection.includes(todo.optimisation.module.id);
  });

  return (
    <Box
      style={{
        backgroundColor: "whitesmoke",
        boxSizing: "border-box",
        minHeight: "100vh",
        padding: "1rem",
      }}
    >
      <Typography variant="h4" style={{ marginBottom: "1rem" }}>
        Information
      </Typography>
      <Box display="flex">
        <Information audit={data.audit} />
      </Box>

      <Typography variant="h4" style={{ marginTop: "1rem" }}>
        Optimizations
      </Typography>
      {modules.length ? (
        <Box style={{ margin: "1rem 0" }}>
          {modules.map((module: any) => (
            <Chip
              avatar={<Avatar>{module.todosCount}</Avatar>}
              color={selection.includes(module.id) ? "primary" : "default"}
              key={module.id}
              label={module.name}
              onClick={() => switchModuleSelection(module.id)}
              style={{ margin: "0.2rem" }}
            />
          ))}
        </Box>
      ) : (
        <CircularProgress />
      )}
      <List audit={data.audit} todos={todos} />
    </Box>
  );
};

export default Overview;
