export const DataType = {
  HtmlTags: "html_tags",
  H1s: "h1s",
  Titles: "titles",
  Descriptions: "descriptions",
  ImgAlts: "alt_imgs",
  Canonicals: "canonicals",
  DuplicatePages: "duplicate_pages",
  Depths: "depths",
  ReturnCodes: "return_codes",
  DownloadTimeDistribution: "download_time_distribution",
  LatencyDistribution: "latency_distribution",
  WeightDistribution: "weight_distribution",
  WordCountDistribution: "word_count_distribution",
  Langs: "langs",
  OpenGraph: "opengraph",
  TwitterCard: "twittercard",
  HnHierarchy: "hn_hierarchy",
  Protocol: "protocol",
  Url: "url",
};

function htmlTags(data) {
  if (!data) {
    return null;
  }

  return {
    data: Object.keys(data).map(key => data[key]),
    labels: Object.keys(data),
  };
}

function langs(data) {
  if (!data) {
    return null;
  }

  return {
    data: Object.keys(data).map(key => data[key].nb),
    labels: Object.keys(data).map(key => data[key].lang),
  };
}

function returnCodes(data) {
  if (!data) {
    return null;
  }

  return {
    data: Object.keys(data).map(key => data[key].nb),
    labels: Object.keys(data).map(key => data[key].code),
  };
}

function downloadTimeDistribution(data) {
  if (!data) {
    return null;
  }

  return {
    data: [data["0.0-0.5"], data["0.5-1.0"], data["1.0-5.0"], data["5.0"]],
    labels: ["0.0-0.5 s", "0.5-1.0 s", "1.0-5.0 s", "5.0 s"],
  };
}

function weightDistribution(data) {
  if (!data) {
    return null;
  }

  return {
    data: [data["0-100"], data["100-500"], data["500-1000"], data["1000"]],
    labels: ["0-100 Ko", "100-500 Ko", "500-1000 Ko", "> 1000 Ko"],
  };
}

function wordCountDistribution(data) {
  if (data === undefined) return null;

  return {
    data: [
      data["0-200"],
      data["200-500"],
      data["500-800"],
      data["800-1200"],
      data["1200"],
    ],
    labels: ["0-200", "200-500", "500-800", "800-1200", "> 1200"],
  };
}

function latencyDistribution(data) {
  return {
    data: [data["0.0-0.2"], data["0.2-0.5"], data["0.5-2.0"], data["2.0"]],
    labels: ["0.0-0.2", "0.2-0.5", "0.5-2.0", "2.0"],
  };
}

export default function format(data, typeChart) {
  switch (typeChart) {
    case DataType.HtmlTags:
      return htmlTags(data);
    case DataType.H1s:
      return htmlTags(data);
    case DataType.Titles:
      return htmlTags(data);
    case DataType.Descriptions:
      return htmlTags(data);
    case DataType.ImgAlts:
      return htmlTags(data);
    case DataType.Canonicals:
      return htmlTags(data);
    case DataType.DuplicatePages:
      return htmlTags(data);
    case DataType.Depths:
      return htmlTags(data);
    case DataType.ReturnCodes:
      return returnCodes(data);
    case DataType.DownloadTimeDistribution:
      return downloadTimeDistribution(data);
    case DataType.LatencyDistribution:
      return latencyDistribution(data);
    case DataType.WeightDistribution:
      return weightDistribution(data);
    case DataType.WordCountDistribution:
      return wordCountDistribution(data);
    case DataType.Langs:
      return langs(data);
    case DataType.OpenGraph:
      return htmlTags(data);
    case DataType.TwitterCard:
      return htmlTags(data);
    case DataType.HnHierarchy:
      return htmlTags(data);
    case DataType.Protocol:
      return htmlTags(data);
    case DataType.Url:
      return htmlTags(data);
    default:
      return null;
  }
}
