import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Icon,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

const PasswordReset: FunctionComponent = () => (
  <Card>
    <CardHeader avatar={<Icon>vpn_key</Icon>} title="Password reset" />

    <CardContent>
      <p>
        The last user activity we have recorded on Octopulse is
        <span className="red"> unknown.</span>
      </p>
      <p>
        If the user has trouble connecting to his account or has his account
        compromised, you can send him an email with a link to reset his
        password.
      </p>
      <p>
        When the user clicks on the link, he will be redirected on a page where
        he can safely update his password.
      </p>
    </CardContent>

    <CardActions>
      <Button color="primary" disabled>
        Send an email
      </Button>
    </CardActions>
  </Card>
);

export default PasswordReset;
