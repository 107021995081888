import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  Snackbar,
  TextField,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";

// Typings
import { User } from "../../types/user";

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

type Props = {
  updateUser: any;
  user: User;
};

const Information: FunctionComponent<Props> = ({ updateUser, user }) => {
  // Component state hooks
  const [shouldDisplaySuccess, displaySuccess] = useState(false);
  const [isEditable, setEditable] = useState(false);

  // Form controller hook
  const { handleSubmit, register } = useForm();

  // Form validation callback
  const onSubmit = handleSubmit(
    ({ firstName, lastName, email, phone, partooId }) => {
      updateUser({
        variables: {
          id: user.id,
          input: {
            firstName: firstName ? firstName : undefined,
            lastName: lastName ? lastName : undefined,
            email: email ? email : undefined,
            phone: phone ? phone : undefined,
            partooId: partooId ? partooId : undefined,
          },
        },
      }).then(() => {
        setEditable(false);
        displaySuccess(true);
      });
    }
  );

  return (
    <Card>
      <CardHeader avatar={<Icon>contacts</Icon>} title="Information" />

      <CardContent>
        <TextField
          disabled
          fullWidth
          label="Identifier"
          margin="dense"
          type="number"
          value={user.id}
        />

        <TextField
          autoComplete="given-name"
          defaultValue={user.firstName}
          disabled={!isEditable}
          fullWidth
          inputRef={register()}
          label="First Name"
          margin="dense"
          name="firstName"
        />

        <TextField
          autoComplete="family-name"
          defaultValue={user.lastName}
          disabled={!isEditable}
          fullWidth
          inputRef={register()}
          label="Last Name"
          margin="dense"
          name="lastName"
        />

        <TextField
          autoComplete="email"
          defaultValue={user.email}
          disabled={!isEditable}
          fullWidth
          inputRef={register()}
          label="Email"
          margin="dense"
          name="email"
          type="email"
        />

        <TextField
          autoComplete="tel"
          defaultValue={user.phone}
          disabled={!isEditable}
          fullWidth
          inputRef={register()}
          label="Phone"
          margin="dense"
          name="phone"
          type="tel"
        />

        <TextField
          autoComplete="partooId"
          defaultValue={user.partooId}
          disabled={!isEditable}
          fullWidth
          inputRef={register()}
          label="Partoo Id"
          margin="dense"
          name="partooId"
        />
      </CardContent>

      <CardActions>
        {isEditable ? (
          <Button color="primary" onClick={onSubmit}>
            Save
          </Button>
        ) : (
          <Button color="primary" onClick={() => setEditable(true)}>
            Edit
          </Button>
        )}
      </CardActions>

      <Snackbar
        autoHideDuration={6000}
        onClose={() => displaySuccess(false)}
        open={shouldDisplaySuccess}
      >
        <Alert>Saved information</Alert>
      </Snackbar>
    </Card>
  );
};

export default Information;
