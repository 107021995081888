import gql from "graphql-tag";

export const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const GET_USER_ACCOUNT_ID = gql`
  query($id: ID!) {
    user(id: $id) {
      accountId
    }
  }
`;

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      account {
        id
        websites {
          audits {
            id
          }
          id
          rankings {
            id
          }
        }
      }
      createdAt
      email
      firstName
      id
      isBlocked
      lastName
      phone
      updatedAt
      verifiedEmail
      partooId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input)
  }
`;

export const GET_USERS = gql`
  query(
    $accountId: ID
    $email: String
    $isBlocked: Boolean
    $isVerified: Boolean
    $limit: Int
    $name: String
    $offset: Int
  ) {
    users(
      accountId: $accountId
      email: $email
      isBlocked: $isBlocked
      isVerified: $isVerified
      limit: $limit
      name: $name
      offset: $offset
    ) {
      createdAt
      email
      firstName
      id
      isBlocked
      lastName
      verifiedEmail
    }
  }
`;

export const GET_USERS_COUNT = gql`
  query(
    $accountId: ID
    $email: String
    $isBlocked: Boolean
    $isVerified: Boolean
    $name: String
  ) {
    usersCount(
      accountId: $accountId
      email: $email
      isBlocked: $isBlocked
      isVerified: $isVerified
      name: $name
    )
  }
`;
