import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { debounce } from "lodash";
import { injectIntl, WrappedComponentProps } from "react-intl";

import getKeywordsColumn from "./Table/keywords";
import getCompetitorsColumn from "./Table/competitors";

// Redux
import { getCompetitors, getKeywords } from "../../../actions/rankings";

// CSS
import "react-table/react-table.css";

interface OwnProps {
  id: any;
  rankingData: any;
  type: "keywords" | "competitors";
}

interface DispatchProps {
  dispatch: Dispatch; // TODO Deprecated
  fetchData: (page: number, sorted: any, filtered: any[]) => void;
}

type Props = OwnProps & DispatchProps & WrappedComponentProps;

class Table extends Component<Props> {
  getColumns = () => {
    const { dispatch, intl, rankingData, type } = this.props;

    switch (type) {
      case "keywords":
        return getKeywordsColumn(dispatch, intl, rankingData);
      case "competitors":
        return getCompetitorsColumn(dispatch, intl, rankingData);
      default:
        return [];
    }
  };

  render() {
    const { fetchData, rankingData, type } = this.props;

    // Debounce to avoid api call every time a character is entered
    // by the user in the search input
    const debouncedDataFetch = debounce(fetchData, 300);

    const defaultSort = {
      keywords: [{ id: "rank", desc: false }],
      competitors: [{ id: "total_matching_keywords", desc: true }],
    };

    const data = rankingData[type] ? rankingData[type].data : [];

    return (
      <ReactTable
        manual
        data={data}
        pages={rankingData[type] && Math.ceil(rankingData[type].total / 10)}
        defaultPageSize={10}
        showPageSizeOptions={false}
        columns={this.getColumns()}
        // @ts-ignore
        defaultSorted={defaultSort[type]}
        filterable
        onFetchData={(state) => {
          debouncedDataFetch(state.page, state.sorted, state.filtered);
        }}
      />
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  { id, type }: OwnProps
): DispatchProps => ({
  dispatch, // TODO Deprecated
  fetchData: (page: number, sorted: any, filtered: any[]) => {
    switch (type) {
      case "keywords":
        dispatch(
          getKeywords(
            id,
            page * 10,
            10,
            sorted,
            filtered.length > 0 && filtered[0].value
          )
        );
        break;
      case "competitors":
        dispatch(
          getCompetitors(
            id,
            page * 10,
            10,
            sorted,
            filtered.length > 0 && filtered[0].value
          )
        );
        break;
      default:
        break;
    }
  },
});

export default injectIntl(connect(null, mapDispatchToProps)(Table));
