import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";

// Legacy api client
import api from "../../api";

// Routing
import history from "../../history";

// Typings
import { CreationForm } from "./index";

type FormData = {
  country: string;
  language: string;
  periodicity: string;
  userId: string;
  website: string;
};

const Ranking: CreationForm = ({ closeDialog }) => {
  const { errors, handleSubmit, register } = useForm<FormData>();

  const onSubmit = handleSubmit(
    async ({ country, language, periodicity, userId, website: websiteUrl }) => {
      try {
        const url = new URL(websiteUrl);

        // Fetch user information
        const { data: user } = await api.get(`/users/${userId}`);

        // Create a new website
        // TODO Create only if it does not exist already
        const { data: website } = await api.post("/websites", {
          accountId: user.accountId,
          domain: url.hostname,
          url: url.toString(),
        });

        // Create a new ranking project
        const { data: ranking } = await api.post("/rankings", {
          country: country || "fr",
          language: language || "fr",
          periodicity: periodicity || "DAILY",
          websiteId: website.id,
        });

        closeDialog();
        // dispatch(clearFilters(FilterScope.RANKING)); // TODO Enable
        history.push(`/rankings/${ranking.id}`);
      } catch (err) {
        // TODO
      }
    }
  );

  return (
    <Fragment>
      <DialogTitle>Create a new ranking project</DialogTitle>

      <DialogContent>
        <TextField
          error={!!errors.website}
          fullWidth
          inputRef={register({
            pattern: /^https?:\/\/.*$/,
            required: true,
          })}
          label="Website"
          margin="dense"
          name="website"
          required
          type="text"
        />

        <TextField
          error={!!errors.userId}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="User id"
          margin="dense"
          name="userId"
          required
          type="number"
        />

        <TextField
          disabled={true}
          error={!!errors.language}
          fullWidth
          inputRef={register()}
          label="Language"
          margin="dense"
          name="language"
          type="text"
          value="fr"
        />

        <TextField
          disabled={true}
          error={!!errors.country}
          fullWidth
          inputRef={register()}
          label="Country"
          margin="dense"
          name="country"
          type="text"
          value="fr"
        />

        <TextField
          disabled={true}
          error={!!errors.periodicity}
          fullWidth
          inputRef={register()}
          label="Periodicity"
          margin="dense"
          name="periodicity"
          type="text"
          value="DAILY"
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeDialog} variant="contained">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default Ranking;
