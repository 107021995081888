import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";

// GraphQL
import apollo from "../../apollo";
import { CREATE_NETLINKING_CAMPAIGN } from "../../queries/netlinking";

// Typings
import { CreationForm } from "./index";
import { NetlinkingCampaignStatus } from "../../types/netlinking";

type FormData = {
  duration: number;
  name: string;
  monthlyArticlesNumber: number;
  status: string;
  websiteId: number;
};

const NetlinkingCampaign: CreationForm = ({ closeDialog }) => {
  const { control, errors, handleSubmit, register } = useForm<FormData>();

  const onSubmit = handleSubmit((data) =>
    apollo
      .mutate({
        mutation: CREATE_NETLINKING_CAMPAIGN,
        variables: {
          input: {
            ...data,
            duration: +data.duration,
            monthlyArticlesNumber: +data.monthlyArticlesNumber,
            websiteId: +data.websiteId,
          },
        },
      })
      .then(() => closeDialog())
  );

  return (
    <Fragment>
      <DialogTitle>Create a campaign</DialogTitle>

      <DialogContent>
        <TextField
          error={!!errors.name}
          fullWidth
          inputRef={register({ required: true })}
          label="Name"
          margin="dense"
          name="name"
          required
        />

        <TextField
          error={!!errors.duration}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Duration"
          margin="dense"
          name="duration"
          required
          type="number"
        />

        <TextField
          error={!!errors.monthlyArticlesNumber}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Publication(s) per month"
          margin="dense"
          name="monthlyArticlesNumber"
          required
          type="number"
        />

        <FormControl error={!!errors.status} fullWidth margin="dense" required>
          <InputLabel id="status-label">Status</InputLabel>
          <Controller
            as={
              <Select labelId="status-label">
                <MenuItem value={NetlinkingCampaignStatus.Pending}>
                  Pending
                </MenuItem>
                <MenuItem value={NetlinkingCampaignStatus.Running}>
                  Running
                </MenuItem>
                <MenuItem value={NetlinkingCampaignStatus.Finished}>
                  Finished
                </MenuItem>
              </Select>
            }
            control={control}
            defaultValue=""
            name="status"
            rules={{ required: true }}
          />
        </FormControl>

        <TextField
          error={!!errors.websiteId}
          fullWidth
          inputProps={{ min: 1 }}
          inputRef={register({ min: 1, required: true })}
          label="Website ID"
          margin="dense"
          name="websiteId"
          required
          type="number"
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeDialog} variant="contained">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default NetlinkingCampaign;
