import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CardActions,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useQuery, useMutation } from "@apollo/client";
import React, { FunctionComponent, useState } from "react";
import { Controller, useForm } from "react-hook-form";

// GraphQL
import { GET_ACCOUNT, UPDATE_ACCOUNT } from "../../queries/accounts";

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

enum SubscriptionType {
  FREE = "FREE",
  ADMIN = "ADMIN",
}

type FormData = {
  subscription: SubscriptionType;
};

interface Props {
  accountId: number;
}

const Subscription: FunctionComponent<Props> = ({ accountId }) => {
  // Notification hook
  const [shouldDisplaySuccess, displaySuccess] = useState(false);

  // Data fetching hook
  const { data, loading, error } = useQuery(GET_ACCOUNT, {
    variables: { id: accountId },
  });

  // Data update hook
  const [updateSubscription] = useMutation(UPDATE_ACCOUNT, {
    awaitRefetchQueries: true,
    refetchQueries: () => [
      { query: GET_ACCOUNT, variables: { id: accountId } },
    ],
  });

  // Form controller hook
  const { control, errors, handleSubmit, watch } = useForm<FormData>();

  const watchSubscription = watch("subscription");

  // Form validation callback
  const onSubmit = handleSubmit(({ subscription }) =>
    updateSubscription({
      variables: { id: accountId, input: { subscription } },
    }).then(() => {
      displaySuccess(true);
    })
  );

  if (error)
    return (
      <Card>
        <CardHeader avatar={<Icon>credit_card</Icon>} title="Subscription" />
        <CardContent>
          <Typography>Error</Typography>
        </CardContent>
      </Card>
    );

  if (loading)
    return (
      <Card>
        <CardHeader avatar={<Icon>credit_card</Icon>} title="Subscription" />
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );

  return (
    <Card>
      <CardHeader avatar={<Icon>credit_card</Icon>} title="Subscription" />
      <CardContent>
        <FormControl error={!!errors.subscription} fullWidth>
          <InputLabel id="subscription-label">Current subscription</InputLabel>
          <Controller
            as={
              <Select labelId="subscription-label">
                <MenuItem value={SubscriptionType.FREE}>Free</MenuItem>
                <MenuItem value={SubscriptionType.ADMIN}>Admin</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.account.subscription}
            name="subscription"
            rules={{ required: true }}
          />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={
            watchSubscription
              ? data.account.subscription === watchSubscription
              : true
          }
        >
          Save
        </Button>
      </CardActions>
      <Snackbar
        autoHideDuration={6000}
        onClose={() => displaySuccess(false)}
        open={shouldDisplaySuccess}
      >
        <Alert>Saved subscription</Alert>
      </Snackbar>
    </Card>
  );
};

export default Subscription;
