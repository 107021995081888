/*
Action dispatched to clear an loader from the redux store
 */
export const CLEAR_LOADER = "CLEAR_LOADER";

export function clearLoader(loader: string) {
  return {
    type: CLEAR_LOADER,
    loader,
  };
}

/*
Action dispatched to clear all loaders from the redux store
 */
export const CLEAR_LOADERS = "CLEAR_LOADERS";

export function clearLoaders() {
  return {
    type: CLEAR_LOADERS,
  };
}
