import {
  Card,
  CardContent,
  Button,
  Icon,
  CardActions,
  CardHeader,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

// Assets
import styles from "./EmailVerification.module.css";

type Props = {
  email: string;
  userId: number;
  updateUser: any;
  verifiedEmail: false;
};

const EmailVerification: FunctionComponent<Props> = ({
  email,
  userId,
  updateUser,
  verifiedEmail,
}) => (
  <Card>
    <CardHeader
      avatar={<Icon>mark_email_read</Icon>}
      title="Email verification"
    />

    <CardContent>
      <Typography variant="body2">
        When a user registers a new account, we send him an email with a link he
        must click on in order to verify he owns the email he used to register.
        <br />
        <br />
        Currently, the email&nbsp;
        {email}
        &nbsp;is
        {verifiedEmail ? (
          <span className={styles.verified}> verified</span>
        ) : (
          <span className={styles.notVerified}> not verified</span>
        )}
        .<br />
        <br />
        If the user has not validated his email yet and/or did not received the
        validation email, you can send him a new one manually or force his email
        verification status.
      </Typography>
    </CardContent>

    <CardActions>
      <Button color="primary" disabled>
        Send an email
      </Button>
      {!verifiedEmail && (
        <Button
          color="primary"
          onClick={() =>
            updateUser({
              variables: { id: userId, input: { verifiedEmail: true } },
            })
          }
        >
          Force verification
        </Button>
      )}
    </CardActions>
  </Card>
);

export default EmailVerification;
