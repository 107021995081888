export enum AuditStatus {
  Any = "ANY",
  Stopped = "STOPPED",
  Crawling = "CRAWLING",
  Analysing = "ANALYSING",
  Finished = "FINISHED",
  Failed = "FAILED",
  Unauthorized = "UNAUTHORIZED",
}

export type Audit = {
  crawlId: string;
  dateEnd: any;
  dateStart: any;
  id: number;
  pagesCount: number;
  score: number;
  status: AuditStatus;
  todos: any[];
  website: any;
};
