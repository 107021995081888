import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { debounce } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

// Enums and typings
import { Column } from "./TableView";
import {
  clearFilters,
  FilterScope,
  setFilter,
  Filter,
} from "../../actions/filters";

const useStyles = makeStyles({
  actions: {
    display: "flex",
    alignSelf: "center",
    textAlign: "end",
  },
  filters: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
    margin: "0 4px 2px 4px",
  },
});

interface StateProps {
  filters: any;
}

interface DispatchProps {
  dispatch: ThunkDispatch<any, any, any>;
}

interface OwnProps<T> {
  actions: number;
  columns: Column<T>[];
  filtersScope: FilterScope;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type Props<T> = OwnProps<T> & StateProps & DispatchProps;

function Filters<T>({
  actions,
  columns,
  dispatch,
  filters,
  filtersScope,
  setPage,
}: Props<T>) {
  const { control, register, setValue } = useForm();
  const classes = useStyles();

  const debouncedSetFilter = debounce((exclusive, name, value) => {
    setPage(1);

    if (exclusive) {
      dispatch(clearFilters(filtersScope));
      resetFilters(name);
    }

    dispatch(setFilter(name, value));
  }, 300);

  const resetFilters = (except?: Filter) => {
    columns.forEach(({ filter, options }) => {
      if (filter !== except)
        if (options) {
          setValue(filter.toString(), options[0].value);
        } else {
          setValue(filter.toString(), "");
        }
    });
  };

  const defaultColumnWidth = Math.floor(12 / columns.length);

  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        {columns.map(
          (
            {
              disabledFilter,
              exclusiveFilter,
              filter,
              options,
              placeholder,
              width,
            },
            idx
          ) => {
            return (
              <Grid
                item
                key={idx}
                style={{ alignSelf: "center" }}
                // @ts-ignore
                xs={width ? width : defaultColumnWidth}
              >
                {options ? (
                  <FormControl defaultValue={options[0].value} fullWidth>
                    <InputLabel id={`${filter}-label`}>
                      {placeholder}
                    </InputLabel>
                    <Controller
                      control={control}
                      defaultValue={options[0].value}
                      render={() => (
                        <Select
                          defaultValue={options[0].value}
                          disabled={disabledFilter}
                          labelId={`${filter}-label`}
                          onChange={(e) => {
                            setPage(1);
                            dispatch(
                              setFilter(filter, e.target.value as string)
                            );
                          }}
                        >
                          {options.map(({ name, value }) => (
                            <MenuItem key={value} value={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name={filter.toString()}
                    />
                  </FormControl>
                ) : (
                  <TextField
                    defaultValue={filters[filter]}
                    disabled={disabledFilter}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register()}
                    key={idx}
                    label={placeholder}
                    margin="dense"
                    name={filter.toString()}
                    onChange={(e) => {
                      e.persist();

                      debouncedSetFilter(
                        exclusiveFilter,
                        filter,
                        e.target.value
                      );
                    }}
                    placeholder="Search"
                    type="search"
                  />
                )}
              </Grid>
            );
          }
        )}
      </Grid>
      <Box className={classes.actions}>
        {/* Dirty trick to adjust this column size depending on the number of row actions */}
        {[...new Array(actions > 0 ? actions - 1 : 0)].map(() => (
          <IconButton style={{ visibility: "hidden" }}>
            <ClearIcon />
          </IconButton>
        ))}
        <IconButton
          onClick={() => {
            // Reset filters in the redux store
            setPage(1);
            dispatch(clearFilters(filtersScope));

            // Reset the search form
            resetFilters();
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ filters }: any) => ({ filters });

export default connect(mapStateToProps)(Filters);
