import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

import Header from "./component/Header";
import helpers from "./helpers";
import Tile from "./Tile";
import TileCompetitors from "./TileCompetitors";

// Redux
import { getTotalNumKeywords } from "../../actions/rankings";

// Assets
import styles from "./Overview.module.css";

interface OwnProps {
  dispatch: any;
  rankingData: any;
}

interface State {
  competitors: any[] | null;
}

type Props = OwnProps & RouteComponentProps<{ id: string }>;

class Overview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      competitors: null,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { id },
      },
    } = this.props;

    this.getCompetitors(id);

    dispatch(getTotalNumKeywords(id));
  }

  componentDidUpdate(nextProps: Props) {
    const {
      dispatch,
      match: {
        params: { id },
      },
      rankingData,
    } = this.props;

    if (
      nextProps.rankingData.startDate !== rankingData.startDate ||
      nextProps.rankingData.end !== rankingData.end ||
      nextProps.rankingData.device !== rankingData.device
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ competitors: null });
      this.getCompetitors(id);

      dispatch(getTotalNumKeywords(id));
    }
  }

  getCompetitors(id: any) {
    helpers.getRankingProject(id).then((project: any) => {
      helpers
        .getCompetitors(
          id,
          moment(project.max_date).subtract(7, "days"),
          moment(project.max_date),
          1,
          0,
          3,
          [{ id: "total_matching_keywords", desc: true }]
        )
        .then((competitors: any) => {
          this.setState({ competitors: competitors.data });
        });
    });
  }

  render() {
    const { rankingData, match } = this.props;
    const { competitors } = this.state;

    if (!competitors)
      return (
        <div className={styles.loadingWrapper}>
          {/* <Spinner size={48} /> */}
          <p>To be continued ...</p>
        </div>
      );

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Header id={match.params.id} rankingData={rankingData} nometrics />
          {rankingData.infos && (
            <div className={styles.containerList}>
              <Tile
                title="ranking.page.overview.averagePosition"
                value={parseFloat(rankingData.infos.avg.value.toFixed(1))}
                diff={parseFloat(rankingData.infos.avg.diff.toFixed(1))}
                position
                link={`serp/${match.params.id}/keywords`}
              />
              <Tile
                title="ranking.page.overview.numKeywords"
                value={rankingData.totalNumKeywords}
                link={`serp/${match.params.id}/keywords`}
              />
              <Tile
                title="ranking.page.overview.presenceRate"
                value={rankingData.infos.visibility.value}
                diff={parseFloat(rankingData.infos.visibility.diff.toFixed(1))}
                percentage
                link={`serp/${match.params.id}/keywords`}
              />
              <Tile
                title="ranking.page.overview.searchVolume"
                value={rankingData.infos.total_search_volume.value}
                diff={rankingData.infos.total_search_volume.diff}
                link={`serp/${match.params.id}/keywords`}
              />
              <Tile
                title="ranking.page.overview.traffic"
                value={rankingData.infos.total_traffic.value}
                diff={rankingData.infos.total_traffic.diff}
                link={`serp/${match.params.id}/keywords`}
              />
              <TileCompetitors
                title="ranking.page.overview.competitors"
                competitors={competitors}
                link={`serp/${match.params.id}/competitors`}
              />
              <Tile
                title="ranking.page.overview.top3"
                value={rankingData.infos.top3.value}
                diff={rankingData.infos.top3.diff}
                link={`serp/${match.params.id}/keywords`}
              />
              <Tile
                title="ranking.page.overview.top10"
                value={rankingData.infos.top10.value}
                diff={rankingData.infos.top10.diff}
                link={`serp/${match.params.id}/keywords`}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ranking }: { ranking: any }) => ({
  rankingData: ranking,
});

export default connect(mapStateToProps)(Overview);
