import React from "react";
import { IntlShape, FormattedMessage } from "react-intl";
import { Dispatch } from "redux";

import { isItemSelected } from "./utils";

import styles from "./competitors.module.css";
import { addItemToFilters } from "../../../../actions/rankings";

interface KeywordIconProps {
  rank: any;
}

const KeywordIcon = ({ rank }: KeywordIconProps) => {
  if (rank.diff === 0 && rank.value > 0 && rank.value < 101) {
    // return null;
    return <b style={{ marginLeft: "5px", color: "lightgrey" }}>=</b>;
  }
  if (typeof rank.diff === "string")
    return (
      <span
        className={styles.label}
        style={{
          background: rank.diff === "gone" ? "red" : "darkblue",
        }}
      >
        {rank.diff}
      </span>
    );
  if (rank.value > 0 && rank.value < 101)
    return (
      <i
        className={`fa fa-caret-${rank.diff > 0 ? "up" : "down"}`}
        aria-hidden="true"
        style={{
          marginLeft: "5px",
          color: rank.diff > 0 ? "green" : "red",
        }}
      />
    );
  return null;
};

export default function getCompetitorsColumn(
  dispatch: Dispatch,
  intl: IntlShape,
  rankingData: any
) {
  return [
    {
      Header: `${intl.formatMessage({
        id: "ranking.competitors.table.competitor",
      })} (${
        rankingData && rankingData.competitors
          ? rankingData.competitors.total
          : "0"
      })`,
      accessor: "domain",
      Filter: (
        { filter, onChange }: { filter: any; onChange: any } // use custom filter in order to put a placeholder
      ) => (
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: "ranking.competitors.table.searchPlaceholder",
          })}
          value={filter ? filter.value : ""}
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
        />
      ),
      Cell: (row: any) =>
        isItemSelected(row.value, rankingData.selectedCompetitors, "domain") ? (
          <span>
            <i
              className="fa fa-check"
              aria-hidden="true"
              style={{ marginRight: "5px", color: "green" }}
            />
            {row.value}
          </span>
        ) : (
          <span
            onClick={() =>
              dispatch(addItemToFilters("selectedCompetitors", row.original))
            }
            className={styles.clickable}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            />
            {row.value}
          </span>
        ),
    },
    {
      Header: () => (
        <FormattedMessage id="ranking.competitors.table.averageRank" />
      ),
      accessor: "rank_avg",
      filterable: false,
      maxWidth: 200,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {row.value.value < 0 || row.value.value > 100
            ? "-"
            : parseFloat(row.value.value.toFixed(1))}
          <KeywordIcon rank={row.original.rank_avg} />
        </div>
      ),
    },
    {
      Header: () => (
        <FormattedMessage id="ranking.competitors.table.presenceRate" />
      ),
      accessor: "total_matching_keywords",
      filterable: false,
      maxWidth: 200,
      Cell: (row: any) => (
        <div style={{ textAlign: "center" }}>
          {parseFloat((row.value.value * 100).toFixed(1))}%
          <KeywordIcon rank={row.original.rank_avg} />
        </div>
      ),
    },
    {
      Header: () => (
        <FormattedMessage id="ranking.competitors.table.comparator" />
      ),
      accessor: "comparator",
      filterable: false,
      maxWidth: 200,
      sortable: false,
      className: styles.disabled,
      Cell: () => <span>ranking.page.competitors.soon</span>,
    },
  ];
}
