import { Delete as DeleteIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";

// Components
import TableView, { Column } from "../components/TableView/TableView";

// GraphQL
import apollo from "../apollo";
import { DELETE_USER } from "../queries/users";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// Utils
import { displayLastActivity, displayName } from "../utils/display";
import { useLazyQuery } from "@apollo/client";

const QUERY = gql`
  query(
    $accountId: ID
    $email: String
    $isBlocked: Boolean
    $isVerified: Boolean
    $limit: Int
    $name: String
    $offset: Int
  ) {
    users(
      accountId: $accountId
      email: $email
      isBlocked: $isBlocked
      isVerified: $isVerified
      name: $name
      limit: $limit
      offset: $offset
    ) {
      # Immutable
      id

      # Custom fields
      email
      firstName
      isBlocked
      lastName
      verifiedEmail
    }
    usersCount(
      accountId: $accountId
      email: $email
      isBlocked: $isBlocked
      isVerified: $isVerified
      name: $name
    )
  }
`;

type User = {
  // Immutable
  id: number;

  // Custom fields
  email: string;
  firstName: string;
  isBlocked: boolean;
  lastName: string;
  verifiedEmail: boolean;

  // Timestamps
  createdAt: string;
};

interface QueryData {
  users: User[];
  usersCount: number;
}

interface QueryVariables {
  accountId?: string;
  email?: string;
  isBlocked?: boolean;
  isVerified?: boolean;
  name?: string;
  limit: number;
  offset: number;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const UsersTableView: FunctionComponent<Props> = ({ filters }) => {
  const columns: Column<User>[] = [
    {
      computeLink: ({ id }: User) => `/users/${id}`,
      computeValue: ({ firstName, lastName }: User) =>
        displayName(firstName, lastName),
      filter: Filter.USER_NAME,
      placeholder: "Name",
      width: 3,
    },
    {
      computeLink: ({ id }: User) => `/users/${id}`,
      computeValue: ({ email }: User) => email,
      filter: Filter.USER_EMAIL,
      placeholder: "Email",
      width: 3,
    },
    {
      computeValue: () => displayLastActivity(null),
      disabledFilter: true,
      filter: Filter.USER_LAST_ACTIVITY,
      placeholder: "Last activity",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ verifiedEmail }: User) =>
        verifiedEmail ? "Verified" : "Not verified",
      filter: Filter.USER_IS_VERIFIED,
      options: [
        { name: "Any", value: "all" },
        { name: "Verified", value: "true" },
        { name: "Not verified", value: "false" },
      ],
      placeholder: "Email status",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ isBlocked }: User) =>
        isBlocked ? "Blocked" : "Not blocked",
      filter: Filter.USER_IS_BLOCKED,
      options: [
        { name: "Any", value: "all" },
        { name: "Blocked", value: "true" },
        { name: "Not blocked", value: "false" },
      ],
      placeholder: "User status",
      width: 2,
    },
  ];

  const rowActions = [
    {
      confirmable: true,
      handler: ({ id }: User) =>
        apollo.mutate({ mutation: DELETE_USER, variables: { id } }),
      Icon: DeleteIcon,
    },
  ];

  const {
    [Filter.USER_ACCOUNT_ID]: accountId,
    [Filter.USER_EMAIL]: email,
    [Filter.USER_IS_BLOCKED]: isBlocked,
    [Filter.USER_IS_VERIFIED]: isVerified,
    [Filter.USER_NAME]: name,
  } = filters;

  const variables: QueryVariables = {
    accountId: accountId ? accountId : undefined,
    email: email ? email : undefined,
    isBlocked: isBlocked ? isBlocked === "true" : undefined,
    isVerified: isVerified ? isVerified === "true" : undefined,
    name: name ? name : undefined,
    limit: 40,
    offset: 0,
  };

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables,
  });

  return (
    <TableView<User, QueryData, QueryVariables>
      columns={columns}
      data={data}
      filtersScope={FilterScope.USER}
      loading={loading}
      normalizeQueryData={({ users, usersCount }) => ({
        dataSet: users,
        total: usersCount,
      })}
      onFetch={fetch}
      onRefetch={async (variables) => {
        if (refetch) await refetch(variables);
      }}
      rowActions={rowActions}
    />
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(UsersTableView);
