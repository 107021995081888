import { Dispatch } from "redux";

import api from "../api";

/*
Action dispatched to delete an quote project from the list stored in redux
 */
export const DELETE_QUOTE = "DELETE_QUOTE";
export const D_QUOTE_ERR = "D_QUOTE_ERR";

function deleteQuote(id: number) {
  return {
    type: DELETE_QUOTE,
    id,
  };
}

/*
Action dispatched to request an quote project deletion from the API
 */
export function requestQuoteDeletion(id: number) {
  return (dispatch: Dispatch) => {
    api
      .delete(`/quotes/${id}`)
      .then(() => dispatch(deleteQuote(id)))
      .catch(() => dispatch({ type: D_QUOTE_ERR }));
  };
}

/*
Action dispatched to update the redux store with a fetched quotes list
 */
export const RECEIVE_QUOTES = "RECEIVE_QUOTES";
export const F_QUOTES_ERR = "F_QUOTES_ERR";

function receiveQuotes(quotes: any) {
  return {
    type: RECEIVE_QUOTES,
    quotes,
  };
}

/*
Action dispatched to fetch an quotes list from the API
 */
export function fetchQuotes(orderId: number) {
  return (dispatch: Dispatch) => {
    api
      .get(`/orders/${orderId}/quotes`)
      .then((quotes) => quotes.data)
      .then((quotes) => {
        dispatch(receiveQuotes(quotes));
      })
      .catch(() => dispatch({ type: F_QUOTES_ERR }));
  };
}
