import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { fetchAuditUrlInfo } from "../../../actions/audits";
import UrlForm from "./UrlForm";
import HTMLTags from "./HtmlTags";
import Content from "./Content";
import Performance from "./Performance";
import Architecture from "./Architecture";

import styles from "./UrlFinder.module.css";

class UrlFinder extends Component {
  fetchUrlData(url) {
    const { audit, dispatch } = this.props;

    dispatch(fetchAuditUrlInfo(audit.id, url));
  }

  render() {
    const { audit } = this.props;

    return (
      <div>
        <UrlForm submitUrl={(url) => this.fetchUrlData(url)} />
        {audit.current &&
        audit.current.url &&
        audit.crawlId === audit.current.url.crawl_id ? (
          // prettier-ignore
          <Switch>
            <Route path="/audits/:id/url-finder/html-tags" component={HTMLTags} />
            <Route path="/audits/:id/url-finder/content" component={Content} />
            <Route path="/audits/:id/url-finder/performance" component={Performance} />
            <Route path="/audits/:id/url-finder/architecture" component={Architecture} />
            <Redirect to={`/audits/${audit.id}/url-finder/html-tags`}/>
          </Switch>
        ) : (
          <div className={styles.noUrlWrapper}>
            <h2>No url provided</h2>
          </div>
        )}
      </div>
    );
  }
}

UrlFinder.propTypes = {
  audit: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ audit }) => ({ audit });

export default connect(mapStateToProps)(UrlFinder);
