import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { confirm, dismiss, Modals } from "../../../actions/modal";

const ConfirmationModal: FunctionComponent<{
  action: any;
  dispatch: any;
  modal?: string;
}> = ({ action, dispatch, modal }) => (
  <Dialog open={modal === Modals.CONFIRMATION}>
    <DialogTitle>Are you sure ?</DialogTitle>

    <DialogContent>This action is reversible.</DialogContent>

    <DialogActions>
      <Button color="primary" onClick={() => dispatch(dismiss())}>
        No
      </Button>
      <Button color="primary" onClick={() => dispatch(confirm(action))}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationModal.propTypes = {
  action: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  action: undefined,
  modal: undefined,
};

function mapStateToProps(state: any) {
  return {
    action: state.modal.action,
    modal: state.modal.modal,
  };
}

export default connect(mapStateToProps)(ConfirmationModal);
