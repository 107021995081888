import store from "../store";

/**
 * @callback findErrorCallback
 * @param {string} error
 */

/**
 * Find if an error has been pushed in redux store
 * @param {string[]} errors
 * @param {findErrorCallback} callback
 */
// eslint-disable-next-line import/prefer-default-export
export function findErrors(errors, callback) {
  const storeErrors = store.getState().errors;

  errors.forEach(error => {
    if (storeErrors.includes(error)) callback(error);
  });
}
