import gql from "graphql-tag";

export const GET_ACCOUNT = gql`
  query($id: ID!) {
    account(id: $id) {
      id
      subscription
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input)
  }
`;

export const GET_ACCOUNTS = gql`
  query($input: AccountInput, $limit: Int, $offset: Int) {
    accounts(input: $input, limit: $limit, offset: $offset) {
      company
      id
      subscription
    }
  }
`;

export const GET_ACCOUNTS_COUNT = gql`
  query($input: AccountInput) {
    accountsCount(input: $input)
  }
`;
