import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Header from "./component/Header";
import Filter from "./component/Filter";
import Table from "./component/Table";
import Chart from "./component/Chart";

import styles from "./Keywords.module.css";

interface Props {
  ranking: any;
}

function Keywords({
  match,
  ranking,
}: Props & RouteComponentProps<{ id: string }>) {
  return (
    <div className={styles.wrapper}>
      <Header id={match.params.id} rankingData={ranking} type="keywords" />
      <div className={styles.contentContainer}>
        <div className={styles.chartContainer}>
          <Chart rankingData={ranking} type="keywords" />
        </div>
        <Filter
          filters={ranking.selectedKeywords}
          rankingData={ranking}
          type="keywords"
        />
      </div>
      <div className={styles.tableContainer}>
        <Table id={match.params.id} rankingData={ranking} type="keywords" />
      </div>
    </div>
  );
}

const mapStateToProps = ({ ranking }: { ranking: any }) => ({ ranking });

export default connect(mapStateToProps)(Keywords);
