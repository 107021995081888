import React, { Component } from "react";
import Dropzone from "react-dropzone";
import moment from "moment";
import Select from "react-select";
import Papa from "papaparse";
import FlagIconFactory from "react-flag-icon-css";
import psl from "psl";

import errorStyles from "./Add/Error.module.css";
import formStyles from "./Add/Form.module.css";
import helpers from "./helpers";
import FlagOption from "./Add/FlagOption";

import styles from "./Add.module.css";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface KeywordComponentProps {
  deleteKeywordFromList: any;
  index: any;
  keyword: any;
}

const KeywordComponent = ({
  keyword,
  index,
  deleteKeywordFromList,
}: KeywordComponentProps) => (
  <span className={styles.wrapperKeywordComponent}>
    {keyword}
    <i
      style={{ marginLeft: "10px", cursor: "pointer" }}
      className="fa fa-times"
      aria-hidden="true"
      onClick={() => deleteKeywordFromList(index)}
    />
  </span>
);

interface FlagProps {
  children: any;
  value: any;
}

function Flag({ children, value }: FlagProps) {
  return (
    <div className="Select-value" title={value.title}>
      <span className="Select-value-label">
        <div
          style={{
            display: "inline-block",
            marginRight: 10,
            position: "relative",
            verticalAlign: "middle",
          }}
        >
          <FlagIcon code={value.value} />
        </div>
        {children}
      </span>
    </div>
  );
}

interface Props {
  history?: any;
}

interface State {
  displayError: boolean;
  errors: any;
  form: any;
  keywordsToAdd: any[];
  loading: boolean;
  loadingParser: boolean;
  startCrawlButtonClicked: boolean;
}

class Add extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayError: false,
      loading: false,
      loadingParser: false,
      keywordsToAdd: [],
      startCrawlButtonClicked: false,
      form: {
        name: "",
        country: "fr",
        value: "fr",
        url: "",
        domain: "",
        keyword: "",
        acceptedFile: [],
        rejectedFile: [],
      },
      errors: {
        url: {
          hasError: true,
          message: "You must enter an URL",
        },
        name: {
          hasError: true,
          message: "ranking.new.nameErrorMessage",
        },
        drop: {
          hasError: true,
          message: "ranking.new.uploadErrorMessage",
        },
        keyword: {
          hasError: false,
          message: "",
        },
      },
    };
  }

  handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      const { errors: errors1, form: form1, keywordsToAdd } = this.state;

      const form = { ...form1 };
      const errors = { ...errors1 };

      form.keyword = "";

      if (form1.keyword.trim() !== "") {
        const keywordsArr = keywordsToAdd;

        form1.keyword.split(/,|;/).forEach((keyword: any) => {
          const k = keyword.trim();
          k.length > 0 && keywordsArr.push(k);
        });

        errors.drop.hasError = false;
        this.setState({ form, keywordsToAdd: keywordsArr, errors });
      } else {
        this.setState({ form });
      }
    }
  };

  isFormValid = () => {
    const { errors } = this.state;

    // eslint-disable-next-line consistent-return
    Object.keys(errors).forEach((key) => {
      if (errors[key].hasError) {
        this.setState({ displayError: true });
        return false;
      }
    });

    this.setState({ displayError: false });

    return true;
  };

  getDomainFromUrl = (url: any) => {
    // eslint-disable-next-line no-param-reassign
    url = url.match(/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:?#[\]@!$&'()*+,;=.]+/g);

    if (url.length === 0) {
      return null;
    }

    // @ts-ignore
    return psl.parse(url[0]).domain;
  };

  handleSelectChange = (e: any) => {
    const { form: form1 } = this.state;

    if (e) {
      const form = { ...form1 };

      form.country = e.domain;
      form.value = e.value;

      this.setState({ form });
    }
  };

  handleChange = (e: any) => {
    const { errors: errors1, form: form1 } = this.state;

    const errors = { ...errors1 };
    const form = { ...form1 };
    const inputName = e.target.name;
    const inputValue = e.target.value;

    form[inputName] = inputValue;
    if (inputName === "name") {
      if (inputValue.trim().length === 0) {
        errors[inputName].hasError = true;
        errors[inputName].message = "ranking.new.nameErrorMessage";
      } else {
        errors[inputName].hasError = false;
        errors[inputName].message = "";
      }
    }

    if (inputName === "url") {
      if (
        !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+/g.test(
          inputValue
        )
      ) {
        errors[inputName].hasError = true;
        errors[inputName].message = "audit.new.step0.beginUrlErrorMessage";
      } else {
        errors[inputName].hasError = false;
        errors[inputName].message = "";

        form.domain = this.getDomainFromUrl(inputValue);
      }
    }

    this.setState({ errors, displayError: false, form });
  };

  getContentOfFile = (file: any, fn: any) => {
    const f = file;
    const read = new FileReader();
    read.readAsText(f);
    read.onloadend = () => {
      fn(read.result);
    };
  };

  createKeywordsArray = (str: any) => {
    const arr = str.split(/;|\n/); // split by ; and \n

    return arr
      .filter((s: any) => s.trim() !== "")
      .map((s: any) => {
        return String.prototype.trim.apply(s);
      }); // trim all the elements in the array if not empty
  };

  startCrawl = () => {
    const { history } = this.props;

    if (this.isFormValid()) {
      this.setState({ startCrawlButtonClicked: true }); // avoid multiple click on the start crawl button

      const { form: form1 } = this.state;
      const form = { ...form1 };

      // generate start crawl date
      form.date = moment().format("YYYY-MM-DD HH:mm:ss").toString();

      this.setState({ form, loading: true }, () => {
        // eslint-disable-next-line no-shadow
        const { form } = this.state;

        // create ranking project
        helpers
          // @ts-ignore
          .postRankingProject(
            form.name.trim(),
            form.country,
            form.date,
            form.url,
            form.domain
          )
          .then((obj: any) => {
            const insertId = obj.data.project_id;
            // post keywords list to the project
            const { keywordsToAdd } = this.state;

            helpers
              // @ts-ignore
              .postKeywords(insertId, keywordsToAdd, form.country)
              .then(() => {
                // Ask the API to run a serp job for the project
                // @ts-ignore
                helpers.launchSerpCrawl(insertId, form.country).then(() => {
                  this.setState({ loading: false }, () => {
                    // change route
                    history.push({ pathname: "/serp" });
                  });
                });
              });
          });
      });
    }
  };

  parseCsv = (file: any) => {
    this.setState({ loadingParser: true });
    Papa.parse(file[0], {
      skipEmptyLines: true,
      complete: (results: { data: any[][] }) => {
        const { keywordsToAdd } = this.state;

        const keywordsArr = keywordsToAdd;

        let posKeywordHeader = 0; // position of title "Keyword" in csd header (default 0 if no header)

        results.data[0].forEach((element, index) => {
          // find if there is header with title Keyword
          if (
            element.match(
              /(?:^|(<= ))(keyword|keywords|mot clé|mot clés|mots clés|mots clé|mot-clé|mot-clés|mots-clés|mots-clé)(?:(?= )|$)/i
            )
          )
            posKeywordHeader = index;
        });
        results.data.forEach((element, index) => {
          const keyword = element[posKeywordHeader];
          if (results.data[0].length > 1) {
            // if there are columns
            if (index > 0)
              // remove header from list
              keywordsArr.push(keyword);
          } else {
            keywordsArr.push(keyword);
          }
        });
        this.setState({ keywordsToAdd: keywordsArr, loadingParser: false });
      },
    });
  };

  onDrop = (accepted: any, rejected: any) => {
    const { errors: errors1, form: form1 } = this.state;

    const form = { ...form1 };
    form.acceptedFile = accepted;
    form.rejectedFile = rejected;

    // error if there is no file in the accepted array
    const errors = { ...errors1 };

    if (form.acceptedFile.length > 0) {
      errors.drop.hasError = false;
      errors.drop.message = "";
      const splitFilename = accepted[0].name.split(".");
      const extension = splitFilename[splitFilename.length - 1];
      if (extension === "txt") {
        this.setState({ loadingParser: true });
        // transform File object into string (get the keywords) / use callaback because async
        this.getContentOfFile(form.acceptedFile[0], (keywords: any) => {
          this.createKeywordsArray(keywords);
          const arr = this.createKeywordsArray(keywords);
          // put keywords into scroll list
          const { keywordsToAdd } = this.state;
          const keywordsArr = keywordsToAdd;
          arr.forEach((element: any) => {
            keywordsArr.push(element);
          });
          this.setState({ keywordsToAdd: keywordsArr, loadingParser: false });
        });
      } else {
        // csv
        this.parseCsv(accepted);
      }
    } else {
      errors.drop.hasError = true;
      errors.drop.message = "ranking.new.uploadErrorMessage";
    }
    this.setState({ form, errors, displayError: false });
  };

  deleteKeywordFromList = (index: any) => {
    const { errors: errors1, form: form1, keywordsToAdd } = this.state;

    const keywordsArr = keywordsToAdd;
    const form = { ...form1 };
    const errors = { ...errors1 };
    keywordsArr.splice(index, 1);
    if (keywordsArr.length === 0) {
      form.acceptedFile = []; // delete the file from upload input
      errors.drop.hasError = true;
    }
    this.setState({ keywordsToAdd: keywordsArr, form, errors });
  };

  render() {
    const {
      errors,
      form,
      loading,
      loadingParser,
      keywordsToAdd,
      startCrawlButtonClicked,
      displayError,
      form: form1,
    } = this.state;

    if (loading) {
      return <span>Loading...</span>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>ranking.new.title</h3>
        </div>
        <div className={styles.center}>
          <div className={styles.inputContainer}>
            <label className={styles.inputColumn}>
              <div>ranking.new.name</div>
              <input
                type="text"
                name="name"
                placeholder="ranking.new.namePlaceholder"
                value={form.name}
                onChange={this.handleChange}
                className={
                  errors.name.hasError && displayError
                    ? `${formStyles.input} ${errorStyles.input}`
                    : formStyles.input
                }
              />
              {errors.name.hasError && displayError && (
                <span className={errorStyles.message}>
                  {errors.name.message}
                </span>
              )}
            </label>
            <label className={styles.inputColumn}>
              <div>URL</div>
              <input
                type="text"
                name="url"
                placeholder="Project url"
                value={form.url}
                onChange={this.handleChange}
                className={
                  errors.url.hasError && displayError
                    ? `${formStyles.input} ${errorStyles.input}`
                    : formStyles.input
                }
              />
              {errors.url.hasError && displayError && (
                <span className={errorStyles.message}>
                  {errors.url.message}
                </span>
              )}
            </label>
            <label className={styles.inputColumn}>
              <div>ranking.new.countryLabel</div>
              {/* <select name="country" value={form.country} onChange={this.handleChange}>
                                <option value="fr">.fr</option>
                                <option value="com">.com</option>
                                <option value="co.uk">.co.uk</option>
                            </select> */}
              <Select
                name="country"
                value={form.value}
                onChange={this.handleSelectChange}
                options={[
                  {
                    value: "fr",
                    label: "ranking.new.country.fr",
                    domain: "fr",
                  },
                  {
                    value: "us",
                    label: "ranking.new.country.us",
                    domain: "com",
                  },
                  {
                    value: "gb",
                    label: "ranking.new.country.en",
                    domain: "co.uk",
                  },
                ]}
                clearable={false}
                valueComponent={Flag}
                optionComponent={FlagOption}
              />
            </label>
          </div>
          <div className={styles.keywordsContainer}>
            <div
              className={`${styles.dropzoneContainer} ${
                errors.drop.hasError &&
                displayError &&
                styles.spaceForErrorMessage
              }`}
            >
              <Dropzone
                className={styles.dropzoneComponent}
                accept=".txt,.csv"
                multiple={false}
                onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
              >
                {form1.acceptedFile.length > 0 ? (
                  <div style={{ textAlign: "center" }}>
                    <i
                      className="fa fa-check"
                      aria-hidden="true"
                      style={{ fontSize: "5vw" }}
                    />
                    <h4>ranking.new.uploadSuccess</h4>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <i
                      className="fa fa-upload"
                      aria-hidden="true"
                      style={{ fontSize: "5vw" }}
                    />
                    <h4>ranking.new.uploadTitle</h4>
                    {/* <span>{'ranking.new.uploadSubtitle'}</span> */}
                  </div>
                )}
              </Dropzone>
              {errors.drop.hasError &&
                displayError &&
                keywordsToAdd.length === 0 && (
                  <span
                    className={`${styles.uploadError} ${errorStyles.message}`}
                  >
                    {errors.drop.message}
                  </span>
                )}
            </div>
            <div
              className={
                keywordsToAdd.length > 0
                  ? styles.listContainerWithKeywords
                  : styles.listContainerWithoutKeywords
              }
            >
              {keywordsToAdd.length > 0 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span
                    className={styles.eraseButton}
                    onClick={() => this.setState({ keywordsToAdd: [] })}
                  >
                    {"ranking.new.eraseAll"}
                  </span>
                </div>
              )}
              {
                // check if there is keywords to send in the list
                keywordsToAdd.length > 0 ? (
                  <div className={styles.keywordsList}>
                    {keywordsToAdd.map((keyword, index) => (
                      <KeywordComponent
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        keyword={keyword}
                        index={index}
                        deleteKeywordFromList={this.deleteKeywordFromList}
                      />
                    ))}
                  </div>
                ) : (
                  <i className={styles.noKeywordsList}>
                    {loadingParser
                      ? "Loading..."
                      : "ranking.new.noKeywordsText"}
                  </i>
                )
              }
              {/* <div> */}
              <input
                className={
                  errors.keyword.hasError && displayError
                    ? `${formStyles.input} ${errorStyles.input}`
                    : formStyles.input
                }
                placeholder="ranking.new.keywordsPlaceholder"
                value={form1.keyword}
                name="keyword"
                type="text"
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
              />
              <div
                style={{ position: "absolute", bottom: "10px", right: "10px" }}
              />
              {/* <i className="fa fa-info-circle" style={{ marginLeft: '5px' }} aria-hidden="false"></i> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <button
            className={`${styles.startCrawl} ${
              startCrawlButtonClicked && styles.startCrawlDisabled
            }`}
            disabled={startCrawlButtonClicked}
            onClick={this.startCrawl}
            type="button"
          >
            {"ranking.new.buttonStart"}{" "}
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  }
}

export default Add;
