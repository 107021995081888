import { AnyAction } from "redux";

import { CLEAR_LOADER, CLEAR_LOADERS } from "../actions/loaders";

function loaders(state: string[] = [], action: AnyAction) {
  // Check if the action is asynchronous, and add it into the loaders array if so.
  // An asynchronous action's starts with F_ (fetch), D_ (delete), or _U, (update),
  // and do not ends with _ERR (error) or _OK (success)
  if (
    /^(?:F_|D_|U_)(?:[A-Z]+|_)+$/.test(action.type) &&
    !/^(?:[A-Z]+|_)+(?:ERR|OK)$/.test(action.type)
  ) {
    return [...state, action.type];
  }

  // Check if the action is terminating another asynchronous action, and remove it from the loaders array if so.
  // An action terminating another asynchronous action ends with _ERR (error) or _OK (success)
  if (/^(?:[A-Z]+_)+ERR$/.test(action.type)) {
    return state.filter((loader) => loader !== action.type.slice(0, -4));
  }
  if (/^(?:[A-Z]+_)+OK$/.test(action.type)) {
    return state.filter((loader) => loader !== action.type.slice(0, -3));
  }

  // Handle regular actions
  switch (action.type) {
    case CLEAR_LOADER:
      return state.filter((loader) => loader !== action.loader);
    case CLEAR_LOADERS:
      return [];
    default:
      return state;
  }
}

export default loaders;
