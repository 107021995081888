import React from "react";

// Assets
import tileStyles from "./OverviewTile.module.css";

interface Props {
  competitors: any[];
  history?: any;
  link: any;
  title: any;
}

const TileCompetitors = ({ competitors, history, link, title }: Props) => {
  return (
    <div
      className={tileStyles.wrapper}
      onClick={() => link && history.push(link)}
    >
      <div className={tileStyles.title}>
        <span>{title}</span>
      </div>
      <div className={tileStyles.container}>
        {competitors.map((competitor, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <h3 key={index}>{competitor.domain}</h3>
        ))}
      </div>
    </div>
  );
};

export default TileCompetitors;
