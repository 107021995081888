import { connect } from "react-redux";
import { Dispatch } from "redux";

// Component to containerize
import OptimizationView from "../../../components/AuditView/OptimizationView";

// Helper functions and enums
import { DataType } from "../formatChartData";
import { fetchWeightTableData } from "../fetchTableData";
import {
  depth as depthCol,
  size as sizeCol,
  url as urlCol,
} from "../tableColumns";

interface OwnProps {}

interface StateProps {
  [index: string]: any;
}

interface DispatchProps {
  dispatch: Dispatch;
}

const mapStateToProps = ({ audit }: any) => ({
  audit,
  catchLabel: true,
  chartContentField: "weight_distribution",
  chartDataType: DataType.WeightDistribution,
  fetchTableData: fetchWeightTableData,
});

const mergeProps = (
  stateProps: StateProps,
  { dispatch }: DispatchProps,
  ownProps: OwnProps
) => ({
  ...stateProps,
  dispatch,
  ...ownProps,
  tableColumns: [urlCol(stateProps.audit.id, dispatch), depthCol, sizeCol],
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
  mergeProps
)(OptimizationView);
