import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Check as CheckIcon, Clear as ClearIcon } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import Spinner from "../../Spinner";

const links: {
  [index: string]: string;
} = {
  "duplicate-h1": "h1",
  "undefined-h1": "h1",
  "duplicate-title": "title",
  "outsize-title": "title",
  "undefined-title": "title",
  "duplicate-meta-description": "meta",
  "outsize-meta-description": "meta",
  "undefined-meta-description": "meta",
  "http-return-code-404": "http-codes",
  "http-return-code-500": "http-codes",
  "http-return-code-302": "http-codes",
  "http-return-code-410": "http-codes",
  "http-return-code-502": "http-codes",
  "http-return-code-503": "http-codes",
  "http-return-code-403": "http-codes",
  "http-return-code-401": "http-codes",
  "undefined-lang": "internationalization",
  "undefined-alt-img": "alt",
  "unminified-javascript": "",
  "unminified-css": "",
  "undefined-opengraph": "open-graph",
  "incomplete-opengraph": "open-graph",
  "undefined-twittercard": "twitter-card",
  "incomplete-twittercard": "twitter-card",
  "missing-google-bot-robots-txt": "robots-txt",
  "denied-google-bot-robots-txt": "robots-txt",
  "hn-hierarchy-jump": "hn",
  "not-set-https": "tls",
  "https-certificate-expired": "tls",
  "not-set-https-redirection": "tls",
  "not-encrypted-pages": "tls",
  "not-fully-encrypted-pages": "tls",
  "not-compliant-url": "url",
  "word-count-too-low": "words-count",
};

interface Props {
  audit: any;
  todos: any[];
}

enum Sort {
  Asc,
  Desc,
  None,
}

/*
  Return true if the optimisation is not deactivated for the audited website because of the technologies it uses

  An optimisation is deactivated only if ALL technos on the websites (audit technos)
  deactivates the optimisation. Therefore we try to determine using reduce()
  if at least one techno of the audited website activates the optimisation (the reduced value will be true).

  Each reduce iteration return true only if a techno activating the optimisation has already been found,
  or if the current technoOptimisations match a techno present on the website AND this optimisation is activated for this techno
 */
const isActivated = (auditTechnos: string[], technoOptimisations: any[]) =>
  technoOptimisations.reduce(
    (current, { technoId, status }) =>
      current || (auditTechnos.includes(technoId) && status),
    false
  );

const List: FunctionComponent<Props> = ({ audit, todos }) => {
  // Hooks used to track how the array must be sorted
  const [statusSorting, sortByStatus] = useState(Sort.None);
  const [activationSorting, sortByActivation] = useState(Sort.None);

  if (!todos) return <Spinner />;

  // Map an array with the technologies ids detected on the audited website
  const auditTechnos = audit.technos.map(({ id }: { id: string }) => id);

  // Map an array with the displayed optimisations and their computed properties
  const items = todos.map<{
    id: number;
    isActivated: boolean;
    link: string | null;
    name: string;
    score: number;
    maxFailScore: number;
    minSuccessScore: number;
  }>(({ id, optimisation, score }) => ({
    id,
    isActivated: isActivated(auditTechnos, optimisation.technoOptimisations),
    link: optimisation ? links[optimisation.tag] || null : null,
    name: optimisation.tag,
    score,
    maxFailScore: optimisation.technoOptimisations[0]
      ? optimisation.technoOptimisations[0].maxFailScore
      : 0,
    minSuccessScore: optimisation.technoOptimisations[0]
      ? optimisation.technoOptimisations[0].minSuccessScore
      : 0,
  }));

  // Sort items array if necessary
  if (statusSorting !== Sort.None) {
    items.sort((a, b) =>
      statusSorting === Sort.Asc ? a.score - b.score : b.score - a.score
    );
  }
  if (activationSorting !== Sort.None) {
    items.sort((a, b) => {
      if (a.isActivated && !b.isActivated) {
        return activationSorting === Sort.Asc ? 1 : -1;
      }

      if (!a.isActivated && b.isActivated) {
        return activationSorting === Sort.Asc ? -1 : 1;
      }

      return 0;
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Failure score</TableCell>
            <TableCell
              onClick={() => {
                sortByActivation(Sort.None);
                sortByStatus(statusSorting === Sort.Asc ? Sort.Desc : Sort.Asc);
              }}
            >
              Current score
              <i className="fas fa-sort" style={{ marginLeft: 8 }} />
            </TableCell>
            <TableCell>Success score</TableCell>
            <TableCell
              onClick={() => {
                sortByActivation(
                  activationSorting === Sort.Asc ? Sort.Desc : Sort.Asc
                );
                sortByStatus(Sort.None);
              }}
            >
              Activated
              <i className="fas fa-sort" style={{ marginLeft: 8 }} />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {/* eslint-disable-next-line no-shadow */}
          {items.map(
            ({
              id,
              isActivated,
              link,
              name,
              score,
              maxFailScore,
              minSuccessScore,
            }) => {
              return (
                <TableRow key={id}>
                  <TableCell>
                    <Link
                      to={`/audits/${audit.id}/${
                        link ? `optimizations/${link}` : ""
                      }`}
                    >
                      {link && (
                        <i
                          className="fas fa-search"
                          style={{ marginRight: 8 }}
                        />
                      )}
                      <FormattedMessage id={`optimisation.${name}`} />
                    </Link>
                  </TableCell>
                  <TableCell>{maxFailScore}</TableCell>
                  <TableCell>
                    {score >= minSuccessScore ? (
                      <span
                        style={{
                          color: "green",
                          textDecoration: "line-through",
                        }}
                      >
                        {score}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {score}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>{minSuccessScore}</TableCell>
                  <TableCell>
                    {isActivated ? (
                      <CheckIcon style={{ color: "green" }} />
                    ) : (
                      <ClearIcon style={{ color: "red" }} />
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
