import React, { FC } from "react";
import {
  CardHeader,
  Icon,
  CardContent,
  Card,
  Typography,
  CircularProgress,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { GET_WEBSITES } from "../../queries/websites";
import { useQuery } from "@apollo/client";

interface WebsitesProps {
  accountId: number;
}

const Websites: FC<WebsitesProps> = ({ accountId }) => {
  const { loading, error, data } = useQuery(GET_WEBSITES, {
    variables: { accountId },
  });

  if (error)
    return (
      <Card>
        <CardHeader avatar={<Icon>http</Icon>} title="Subscription" />
        <CardContent>
          <Typography>Error</Typography>
        </CardContent>
      </Card>
    );

  if (loading)
    return (
      <Card>
        <CardHeader avatar={<Icon>http</Icon>} title="Subscription" />
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );

  return (
    <Card>
      <CardHeader avatar={<Icon>http</Icon>} title="Websites" />
      <CardContent style={{ maxHeight: "200px", overflowX: "auto" }}>
        {data.websites.map((website: any) => (
          <ListItem
            component="a"
            href={`/websites/${website.id}`}
            key={website.id}
          >
            <ListItemText primary={website.id} />
            <ListItemText primary={website.domain} />
          </ListItem>
        ))}
      </CardContent>
    </Card>
  );
};

export default Websites;
