import { useQuery } from "@apollo/client";
import { CircularProgress, Typography } from "@material-ui/core";
import gql from "graphql-tag";
import React, { FunctionComponent, Fragment } from "react";
import { useParams } from "react-router-dom";

// Components
import Publications from "./Publications";
import Configurations from "./Configurations";

// Assets
import styles from "./CampaignView.module.css";

// Typings
import { NetlinkingCampaign } from "../../types/netlinking";
import {
  NetlinkingConfiguration,
  NetlinkingPublication,
} from "../../queries/netlinking";
import PublicationsTable from "./PublicationsTable";
import ConfigurationsTable from "./ConfigurationsTable";

const QUERY = gql`
  query($campaignId: ID!) {
    campaign: netlinkingCampaign(id: $campaignId) {
      createdAt
      duration
      id
      monthlyArticlesNumber
      name
      status
    }
    configurations: netlinkingConfigurations(
      input: { netlinkingCampaignId: $campaignId }
    ) {
      id
      ratio
      targetKeywords
      targetUrl
    }
    publications: netlinkingPublications(
      input: { netlinkingCampaignId: $campaignId }
    ) {
      anchorText
      citationFlow
      date
      id
      netlinkingConfigurationId
      trustFlowScore
      trustFlowTopic
      type
      url
    }
  }
`;

type QueryData = {
  campaign: NetlinkingCampaign;
  configurations: NetlinkingConfiguration[];
  publications: NetlinkingPublication[];
};

type QueryVariables = {
  campaignId: string;
};

const CampaignView: FunctionComponent = () => {
  const { id: campaignId } = useParams();
  const { data, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      variables: {
        campaignId,
      },
    }
  );

  if (loading || !data) return <CircularProgress />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h1">Campaign n°{campaignId}</Typography>
        {data && data.campaign && (
          <Fragment>
            <Typography variant="subtitle1">
              {data.campaign.name} - Status: {data.campaign.status}
            </Typography>
            <Typography variant="body1">
              {data.campaign.monthlyArticlesNumber} publications / month
              <br />
              Began on : {data.campaign.createdAt}
              <br />
              Duration : {data.campaign.duration} month(s)
              <br />
            </Typography>
          </Fragment>
        )}
      </div>

      <Publications configurations={data.configurations} refetch={refetch}>
        <PublicationsTable
          configurations={data.configurations}
          publications={data.publications}
          refetch={refetch}
        />
      </Publications>

      <Configurations refetch={refetch}>
        <ConfigurationsTable
          configurations={data.configurations}
          refetch={refetch}
        />
      </Configurations>
    </div>
  );
};

export default CampaignView;
