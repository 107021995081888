import { AnyAction } from "redux";

import { CONFIRM, DISMISS, SHOW_MODAL } from "../actions/modal";

const initialState = {
  action: null,
  modal: null,
};

function modal(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CONFIRM:
      return initialState;
    case DISMISS:
      return initialState;
    case SHOW_MODAL:
      return {
        action: action.action,
        modal: action.modal,
      };
    default:
      return state;
  }
}

export default modal;
