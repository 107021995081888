import React, { FunctionComponent } from "react";

import styles from "./Switch.module.css";

interface Props {
  checked: boolean;
  id: string;
  name: string;
  onChange: () => {};
}

const Switch: FunctionComponent<Props> = ({ checked, id, name, onChange }) => (
  <label htmlFor={id} className={styles.switch}>
    <input
      checked={checked}
      id={id}
      name={name}
      onChange={onChange}
      type="checkbox"
    />
    <span className={styles.slider} />
  </label>
);

export default Switch;
