import { QueryLazyOptions } from "@apollo/client/react/types/types";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Fade,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

// Components
import Filters from "./Filters";
import ConfirmationDialog from "../UI/Dialog/ConfirmationDialog";

// Redux
import { Filter as FilterName, FilterScope } from "../../actions/filters";

// Typings
import { CreationForm } from "../CreationForms";
import Rows from "./Rows";

// TODO Set dynamically from UI
const PAGE_SIZE = 40;

export type RowAction<T> = {
  confirmable?: boolean;
  handler: (data: T) => Promise<any>;
  Icon: SvgIconComponent;
};

export type Column<T> = {
  centered?: boolean;
  computeLink?: (data: T) => string;
  computeValue: (data: T) => any;
  disabledFilter?: boolean;
  exclusiveFilter?: boolean;
  filter: FilterName;
  options?: {
    name: string;
    value: string;
  }[];
  placeholder: string;
  width?: number;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  pagerContainer: {
    alignItems: "center",
    background: "white",
    bottom: "0",
    borderTop: "1px solid lightgrey",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.6rem 0.2rem",
    position: "sticky",
  },
});

type PaginationVariables = {
  limit: number;
  offset: number;
};

type Props<T, QueryData, QueryVariables extends PaginationVariables> = {
  CreationForm?: CreationForm;
  onSuccessCreation?: () => void;
  columns: Column<T>[];
  data: QueryData | undefined;
  filtersScope: FilterScope;
  loading: boolean;
  normalizeQueryData: (data: QueryData) => { dataSet: T[]; total: number };
  onFetch: (options?: QueryLazyOptions<QueryVariables>) => void;
  onRefetch: (variables?: QueryVariables) => void;
  rowActions: RowAction<T>[];
};

function TableView<T, QueryData, QueryVariables extends PaginationVariables>({
  CreationForm,
  onSuccessCreation,
  columns,
  data,
  filtersScope,
  loading,
  normalizeQueryData,
  onFetch,
  onRefetch,
  rowActions,
}: Props<T, QueryData, QueryVariables>) {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const classes = useStyles();

  // State hook used to store and confirm an action
  const [deferredAction, setDeferredAction] = useState<() => Promise<any>>();
  const [mustConfirm, showConfirmationDialog] = useState(false);

  // Fetch data when component mounts
  useEffect(() => {
    // @ts-ignore
    onFetch({
      variables: {
        limit: PAGE_SIZE,
        offset: 0,
      },
    });
  }, [onFetch]);

  // Pop up confirmation dialog if a new action has been deferred
  useEffect(() => {
    if (deferredAction) showConfirmationDialog(true);
  }, [deferredAction]);

  // Refetch when pagination changes
  useEffect(() => {
    // @ts-ignore
    onRefetch({
      offset: page > 0 ? (page - 1) * PAGE_SIZE : 0,
    });
  }, [page]);

  if (!data)
    return (
      <Box className={classes.root} style={{ padding: "1rem" }}>
        <CircularProgress />
      </Box>
    );

  const { dataSet, total } = normalizeQueryData(data);

  // Encapsulate the confirmable actions with a confirmation dialog
  // and a callback to force a data refetching (useful for e.g. when creating/deleting rows)
  const overriddenActions = rowActions.map<RowAction<T>>((action) => {
    if (action.confirmable)
      return {
        ...action,
        handler: (row) => {
          setDeferredAction(() => () => action.handler(row).then(onRefetch));

          return Promise.resolve();
        },
      };

    return action;
  });

  return (
    <Box className={classes.root}>
      {/* Header */}
      <Filters
        actions={rowActions.length}
        // @ts-ignore
        columns={columns}
        filtersScope={filtersScope}
        setPage={setPage}
      />

      {/* Content */}
      <Rows columns={columns} items={dataSet} rowActions={overriddenActions} />

      {/* Footer */}
      <div className={classes.pagerContainer}>
        <div>
          <Button
            color="primary"
            disabled={!CreationForm}
            onClick={() => setOpen(true)}
            style={{ marginLeft: "8px" }}
            variant="contained"
          >
            Add
          </Button>
          <span style={{ marginLeft: "1rem" }}>Total : {total}</span>
        </div>
        <Pagination
          count={total ? Math.ceil(total / PAGE_SIZE) : 1}
          onChange={(event, page) => {
            setPage(page);
          }}
        />
      </div>

      {/* Creation modal */}
      {CreationForm && (
        <Dialog open={open}>
          <CreationForm
            closeDialog={() => {
              setOpen(false);
              if (onSuccessCreation) {
                onSuccessCreation();
              }
            }}
          />
        </Dialog>
      )}

      {/* Confirmation dialog */}
      <ConfirmationDialog
        action={() => {
          if (deferredAction)
            deferredAction().then(() => showConfirmationDialog(false));
        }}
        cancel={() => showConfirmationDialog(false)}
        open={mustConfirm}
      />

      {/* Loading overlay */}
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "500ms" : "0ms",
        }}
        unmountOnExit
      >
        <Backdrop open={true} style={{ zIndex: "auto" }}>
          <CircularProgress />
        </Backdrop>
      </Fade>
    </Box>
  );
}

export default TableView;
