import { useMutation } from "@apollo/client";
import React, { FunctionComponent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";

// Components
import Form, { FormData } from "../CreationForms/NetlinkingConfiguration";

// GraphQL
import {
  CREATE_NETLINKING_CONFIGURATION,
  CreateNetlinkingPublicationData,
  CreateNetlinkingPublicationVariables,
} from "../../queries/netlinking";

type Props = {
  campaignId: string;
  open: boolean;
  onCancel: () => any;
  onSuccess: () => any;
};

const NetlinkingConfiguration: FunctionComponent<Props> = ({
  campaignId,
  open,
  onCancel,
  onSuccess,
}) => {
  const formMethods = useForm<FormData>();

  const [createConfiguration] = useMutation<
    CreateNetlinkingPublicationData,
    CreateNetlinkingPublicationVariables
  >(CREATE_NETLINKING_CONFIGURATION);

  const onSubmit = formMethods.handleSubmit((data) =>
    createConfiguration({
      variables: {
        input: {
          ...data,
          ratio: +data.ratio,
          netlinkingCampaignId: campaignId,
        },
      },
    }).then(() => onSuccess())
  );

  return (
    <Dialog open={open}>
      <DialogTitle>New configuration</DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <Form />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NetlinkingConfiguration;
