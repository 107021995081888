import randomColor from "randomcolor";
import { AnyAction } from "redux";

import {
  CHANGE_DEVICE,
  CHANGE_DATE,
  CLEAN_STORE,
  ADD_ITEM_TO_FILTERS,
  REMOVE_ITEM_TO_FILTERS,
  REMOVE_ALL_ITEMS_TO_FILTERS,
  UPDATE_FILTERS_ITEMS,
} from "../actions/rankings";

function requestSucceeded(state: any, path: any, data: any) {
  return {
    ...state,
    loading: false,
    error: null,
    [path]: data,
  };
}

function changeDevice(state: any, _device: any) {
  return {
    ...state,
    device: _device,
  };
}

function addItemToFilters(state: any, path: any, item: any) {
  // eslint-disable-next-line no-param-reassign
  item.color = randomColor({ luminosity: "light" });

  return {
    ...state,
    [path]: [...state[path], item],
  };
}

function removeItemToFilters(state: any, path: any, index: any) {
  return {
    ...state,
    [path]: [...state[path].slice(0, index), ...state[path].slice(index + 1)],
  };
}

function removeAllItemsToFilters(state: any, path: any) {
  return {
    ...state,
    [path]: [],
  };
}

function updateFiltersItems(state: any, path: any, newData: any) {
  const newObj: any[] = JSON.parse(JSON.stringify(state[path])); // deep clone array of objects

  newObj.forEach((element, index) => {
    if (path === "selectedKeywords") {
      // eslint-disable-next-line no-param-reassign
      element.keyword = newData[index].keyword;
      // eslint-disable-next-line no-param-reassign
      element.rank = newData[index].rank;
      // eslint-disable-next-line no-param-reassign
      element.by_date = newData[index].by_date;
      // eslint-disable-next-line no-param-reassign
      element.url = newData[index].url;
    }
    if (path === "selectedCompetitors") {
      // eslint-disable-next-line no-param-reassign
      element.domain = newData[index].domain;
      // eslint-disable-next-line no-param-reassign
      element.rank_avg = newData[index].rank_avg;
      // eslint-disable-next-line no-param-reassign
      element.total_matching_keywords = newData[index].total_matching_keywords;
      // eslint-disable-next-line no-param-reassign
      element.by_date = newData[index].by_date;
    }
  });

  return {
    ...state,
    [path]: newObj,
  };
}

function changeDate(state: any, _startDate: any, _endDate: any) {
  return {
    ...state,
    startDate: _startDate,
    endDate: _endDate,
  };
}

const defaultState = {
  project: null,
  infos: null,
  device: 0,
  startDate: null,
  endDate: null,
  keywords: null,
  competitors: null,
  selectedKeywords: [],
  selectedCompetitors: [],
  totalNumKeywords: null,
};

function ranking(state = defaultState, action: AnyAction) {
  // when action.type === *_SUCCESS
  const match = /(.*)_(SUCCESS)/.exec(action.type);
  if (match && action.path && action.data)
    return requestSucceeded(state, action.path, action.data);

  switch (action.type) {
    case CHANGE_DEVICE: {
      return changeDevice(state, action.device);
    }
    case ADD_ITEM_TO_FILTERS: {
      return addItemToFilters(state, action.path, action.item);
    }
    case REMOVE_ITEM_TO_FILTERS: {
      return removeItemToFilters(state, action.path, action.index);
    }
    case REMOVE_ALL_ITEMS_TO_FILTERS: {
      return removeAllItemsToFilters(state, action.path);
    }
    case UPDATE_FILTERS_ITEMS: {
      return updateFiltersItems(state, action.path, action.newData);
    }
    case CHANGE_DATE: {
      return changeDate(state, action.startDate, action.endDate);
    }
    case CLEAN_STORE: {
      return defaultState;
    }
    default:
      return state;
  }
}

export default ranking;
