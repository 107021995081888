import { useLazyQuery } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import gql from "graphql-tag";
import React, { FunctionComponent, Fragment, useState } from "react";
import { connect } from "react-redux";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// Typings
import { Account, AccountSubscription } from "../types/account";

// Components
import TableView, { Column } from "../components/TableView/TableView";

const QUERY = gql`
  query($input: AccountInput, $limit: Int, $offset: Int) {
    accounts(input: $input, limit: $limit, offset: $offset) {
      company
      id
      subscription
    }
    accountsCount(input: $input)
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    account(id: $id) {
      company
      id
      subscription
    }
  }
`;

interface QueryData {
  accounts: Account[];
  accountsCount: number;
}

interface QuerySingleData {
  account?: Account;
}

interface QueryVariables {
  input: {
    company?: string;
    subscription?: AccountSubscription;
  };
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id: string;
  limit: number; // TODO Remove
  offset: number; // TODO Remove
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const AccountsTableView: FunctionComponent<Props> = ({ filters }) => {
  const [open, setOpen] = useState(false);

  const columns: Column<Account>[] = [
    {
      centered: true,
      computeLink: ({ id }: Account) => `/accounts/${id}`,
      computeValue: ({ id }: Account) => id,
      exclusiveFilter: true,
      filter: Filter.ACCOUNT_ID,
      placeholder: "Id",
      width: 2,
    },
    {
      computeLink: ({ id }: Account) => `/accounts/${id}`,
      computeValue: ({ company }: Account) => company || "-",
      filter: Filter.ACCOUNT_NAME,
      placeholder: "Company",
      width: 5,
    },
    {
      centered: true,
      computeLink: ({ id }: Account) => `/accounts/${id}`,
      computeValue: ({ subscription }: Account) => subscription,
      filter: Filter.ACCOUNT_SUBSCRIPTION,
      options: [
        { name: "Any", value: AccountSubscription.Any },
        { name: "Free", value: AccountSubscription.Free },
        { name: "Pro (monthly)", value: AccountSubscription.ProMonthly },
        // TODO Check why the value is unknown in backend
        // { name: "Unlimited", value: AccountSubscription.Unlimited },
        { name: "Admin", value: AccountSubscription.Admin },
      ],
      placeholder: "Subscription",
      width: 5,
    },
  ];

  const rowActions = [
    {
      confirmable: true,
      handler: async () => {
        setOpen(true);
      },
      Icon: DeleteIcon,
    },
  ];

  const {
    [Filter.ACCOUNT_ID]: id,
    [Filter.ACCOUNT_NAME]: company,
    [Filter.ACCOUNT_SUBSCRIPTION]: subscription,
  } = filters;

  let input: QueryVariables["input"] = {
    company,
  };

  if (subscription && subscription !== AccountSubscription.Any)
    input = {
      ...input,
      subscription: subscription as AccountSubscription,
    };

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables: { input, limit: 40, offset: 0 },
  });

  return (
    <Fragment>
      {id ? (
        <TableView<Account, QuerySingleData, QuerySingleVariables>
          columns={columns}
          data={singleData}
          filtersScope={FilterScope.ACCOUNT}
          loading={singleLoading}
          normalizeQueryData={({ account }) =>
            account
              ? { dataSet: [account], total: 1 }
              : { dataSet: [], total: 0 }
          }
          onFetch={fetchSingle}
          onRefetch={async (variables) => {
            if (refetchSingle) await refetchSingle(variables);
          }}
          rowActions={rowActions}
        />
      ) : (
        <TableView<Account, QueryData, QueryVariables>
          columns={columns}
          data={data}
          filtersScope={FilterScope.ACCOUNT}
          loading={loading}
          normalizeQueryData={({ accounts, accountsCount }) => ({
            dataSet: accounts || [],
            total: accountsCount || 0,
          })}
          onFetch={fetch}
          onRefetch={async (variables) => {
            if (refetch) await refetch(variables);
          }}
          rowActions={rowActions}
        />
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="info">Not implemented yet</Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(AccountsTableView);
