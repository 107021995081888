export enum Filter {
  UNKNOWN,
  ACCOUNT_ID,
  ACCOUNT_NAME,
  ACCOUNT_SUBSCRIPTION,
  AUDIT_ACCOUNT_ID,
  AUDIT_ID,
  AUDIT_STATUS,
  AUDIT_URL,
  NETLINKING_CAMPAIGN_ID,
  NETLINKING_CAMPAIGN_NAME,
  NETLINKING_CAMPAIGN_STATUS,
  ORDER_ID,
  ORDER_CREATED_AT,
  ORDER_PRICE,
  ORDER_STATUS,
  ORDER_UPDATED_AT,
  ORDER_WEBSITE,
  RANKING_ACCOUNT_ID,
  RANKING_CREATED_AT,
  RANKING_DOMAIN,
  RANKING_ID,
  USER_ACCOUNT_ID,
  USER_EMAIL,
  USER_LAST_ACTIVITY,
  USER_IS_BLOCKED,
  USER_IS_VERIFIED,
  USER_NAME,
  WEBSITE_DOMAIN,
  WEBSITE_ID,
  WEBSITE_URL,
  WEBSITE_ACCOUNT_ID,
  ADS_CAMPAIGN_ID,
  ADS_CAMPAIGN_DOMAIN,
  ADS_CAMPAIGN_GOOGLE_ADS_ID,
  ADS_CAMPAIGN_BUDGET,
  ADS_CAMPAIGN_ACCOUNT_ID,
  ADS_CAMPAIGN_STATUS,
}

export type Filters = { [key in Filter]?: string };

export enum FilterScope {
  UNKNOWN,
  ACCOUNT,
  AUDIT,
  NETLINKING,
  NETLINKING_CAMPAIGN,
  NETLINKING_CONFIGURATION,
  NETLINKING_PUBLICATIONS,
  ORDER,
  RANKING,
  USER,
  WEBSITE,
  ADS_CAMPAIGN,
}

// Action dispatched to set a filter on a resource field
// when fetching a list of this resource
export const SET_FILTER = "SET_FILTER";
export function setFilter(name: Filter, value: any) {
  return {
    type: SET_FILTER,
    name,
    value,
  };
}

// Action dispatched to remove the filter set on a resource field
// when fetching a list of this resource
export const CLR_FILTER = "CLR_FILTER";
export function clearFilter(name: Filter) {
  return {
    type: CLR_FILTER,
    name,
  };
}

// Action dispatched to remove all filters used in a resource scope
// when fetching a list of this resource
export const CLR_FILTERS = "CLR_FILTERS";
export function clearFilters(scope: FilterScope) {
  return {
    type: CLR_FILTERS,
    scope,
  };
}
