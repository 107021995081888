import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { FunctionComponent, useEffect, useState } from "react";

const Home: FunctionComponent = () => {
  const [gifUrl, setGifUrl] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get(
        "https://api.giphy.com/v1/gifs/random?api_key=prr08JodWLm46q9qacpY9OXaIBjHHAZ2&tag=welcome"
      )
      .then(({ data: { data: { images: { fixed_height: { url } } } } }) =>
        setGifUrl(url)
      );
  }, []);

  return (
    <Box
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      <h1>Octopulse Back Office, welcome</h1>
      {gifUrl ? <img alt="Random GIF" src={gifUrl} /> : <CircularProgress />}
    </Box>
  );
};

export default Home;
