import { useLazyQuery } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, FunctionComponent, useState } from "react";
import { connect } from "react-redux";

// Redux
import { Filter, FilterScope } from "../actions/filters";

// Components
import TableView, { Column } from "../components/TableView/TableView";

// Typings
import { Order, OrderStatus } from "../types/order";

const QUERY = gql`
  query($input: OrderInput, $limit: Int, $offset: Int) {
    orders(input: $input, limit: $limit, offset: $offset) {
      createdAt
      id
      status
      updatedAt
    }
    ordersCount(input: $input)
  }
`;

const QUERY_SINGLE = gql`
  query($id: ID!) {
    order(id: $id) {
      createdAt
      id
      status
      updatedAt
    }
  }
`;

interface QueryData {
  orders: Order[];
  ordersCount: number;
}

interface QuerySingleData {
  order?: Order;
}

interface QueryVariables {
  input?: {
    status?: OrderStatus;
  };
  limit: number;
  offset: number;
}

interface QuerySingleVariables {
  id?: string;
  limit: number; // TODO Remove
  offset: number; // TODO Remove
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface StateProps {
  filters: { [key in Filter]: string };
}

type Props = StateProps;

const OrdersTableView: FunctionComponent<Props> = ({ filters }) => {
  const [open, setOpen] = useState(false);

  const columns: Column<Order>[] = [
    {
      centered: true,
      computeLink: ({ id }: Order) => `/orders/${id}`,
      computeValue: ({ id }: Order) => id,
      exclusiveFilter: true,
      filter: Filter.ORDER_ID,
      placeholder: "Id",
      width: 1,
    },
    {
      computeValue: ({ audit }: Order) =>
        (audit && audit.website && audit.website.url) || "-",
      disabledFilter: true,
      filter: Filter.ORDER_WEBSITE,
      placeholder: "Url",
      width: 3,
    },
    {
      centered: true,
      computeValue: ({ createdAt }: Order) => moment(createdAt).fromNow(),
      disabledFilter: true,
      filter: Filter.ORDER_CREATED_AT,
      placeholder: "Creation",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ updatedAt }: Order) => moment(updatedAt).fromNow(),
      disabledFilter: true,
      filter: Filter.ORDER_UPDATED_AT,
      placeholder: "Last update",
      width: 2,
    },
    {
      computeValue: () => "-",
      disabledFilter: true,
      filter: Filter.ORDER_PRICE,
      placeholder: "Price",
      width: 2,
    },
    {
      centered: true,
      computeValue: ({ status }: Order) => status,
      filter: Filter.ORDER_STATUS,
      options: [
        { name: "Any", value: OrderStatus.Any },
        { name: "Created", value: OrderStatus.Created },
        { name: "Validated", value: OrderStatus.Validated },
        { name: "Estimated", value: OrderStatus.Estimated },
        { name: "Accepted", value: OrderStatus.Accepted },
        { name: "Refused", value: OrderStatus.Refused },
        { name: "Fulfilled", value: OrderStatus.Fulfilled },
        { name: "Finalized", value: OrderStatus.Finalized },
      ],
      placeholder: "Status",
      width: 2,
    },
  ];

  const rowActions = [
    {
      confirmable: true,
      handler: async () => setOpen(true),
      Icon: DeleteIcon,
    },
  ];

  const { [Filter.ORDER_ID]: id, [Filter.ORDER_STATUS]: status } = filters;

  const variables: QueryVariables = { limit: 40, offset: 0 };

  if (status && status !== OrderStatus.Any)
    variables.input = { status: status as OrderStatus };

  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, refetch: refetchSingle },
  ] = useLazyQuery<QuerySingleData, QuerySingleVariables>(QUERY_SINGLE, {
    variables: { id, limit: 1, offset: 0 },
  });

  const [fetch, { data, loading, refetch }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    variables,
  });

  return (
    <Fragment>
      {id ? (
        <TableView<Order, QuerySingleData, QuerySingleVariables>
          columns={columns}
          data={singleData}
          filtersScope={FilterScope.ORDER}
          loading={singleLoading}
          normalizeQueryData={({ order }) =>
            order ? { dataSet: [order], total: 1 } : { dataSet: [], total: 0 }
          }
          onFetch={fetchSingle}
          onRefetch={async (variables) => {
            if (refetchSingle) await refetchSingle(variables);
          }}
          rowActions={rowActions}
        />
      ) : (
        <TableView<Order, QueryData, QueryVariables>
          columns={columns}
          data={data}
          filtersScope={FilterScope.ORDER}
          loading={loading}
          normalizeQueryData={({ orders, ordersCount }) => ({
            dataSet: orders || [],
            total: ordersCount || 0,
          })}
          onFetch={fetch}
          onRefetch={async (variables) => {
            if (refetch) await refetch(variables);
          }}
          rowActions={rowActions}
        />
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="info">Not implemented yet</Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapStateToProps = ({
  filters,
}: {
  filters: { [key in Filter]: string };
}) => ({
  filters,
});

export default connect(mapStateToProps)(OrdersTableView);
