import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import gql from "graphql-tag";
import psl from "psl";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import api from "../../api";
import apollo from "../../apollo";

// Redux
import { clearFilters, FilterScope } from "../../actions/filters";

// Routing
import history from "../../history";

// Assets
import styles from "./RunAudit.module.css";

const DEFAULT_DEPTH = 1;
const DEFAULT_MAX_URLS = 100;
const DEFAULT_SPEED = 1;

const CREATE_AUDIT = gql`
  mutation($fields: AuditInput!) {
    createAudit(input: $fields) {
      id
    }
  }
`;

const CREATE_WEBSITE = gql`
  mutation($fields: WebsiteInput!) {
    createWebsite(input: $fields) {
      id
    }
  }
`;

const FIND_WEBSITE = gql`
  query($accountId: ID!, $domain: String!) {
    websites(accountId: $accountId, domain: $domain) {
      id
    }
  }
`;

interface OwnProps {}

interface StateProps {
  accountId: number;
}

interface DispatchProps {
  afterSubmit: (auditId: number) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const RunAudit: FunctionComponent<Props> = ({ afterSubmit, accountId }) => {
  const [error, setError] = useState("");

  // Form controller hook
  const { errors, handleSubmit, register } = useForm();

  const onSubmit = handleSubmit(async ({ notifyByEmail, url }) => {
    try {
      // Parse the URL if it is valid, throws an error otherwise
      const parsedUrl = new URL(url);
      const sldTld = psl.get(parsedUrl.hostname);

      // Try to find a website the user owns with the same domain
      const {
        data: { websites },
      } = await apollo.query<any>({
        query: FIND_WEBSITE,
        variables: {
          accountId,
          domain: sldTld,
        },
      });

      // Website ID the audit will be attached to
      let websiteId;

      // If a website already exists, use it, otherwise create a new one
      if (websites.length) {
        websiteId = websites[0].id;
      } else {
        const {
          data: {
            // @ts-ignore
            createWebsite,
          },
        } = await apollo.mutate({
          mutation: CREATE_WEBSITE,
          variables: {
            fields: {
              accountId,
              domain: sldTld,
              url: parsedUrl.toString(),
            },
          },
        });

        websiteId = createWebsite.id;
      }

      // Create the audit
      const {
        data: {
          // @ts-ignore
          createAudit: { id: auditId },
        },
      } = await apollo.mutate({
        mutation: CREATE_AUDIT,
        variables: {
          fields: {
            depth: DEFAULT_DEPTH,
            maxURL: DEFAULT_MAX_URLS,
            speed: DEFAULT_SPEED,
            websiteId,
          },
        },
      });

      // Run the audit
      await api.post(`/audits/${auditId}/_run`, {
        disableEmail: !notifyByEmail,
      });

      // Execute the post submit hook if successful
      afterSubmit(auditId);
    } catch (err) {
      setError(err);
    }
  });

  return (
    <Card>
      <CardHeader avatar={<Icon>play_arrow</Icon>} title="Run an audit" />

      <CardContent>
        <Typography variant="body2">
          Provide the website&rsquo;s url you want to audit.
          <br />
          <br />
          The audit will be run using default parameters (low speed, latest
          version ...) and will be accessible by the user when finished.
        </Typography>

        <TextField
          error={!!errors.url}
          fullWidth
          label="Url"
          inputRef={register({ required: true })}
          margin="dense"
          name="url"
          required
          type="text"
        />

        <FormControlLabel
          control={<Switch color="primary" />}
          inputRef={register()}
          label="Notify user by email"
          name="notifyByEmail"
        />

        {error && (
          <p className={styles.error}>
            Please verify the information you provided
          </p>
        )}
      </CardContent>

      <CardActions>
        <Button color="primary" onClick={onSubmit}>
          Run
        </Button>
      </CardActions>
    </Card>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
  afterSubmit: (auditId: number) => {
    dispatch(clearFilters(FilterScope.AUDIT));

    history.push(`/audits/${auditId}`);
  },
});

export default connect(null, mapDispatchToProps)(RunAudit);
