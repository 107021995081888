import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

// Components
import Activity from "./Activity";
import Information from "./Information";
import ProfilePicture from "./ProfilePicture";
import EmailVerification from "./EmailVerification";
import PasswordReset from "./PasswordReset";
import Ban from "./Ban";
import Impersonation from "./Impersonation";
import Delete from "./Delete";
import RunAudit from "./RunAudit";
import Subscription from "./Subscription";

// GraphQL
import { GET_USER, UPDATE_USER } from "../../queries/users";

// Assets
import styles from "./UserView.module.css";
import Websites from "./Websites";

const UserView: FunctionComponent = () => {
  // Get userId from URL
  const { id: userId } = useParams();

  // User data fetch hook
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId },
  });

  // User data update hook
  const [updateUser] = useMutation(UPDATE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: GET_USER, variables: { id: userId } }],
  });

  if (loading)
    return (
      <div className={styles.defaultWrapper}>
        <CircularProgress />
      </div>
    );

  if (error)
    return (
      <div className={styles.defaultWrapper}>
        <p>Error</p>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Grid container spacing={2}>
          <Grid className={styles.gridItem} item>
            <ProfilePicture />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Information updateUser={updateUser} user={data.user} />
          </Grid>

          <Grid className={styles.gridItem} item>
            <EmailVerification
              email={data.user.email}
              updateUser={updateUser}
              userId={userId}
              verifiedEmail={data.user.verifiedEmail}
            />
          </Grid>

          <Grid className={styles.gridItem} item>
            <PasswordReset />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Ban
              isBlocked={data.user.isBlocked}
              userId={userId}
              updateUser={updateUser}
            />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Impersonation userId={userId} />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Delete userId={userId} />
          </Grid>

          <Grid className={styles.gridItem} item>
            <RunAudit accountId={data.user.account.id} />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Activity
              accountId={data.user.account.id}
              audits={data.user.account.websites.reduce(
                (audits: any[], website: any) => audits.concat(website.audits),
                []
              )}
              createdAt={data.user.createdAt}
              websites={data.user.account.websites}
              rankings={data.user.account.websites.reduce(
                (rankings: any[], website: any) =>
                  rankings.concat(website.rankings),
                []
              )}
            />
          </Grid>

          <Grid className={styles.gridItem} item>
            <Subscription accountId={data.user.account.id} />
          </Grid>
          <Grid className={styles.gridItem} item>
            <Websites accountId={data.user.account.id} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserView;
