import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Router, Route, Switch } from "react-router-dom";

// Components
import Login from "./Login";
import Home from "./Home/Home";
import AuditView from "./AuditView/AuditView";
import OrderView from "./OrderView/OrderView";
import NetlinkingCampaignView from "./NetlinkingCampaignView/CampaignView";
import NewQuote from "./OrderView/NewQuote";
import RankingView from "./RankingView/RankingView";
import SettingsView from "./SettingsView/SettingsView";
import SponsorshipsView from "./SponsorshipsView/SponsorshipsView";
import TodosView from "./TodosView/TodosView";
import UserView from "./UserView/UserView";
import WebsiteView from "./WebsiteView";
import WordingView from "./WordingView/WordingView";
import VisibleAccountsList from "../containers/FilteredAccountsList";
import VisibleAuditsList from "../containers/FilteredAuditsList";
import VisibleNetlinkingCampaignsList from "../containers/FilteredNetlinkingCampaignsList";
import VisibleOrdersList from "../containers/FilteredOrdersList";
import VisibleRankingsList from "../containers/FilteredRankingsList";
import VisibleUsersList from "../containers/FilteredUsersList";
import VisibleWebsitesList from "../containers/FilteredWebsitesList";
import VisibleAdsCampaignsList from "../containers/FilteredGoogleAdsCampaignsList";
import NavBar from "./NavBar/NavBar";
import ConfirmationModal from "./UI/Modals/ConfirmationModal";
import ErrorBoundary from "./ErrorBoundary";
import NotFound from "./NotFound";

// Utils
import history from "../history";

// Styles
import styles from "./App.module.css";
import Spinner from "./Spinner";

const App: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [localeData, setLocaleData] = useState(null);

  // Define user local
  // See https://developer.mozilla.org/fr/docs/Web/API/NavigatorLanguage/language
  // TODO Handle the locales names without a specific region (e.g. "fr" and "en")
  const userLocale = ["fr-FR", "en-US"].includes(navigator.language)
    ? navigator.language
    : "fr-FR";

  // Fetch the translations if not present
  useEffect(() => {
    if (!localeData)
      fetch(`/locales/${userLocale}.json`)
        .then(async (res) => {
          setLocaleData(await res.json());
          setLoading(false);
        })
        .catch(() => {
          // Do not fail the application init outside production environments
          // if the translations cannot be fetched. All text fields will be hydrated
          // with their default values
          if (process.env.NODE_ENV !== "production") {
            setLoading(false);
          } else {
            // TODO Display a proper error
            // setLoading(false);
          }
        });
  });

  // Display a loader until the locale data is fetched
  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );

  return (
    <IntlProvider locale={userLocale} messages={localeData || {}}>
      <Router history={history}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="app">
            <ErrorBoundary>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/">
                  <div className={styles.wrapper}>
                    <Route component={NavBar} />
                    <div className={styles.content}>
                      {/* prettier-ignore */}
                      <Switch>
                        <Route exact path="/(|en|fr)" component={Home} />
                        <Route path="/accounts" component={VisibleAccountsList} />
                        <Route path="/users/:id" component={UserView} />
                        <Route path="/users" component={VisibleUsersList} />
                        <Route path="/audits/:id" component={AuditView} />
                        <Route path="/audits" component={VisibleAuditsList} />
                        <Route path="/rankings/:id" component={RankingView} />
                        <Route path="/rankings" component={VisibleRankingsList} />
                        <Route path="/netlinking-campaigns/:id" component={NetlinkingCampaignView} />
                        <Route path="/netlinking-campaigns" component={VisibleNetlinkingCampaignsList} />
                        <Route path="/ads-campaigns" component={VisibleAdsCampaignsList} />
                        <Route path="/orders/:id/new-quote" component={NewQuote} />
                        <Route path="/orders/:id" component={OrderView} />
                        <Route path="/orders" component={VisibleOrdersList} />
                        <Route path="/settings" component={SettingsView} />
                        <Route path="/sponsorships" component={SponsorshipsView} />
                        <Route path="/todos" component={TodosView} />
                        <Route path="/websites/:id" component={WebsiteView} />
                        <Route path="/websites" component={VisibleWebsitesList} />
                        <Route path="/wording" component={WordingView} />
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                  </div>
                </Route>
              </Switch>
              <ConfirmationModal />
            </ErrorBoundary>
          </div>
        </MuiPickersUtilsProvider>
      </Router>
    </IntlProvider>
  );
};

export default App;
